import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TrophyActionContext } from '@kiq/shared/enums';
import { Trophy } from '@kiq/shared/interfaces';

export const TrophyActions = createActionGroup({
  source: 'Trophy',
  events: {
    'get trophies start': props<{ userId: string | null | undefined; trophyActionContext?: TrophyActionContext }>(),
    'get trophies success': props<{ trophies: Array<Trophy>; trophyActionContext?: TrophyActionContext }>(),
    'get trophies fail': emptyProps(),

    'get trophy count for user': emptyProps(),
    'get trophy count for user success': props<{ trophies: Array<Trophy> }>(),
    'get trophy count for user fail': emptyProps(),

    'set trophy count notification': emptyProps(),
    'set trophy count notification success': props<{ trophyCounterNotification: number | null }>(),

    'retrieve last trophy count': emptyProps(),
    'retrieve last trophy count success': props<{ trophyCounterNotification: number | null }>(),
    'retrieve last trophy count fail': emptyProps(),
  },
});
