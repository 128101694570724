import dayjs from 'dayjs';

export function getTimeLeft(timeoutTimestamp?: Date, currentServerTime?: Date) {
  if (timeoutTimestamp) {
    const now = dayjs(currentServerTime);
    const timeLeft = dayjs(timeoutTimestamp).diff(now, 'second');
    return timeLeft > 0 ? timeLeft : 0;
  } else return 0;
}

export function getTimeLeftInPercent(secondsLeft: number, secondsInTotal: number | undefined) {
  return (secondsLeft / (secondsInTotal ?? 15)) * 100;
}

export function getTimeLeftInHours(timeoutTimestamp?: Date, currentServerTime?: Date) {
  if (timeoutTimestamp) {
    const now = dayjs(currentServerTime);
    const timeLeft = dayjs(timeoutTimestamp).diff(now, 'hour');
    return timeLeft > 0 ? timeLeft : 0;
  } else return 0;
}
