import { AsyncQuestion } from './async-question';
import { AsyncDuellAnswer } from './async-duell-answer';

export interface VipUserQuizduelGameRound {
  categoryId: string;
  currentQuestionIndex: number;
  currentQuestion: AsyncQuestion;
  currentQuestionTimeoutTimestamp: Date;
  answers: Array<AsyncDuellAnswer>;
}
