import { TransferType } from '@kiq/shared/enums';

export interface FootballPlayerTransfer {
  season?: string;
  date?: string;
  hasFromTeam: boolean;
  fromTeamProviderId?: string;
  fromTeam?: string;
  hasToTeam: boolean;
  toCareerEnd: boolean;
  toTeamProviderId?: string;
  toTeam?: string;
  marketValue?: number;
  type?: TransferType;
  transferValue?: number;
}
