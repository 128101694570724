import { InjectionToken } from '@angular/core';
import { AsyncMultiplayerFacade } from '../interfaces/async-multiplayer-facade';
import { RegularAsyncMultiplayerGame, VipAsyncMultiplayerGame, VipUserQuizduelGameState } from '@kiq/shared/interfaces';
import { AsyncMultiplayerSingleFacade } from '../interfaces/async-multiplayer-single-facade';

export const ASYNC_MULTIPLAYER_DATA_FACADE = new InjectionToken<
  AsyncMultiplayerFacade<RegularAsyncMultiplayerGame | VipAsyncMultiplayerGame>
>('DATA_FACADE');

export const ASYNC_MULTIPLAYER_DATA_FACADE_SINGLE = new InjectionToken<
  AsyncMultiplayerSingleFacade<VipUserQuizduelGameState>
>('DATA_FACADE_SINGLE');
