import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { OnlineStatisticsActions } from './online-statistics.actions';
import { BasicUserView, UserOwnerView } from '@kiq/shared/classes';
import { UserStatisticGameType } from '@kiq/shared/enums';
import {
  StatisticsRow,
  UserGameStatisticOpponentDetail,
  UserGameStatisticOpponentOverview,
  UserGameStatisticOverview,
  UserVipGameStatisticsOverview,
} from '@kiq/shared/interfaces';

export interface OnlineStatisticsState {
  overviewLoading: boolean;
  allStatisticsLoading: boolean;
  otherUserLoading: boolean;
  userGameStatisticOverview: UserGameStatisticOverview | null;
  allUserGameStatisticOpponentOverviews: Array<StatisticsRow> | null;
  userGameStatisticOpponentDetails: Array<UserGameStatisticOpponentDetail> | null;
  otherUser: UserOwnerView | BasicUserView | null;
  userGameStatisticOpponentOverview: UserGameStatisticOverview | null;
  vipGameStatisticsOverview: UserVipGameStatisticsOverview | null;
  allVipUserOverviews: Array<StatisticsRow> | null;
}

export const initialOnlineStatisticsState: OnlineStatisticsState = {
  overviewLoading: false,
  allStatisticsLoading: false,
  otherUserLoading: false,
  userGameStatisticOverview: null,
  allUserGameStatisticOpponentOverviews: null,
  userGameStatisticOpponentDetails: null,
  otherUser: null,
  userGameStatisticOpponentOverview: null,
  vipGameStatisticsOverview: null,
  allVipUserOverviews: null,
};

export const onlineStatisticsFeature = createFeature({
  name: 'onlineStatistics',

  reducer: createReducer(
    initialOnlineStatisticsState,
    //Add immerOn
    immerOn(OnlineStatisticsActions.getOwnUserGameStatisticOverviewStart, (state) => {}),
    immerOn(OnlineStatisticsActions.getOwnUserGameStatisticOverviewSuccess, (state, { userGameStatisticOverview }) => {
      state.userGameStatisticOverview = userGameStatisticOverview;
      state.overviewLoading = false;
    }),
    immerOn(OnlineStatisticsActions.getOwnUserGameStatisticOverviewFail, (state) => {
      state.overviewLoading = false;
    }),

    immerOn(OnlineStatisticsActions.getOtherUserGameStatisticOverviewStart, (state) => {}),
    immerOn(
      OnlineStatisticsActions.getOtherUserGameStatisticOverviewSuccess,
      (state, { userGameStatisticOpponentOverview }) => {
        state.userGameStatisticOpponentOverview = userGameStatisticOpponentOverview;
        state.overviewLoading = false;
      },
    ),
    immerOn(OnlineStatisticsActions.getOtherUserGameStatisticOverviewFail, (state) => {
      state.overviewLoading = false;
    }),

    immerOn(OnlineStatisticsActions.getAllUserGameStatisticOpponentOverviewsStart, (state) => {}),
    immerOn(
      OnlineStatisticsActions.getAllUserGameStatisticOpponentOverviewsSuccess,
      (state, { allUserGameStatisticOpponentOverviews }) => {
        state.allUserGameStatisticOpponentOverviews = allUserGameStatisticOpponentOverviews.map((stat) => {
          const statRow: StatisticsRow = {
            imageUrl: stat.user.imageUrl ?? null,
            username: stat.user.username,
            wins: stat.wins,
            losses: stat.losses,
            draws: stat.draws,
            totalMatches: stat.totalMatches,
          };

          return statRow;
        });
        state.allStatisticsLoading = false;
      },
    ),
    immerOn(OnlineStatisticsActions.getAllUserGameStatisticOpponentOverviewsFail, (state) => {
      state.allStatisticsLoading = false;
    }),

    immerOn(OnlineStatisticsActions.getUserGameStatisticOpponentDetailsStart, (state, { otherUser }) => {
      state.overviewLoading = true;
    }),
    immerOn(
      OnlineStatisticsActions.getUserGameStatisticOpponentDetailsSuccess,
      (state, { userGameStatisticOpponentDetails }) => {
        const sortOrder = [
          UserStatisticGameType.ONLINE_MATCH,
          UserStatisticGameType.TTT,
          UserStatisticGameType.TTT_TRANSFER,
          UserStatisticGameType.TTT_NATIONS,
        ];

        const sortedDetails = [...userGameStatisticOpponentDetails].sort((a, b) => {
          return sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type);
        });

        state.userGameStatisticOpponentDetails = sortedDetails;
        state.overviewLoading = false;
      },
    ),
    immerOn(OnlineStatisticsActions.getUserGameStatisticOpponentDetailsFail, (state) => {
      state.overviewLoading = false;
    }),

    immerOn(OnlineStatisticsActions.getOtherUserStart, (state, { userName }) => {
      state.otherUser = null;
    }),
    immerOn(OnlineStatisticsActions.getOtherUserSuccess, (state, { otherUser }) => {
      state.otherUser = otherUser;
      state.otherUserLoading = false;
    }),
    immerOn(OnlineStatisticsActions.getOtherUserFail, (state) => {
      state.otherUser = null;
      state.otherUserLoading = false;
    }),
    immerOn(OnlineStatisticsActions.showOverviewLoader, (state) => {
      state.overviewLoading = true;
    }),
    immerOn(OnlineStatisticsActions.showAllStatisticsLoader, (state) => {
      state.allStatisticsLoading = true;
    }),
    immerOn(OnlineStatisticsActions.showOtherUserLoader, (state) => {
      state.otherUserLoading = true;
    }),
    immerOn(OnlineStatisticsActions.getVipGameStatisticsOverviewForUser, (state) => {
      state.otherUserLoading = true;
      state.vipGameStatisticsOverview = null;
    }),
    immerOn(OnlineStatisticsActions.getVipGameStatisticsOverviewForUserSuccess, (state, { response }) => {
      state.overviewLoading = false;
      state.vipGameStatisticsOverview = response;
      state.allVipUserOverviews = response.vipUserOverviews.map((stat) => {
        const statRow: StatisticsRow = {
          imageUrl: stat.vipUser.profileImageUrl ?? null,
          username: stat.vipUser.name,
          wins: stat.wins,
          losses: stat.losses,
          draws: stat.draws,
          totalMatches: stat.totalMatches,
        };

        return statRow;
      });
    }),
    immerOn(OnlineStatisticsActions.getVipGameStatisticsOverviewForUserFail, (state) => {
      state.overviewLoading = false;
    }),
    immerOn(OnlineStatisticsActions.resetVipGameStatisticsOverview, (state) => {
      state.vipGameStatisticsOverview = null;
      state.allVipUserOverviews = null;
    }),
  ),
});

export const {
  selectOverviewLoading,
  selectAllStatisticsLoading,
  selectOtherUserLoading,
  selectUserGameStatisticOverview,
  selectAllUserGameStatisticOpponentOverviews,
  selectUserGameStatisticOpponentDetails,
  selectUserGameStatisticOpponentOverview,
  selectVipGameStatisticsOverview,
  selectAllVipUserOverviews,
  selectOtherUser,
} = onlineStatisticsFeature;
