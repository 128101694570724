import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AsyncState } from '@kiq/shared/enums';
import {
  createAnswerQuestionActions,
  createGetNextQuestionActions,
  createSelectNextCategoryActions,
  createSetCurrentCategoryActions,
} from '../../helper/shared-actions.helpers';
import {
  createCloseCurrentGameAction,
  createIntervalActions,
  createRefreshGameStateOnFocusActions,
  createShowLoaderActions,
  createTimeTickActions,
} from '@kiq/shared/util/helper';
import { AsyncGameRoundCategory, KikkzError, VipUserQuizduelGameState } from '@kiq/shared/interfaces';

export const VipSingleAsyncMultiplayerActions = createActionGroup({
  source: 'VipSingleAsyncMultiplayer',
  events: {
    ...createGetNextQuestionActions<VipUserQuizduelGameState>(),
    ...createSelectNextCategoryActions<VipUserQuizduelGameState>(),
    ...createAnswerQuestionActions<VipUserQuizduelGameState>(),
    ...createIntervalActions<VipUserQuizduelGameState>(),
    ...createTimeTickActions(),
    ...createShowLoaderActions(),
    ...createSetCurrentCategoryActions<AsyncGameRoundCategory>(),
    ...createRefreshGameStateOnFocusActions(),
    ...createCloseCurrentGameAction(),

    'get vip single game': props<{ gameLinkId: string }>(),
    'get vip single game success': props<{ response: VipUserQuizduelGameState }>(),
    'get vip single game failure': props<{ error?: KikkzError }>(),

    'set current async state': props<{ currentAsyncState: AsyncState | null }>(),

    'reset error': emptyProps(),
  },
});
