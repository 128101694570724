import { Component, Inject, Input, OnChanges, Optional, SimpleChanges } from '@angular/core';
import { ASSET_TRANSFORMER } from '@kiq/shared/util/asset-helper';
import { AssetTransformer } from '@kiq/shared/interfaces';

@Component({
    selector: 'shared-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    standalone: false
})
export class IconComponent implements OnChanges {
  @Input() name: string | undefined;

  constructor(@Optional() @Inject(ASSET_TRANSFORMER) private assetTransformer?: AssetTransformer) {}

  iconPath?: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['name']) {
      const assetPath = `shared-assets/icons/${this.name}.svg`;
      this.iconPath = `url('${this.assetTransformer ? this.assetTransformer?.getAssetWithHash(assetPath) : assetPath}')`;
    }
  }
}
