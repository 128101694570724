export enum NotificationType {
  quizDuelInvitation = 'quizDuelInvitation',
  quizDuelPlayerTurnChange = 'quizDuelPlayerTurnChange',
  quizDuelGameEnded = 'quizDuelGameEnded',
  quizDuelExpirationHalfwayWarning = 'quizDuelExpirationHalfwayWarning',
  quizDuelExpirationLastWarning = 'quizDuelExpirationLastWarning',
  quizDuelGameTimeout = 'quizDuelGameTimeout',

  quizDuelVIPUserNewGameAvailable = 'quizDuelVIPUserNewGameAvailable',
  quizDuelVIPUserPlayerTurnChange = 'quizDuelVIPUserPlayerTurnChange',
  quizDuelVIPUserExpirationHalfwayWarning = 'quizDuelVIPUserExpirationHalfwayWarning',
  quizDuelVIPUserExpirationLastWarning = 'quizDuelVIPUserExpirationLastWarning',
  quizDuelVIPUserGameTimeout = 'quizDuelVIPUserGameTimeout',
}
