import { BaseAsyncMultiplayerGame } from './base-async-multiplayer-game';
import { AsyncGameStateServer, PlayerNumber } from '@kiq/shared/enums';

export interface RegularAsyncMultiplayerGame extends BaseAsyncMultiplayerGame {
  challengerUserId: string;
  playerIds: Map<PlayerNumber, string>;
  gameStatus: AsyncGameStateServer;
  isRunningOrSelfChallengeRequestedGame: boolean;
  gamePoints?: Map<PlayerNumber, number>;

  // Not applicable for regular games:
  instanceId?: never;
  gameStatistics?: never;
  vipUserReactionOnGameEnd?: never;
}
