import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { BasicUserView, UserOwnerView } from '@kiq/shared/classes';
import {
  AsyncGameRoundCategory,
  AsyncMultiplayerUser,
  GamesOverview,
  KikkzError,
  RegularAsyncMultiplayerGame,
} from '@kiq/shared/interfaces';
import { AsyncState, PlayerNumber } from '@kiq/shared/enums';
import {
  createAnswerQuestionActions,
  createGetNextQuestionActions,
  createSelectNextCategoryActions,
  createSetCurrentCategoryActions,
  setShownOpponentScoreActions,
} from '../../helper/shared-actions.helpers';
import {
  createCloseCurrentGameAction,
  createIntervalActions,
  createRefreshGameStateOnFocusActions,
  createShowLoaderActions,
  createTimeTickActions,
} from '@kiq/shared/util/helper';

export const RegularAsyncMultiplayerActions = createActionGroup({
  source: 'RegularAsyncMultiplayer',
  events: {
    ...createGetNextQuestionActions<RegularAsyncMultiplayerGame>(),
    ...createSelectNextCategoryActions<RegularAsyncMultiplayerGame>(),
    ...createAnswerQuestionActions<RegularAsyncMultiplayerGame>(),
    ...createIntervalActions<RegularAsyncMultiplayerGame>(),
    ...createTimeTickActions(),
    ...createShowLoaderActions(),
    ...createSetCurrentCategoryActions<AsyncGameRoundCategory>(),
    ...createRefreshGameStateOnFocusActions(),
    ...createCloseCurrentGameAction(),
    ...setShownOpponentScoreActions(),

    'get async game': props<{ gameId: string }>(),
    'get async game success': props<{ response: RegularAsyncMultiplayerGame }>(),
    'get async game failure': props<{ error?: KikkzError }>(),

    'create or get async game from invitation link': props<{ invitationLinkId: string }>(),
    'create or get async game from invitation link success': props<{ response: RegularAsyncMultiplayerGame }>(),
    'create or get async game from invitation link failure': props<{ error?: KikkzError }>(),

    'get opponent from invitation link': props<{ invitationLinkId: string }>(),
    'get opponent from invitation link success': props<{ opponent: UserOwnerView | BasicUserView }>(),
    'get opponent from invitation link failure': props<{ error?: KikkzError }>(),

    'get all async games': emptyProps(),
    'get all async games success': props<{
      allAsyncMultiplayerGames: GamesOverview<RegularAsyncMultiplayerGame>;
      user: UserOwnerView | null;
    }>(),
    'get all async games failure': props<{ error?: KikkzError }>(),

    'give up game': emptyProps(),
    'give up game success': props<{ response: RegularAsyncMultiplayerGame }>(),
    'give up game fail': props<{ error?: KikkzError }>(),

    'decline challenge': emptyProps(),
    'decline challenge success': props<{ response: RegularAsyncMultiplayerGame }>(),
    'decline challenge fail': props<{ error?: KikkzError }>(),

    'accept challenge': emptyProps(),
    'accept challenge success': props<{ response: RegularAsyncMultiplayerGame }>(),
    'accept challenge fail': props<{ error?: KikkzError }>(),

    'delete ended game': emptyProps(),
    'delete ended game success': emptyProps(),
    'delete ended game fail': props<{ error?: KikkzError }>(),

    'challenge user': props<{ opponentId: string }>(),
    'challenge user success': props<{ response: RegularAsyncMultiplayerGame }>(),
    'challenge user fail': props<{ error?: KikkzError }>(),

    'challenge user again': props<{ previousGameId: string }>(),
    'challenge user again success': props<{ response: RegularAsyncMultiplayerGame }>(),
    'challenge user again fail': props<{ error?: KikkzError }>(),

    'get all users for ids': props<{ userIds: string[] }>(),
    'get all users for ids success': props<{ users: BasicUserView[] }>(),
    'get all users for ids failure': props<{ error: KikkzError }>(),

    'set player numbers': props<{ userPlayerNumber: PlayerNumber | null; opponentPlayerNumber: PlayerNumber | null }>(),

    'set current opponent': props<{ currentOpponent: AsyncMultiplayerUser | null }>(),
    'set current opponent failure': props<{ error: KikkzError }>(),

    'set current async state': props<{ currentAsyncState: AsyncState | null }>(),

    'set player game points': props<{ userGamePoints: number | null; opponentGamePoints: number | null }>(),

    'reset error': emptyProps(),

    'do not show game already running dialog': emptyProps(),

    'do not show challenge already send dialog': emptyProps(),
  },
});
