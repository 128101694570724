import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { advertisementFeature } from './+state/advertisement.reducer';
import { AdvertisementFacade } from './+state/advertisement.facade';
import { AdvertisementService } from './service/advertisement.service';

@NgModule({
  imports: [CommonModule, HttpClientModule, StoreModule.forFeature(advertisementFeature)],
  providers: [AdvertisementFacade, AdvertisementService],
})
export class ClientDataAccessAdvertisementModule {}
