import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { AsyncGameStateServer, AsyncMultiplayerType, AsyncState, PlayerNumber } from '@kiq/shared/enums';
import { getTimeLeft, getTimeLeftInPercent } from 'client/util/time-calculations';
import {
  AsyncGameRoundCategory,
  AsyncGamesOverview,
  AsyncMultiplayerUser,
  KikkzError,
  RegularAsyncMultiplayerGame,
  RegularAsyncMultiplayerViewModel,
} from '@kiq/shared/interfaces';
import { CommonAsyncGameState } from '../../interfaces/common-async-game-state';
import { RegularAsyncMultiplayerActions } from './regular-async-multiplayer.actions';
import { convertUserToMultiplayerUser } from '@kiq/shared/util/helper';
import { provideCommonAsyncActionHandlers } from '../../helper/shared-reducer.helpers';
import { createInitialCommonAsyncGameState } from '../../helper/initial-state.const';

export interface RegularAsyncMultiplayerState extends CommonAsyncGameState<RegularAsyncMultiplayerGame> {
  allAsyncMultiplayerGames: AsyncGamesOverview | null;
  userPlayerNumber: PlayerNumber | null;
  opponentPlayerNumber: PlayerNumber | null;
  currentOpponent: AsyncMultiplayerUser | null;
  currentAsyncState: AsyncState | null;
  currentCategory: AsyncGameRoundCategory | null;
  shownOpponentScore: number | null;
  opponentUserMap: Map<string, AsyncMultiplayerUser | null>;
  showGameAlreadyRunningDialog: boolean;
  showChallengeAlreadySendDialog: boolean;
  userGamePoints: number | null;
  opponentGamePoints: number | null;
  challengeDeclined: boolean;
}

export const initialAsyncMultiplayerState: RegularAsyncMultiplayerState = {
  ...createInitialCommonAsyncGameState<RegularAsyncMultiplayerGame>(),
  allAsyncMultiplayerGames: null,
  userPlayerNumber: null,
  opponentPlayerNumber: null,
  currentOpponent: null,
  currentAsyncState: null,
  currentCategory: null,
  intervalRunning: false,
  timeLeftQuestion: null,
  timeLeftQuestionInPercent: null,
  stepLoading: false,
  shownOpponentScore: null,
  opponentUserMap: new Map<string, AsyncMultiplayerUser | null>(),
  showGameAlreadyRunningDialog: false,
  showChallengeAlreadySendDialog: false,
  userGamePoints: null,
  opponentGamePoints: null,
  challengeDeclined: false,
};

export const regularAsyncMultiplayerFeature = createFeature({
  name: 'regularAsyncMultiplayer',
  reducer: createReducer(
    initialAsyncMultiplayerState,
    ...provideCommonAsyncActionHandlers<
      RegularAsyncMultiplayerState,
      RegularAsyncMultiplayerGame,
      KikkzError,
      AsyncGameRoundCategory
    >({
      showStepLoader: RegularAsyncMultiplayerActions.showStepLoader,
      showGameLoader: RegularAsyncMultiplayerActions.showGameLoader,
      refreshGameStateOnFocus: RegularAsyncMultiplayerActions.refreshGameStateOnFocus,
      startIntervalSuccess: RegularAsyncMultiplayerActions.startIntervalSuccess,
      stopInterval: RegularAsyncMultiplayerActions.stopInterval,
      timeTick: RegularAsyncMultiplayerActions.timeTick,
      getNextQuestion: RegularAsyncMultiplayerActions.getNextQuestion,
      getNextQuestionSuccess: RegularAsyncMultiplayerActions.getNextQuestionSuccess,
      getNextQuestionFailure: RegularAsyncMultiplayerActions.getNextQuestionFailure,
      selectCategory: RegularAsyncMultiplayerActions.selectCategory,
      selectCategorySuccess: RegularAsyncMultiplayerActions.selectCategorySuccess,
      selectCategoryFailure: RegularAsyncMultiplayerActions.selectCategoryFailure,
      answerQuestion: RegularAsyncMultiplayerActions.answerQuestion,
      answerQuestionSuccess: RegularAsyncMultiplayerActions.answerQuestionSuccess,
      answerQuestionFailure: RegularAsyncMultiplayerActions.answerQuestionFailure,
      setCurrentCategory: RegularAsyncMultiplayerActions.setCurrentCategory,
      setShownOpponentScore: RegularAsyncMultiplayerActions.setShownOpponentScore,
    }),

    immerOn(RegularAsyncMultiplayerActions.closeCurrentGame, (state) => {
      const newState = {
        ...initialAsyncMultiplayerState,
        allAsyncMultiplayerGames: state.allAsyncMultiplayerGames,
        opponentUserMap: state.opponentUserMap,
      };

      Object.assign(state, newState);
    }),
    immerOn(RegularAsyncMultiplayerActions.getAllAsyncGames, (state) => {
      state.allGamesLoading = true;
      state.error = null;
    }),
    immerOn(RegularAsyncMultiplayerActions.getAllAsyncGamesSuccess, (state, { allAsyncMultiplayerGames, user }) => {
      state.allAsyncMultiplayerGames = allAsyncMultiplayerGames;

      const gamesCombined = [
        ...(allAsyncMultiplayerGames?.runningGames ?? []),
        ...(allAsyncMultiplayerGames?.pendingGames ?? []),
        ...(allAsyncMultiplayerGames?.endedGames ?? []),
      ];
      const opponentUserMap = state.opponentUserMap;

      gamesCombined.forEach((game) => {
        game.playerIds.forEach((playerId) => {
          if (!opponentUserMap.has(playerId) && user?.id !== playerId) {
            opponentUserMap.set(playerId, null);
          }
        });
      });
      state.opponentUserMap = opponentUserMap;

      state.allGamesLoading = false;
    }),
    immerOn(RegularAsyncMultiplayerActions.getAllUsersForIdsSuccess, (state, { users }) => {
      const opponentUserMap = state.opponentUserMap;

      users.forEach((user) => {
        if (opponentUserMap.has(user.id) && opponentUserMap.get(user.id) === null) {
          const multiplayerUser: AsyncMultiplayerUser = convertUserToMultiplayerUser(user);

          opponentUserMap.set(user.id, multiplayerUser);
        }
      });

      state.opponentUserMap = opponentUserMap;
    }),
    immerOn(RegularAsyncMultiplayerActions.getAllAsyncGamesFailure, (state, { error }) => {
      state.allGamesLoading = false;
      state.error = error ?? null;
    }),

    immerOn(RegularAsyncMultiplayerActions.getAsyncGame, (state) => {
      state.error = null;
      state.gameLoading = true;
    }),
    immerOn(RegularAsyncMultiplayerActions.getAsyncGameSuccess, (state, { response }) => {
      state.currentGame = response;

      if (response.currentRound?.currentQuestionTimeoutTimestamp) {
        state.timeLeftQuestion = getTimeLeft(
          response?.currentRound?.currentQuestionTimeoutTimestamp,
          response.currentServerTime,
        );
        state.timeLeftQuestionInPercent = getTimeLeftInPercent(
          state.timeLeftQuestion,
          response?.currentRound?.currentQuestion?.maxAnswerDurationSeconds,
        );
      }
      state.gameLoading = false;
      state.stepLoading = false;
    }),
    immerOn(RegularAsyncMultiplayerActions.getAsyncGameFailure, (state, { error }) => {
      state.currentGame = null;
      state.gameLoading = false;
      state.error = error ?? null;
    }),

    immerOn(RegularAsyncMultiplayerActions.createOrGetAsyncGameFromInvitationLinkSuccess, (state, { response }) => {
      state.currentGame = response;
      state.showGameAlreadyRunningDialog =
        response.isRunningOrSelfChallengeRequestedGame && response.gameStatus === AsyncGameStateServer.RUNNING;
      state.stepLoading = false;
    }),
    immerOn(RegularAsyncMultiplayerActions.createOrGetAsyncGameFromInvitationLinkFailure, (state, { error }) => {
      state.currentGame = null;
      state.userPlayerNumber = null;
      state.opponentPlayerNumber = null;
      state.currentOpponent = null;
      state.currentAsyncState = null;
      state.currentCategory = null;
      state.intervalRunning = false;
      state.timeLeftQuestion = null;
      state.timeLeftQuestionInPercent = null;
      state.gameLoading = false;
      state.stepLoading = false;
      state.error = error ?? null;
    }),

    immerOn(RegularAsyncMultiplayerActions.challengeUser, (state, { opponentId }) => {
      state.currentGame = null;
    }),
    immerOn(RegularAsyncMultiplayerActions.challengeUserSuccess, (state) => {
      state.stepLoading = false;
    }),
    immerOn(RegularAsyncMultiplayerActions.challengeUserFail, (state, { error }) => {
      state.gameLoading = false;
      state.stepLoading = false;
    }),

    immerOn(RegularAsyncMultiplayerActions.challengeUserAgain, (state, { previousGameId }) => {
      state.currentGame = null;
    }),
    immerOn(RegularAsyncMultiplayerActions.challengeUserAgainSuccess, (state, { response }) => {
      state.currentGame = response;
      state.showChallengeAlreadySendDialog =
        response.isRunningOrSelfChallengeRequestedGame &&
        response.gameStatus === AsyncGameStateServer.CHALLENGE_REQUESTED;
      state.showGameAlreadyRunningDialog =
        response.isRunningOrSelfChallengeRequestedGame && response.gameStatus === AsyncGameStateServer.RUNNING;
      state.stepLoading = false;
    }),
    immerOn(RegularAsyncMultiplayerActions.challengeUserAgainFail, (state, { error }) => {
      state.error = error ?? null;
      state.gameLoading = false;
      state.stepLoading = false;
    }),

    immerOn(RegularAsyncMultiplayerActions.acceptChallengeSuccess, (state, { response }) => {
      state.currentGame = response;
      state.stepLoading = false;
    }),
    immerOn(RegularAsyncMultiplayerActions.acceptChallengeFail, (state, { error }) => {
      state.error = error ?? null;
      state.gameLoading = false;
      state.stepLoading = false;
      if (error) console.log(error.errorMessage);
    }),

    immerOn(RegularAsyncMultiplayerActions.getOpponentFromInvitationLink, (state) => {
      state.gameLoading = false;
      state.stepLoading = false;
    }),
    immerOn(RegularAsyncMultiplayerActions.getOpponentFromInvitationLinkSuccess, (state, { opponent }) => {
      state.currentOpponent = convertUserToMultiplayerUser(opponent);
      state.currentAsyncState = AsyncState.CHALLENGED;
      state.gameLoading = false;
    }),
    immerOn(RegularAsyncMultiplayerActions.getOpponentFromInvitationLinkFailure, (state, { error }) => {
      state.currentGame = null;
      state.userPlayerNumber = null;
      state.opponentPlayerNumber = null;
      state.currentOpponent = null;
      state.currentAsyncState = null;
      state.currentCategory = null;
      state.intervalRunning = false;
      state.timeLeftQuestion = null;
      state.timeLeftQuestionInPercent = null;
      state.gameLoading = false;
      state.error = error ?? null;
    }),

    immerOn(RegularAsyncMultiplayerActions.setPlayerNumbers, (state, { userPlayerNumber, opponentPlayerNumber }) => {
      state.userPlayerNumber = userPlayerNumber;
      state.opponentPlayerNumber = opponentPlayerNumber;
    }),

    immerOn(RegularAsyncMultiplayerActions.setCurrentOpponent, (state, { currentOpponent }) => {
      state.currentOpponent = currentOpponent;
      state.gameLoading = false;
    }),
    immerOn(RegularAsyncMultiplayerActions.setCurrentOpponentFailure, (state) => {
      state.currentOpponent = null;
      state.gameLoading = false;
    }),

    immerOn(RegularAsyncMultiplayerActions.setCurrentAsyncState, (state, { currentAsyncState }) => {
      state.currentAsyncState = currentAsyncState;
    }),

    immerOn(RegularAsyncMultiplayerActions.setPlayerGamePoints, (state, { userGamePoints, opponentGamePoints }) => {
      state.userGamePoints = userGamePoints;
      state.opponentGamePoints = opponentGamePoints;
    }),

    immerOn(RegularAsyncMultiplayerActions.deleteEndedGame, (state) => {
      state.allGamesLoading = true;
    }),
    immerOn(RegularAsyncMultiplayerActions.deleteEndedGameSuccess, (state) => {
      state.allGamesLoading = false;
    }),
    immerOn(RegularAsyncMultiplayerActions.deleteEndedGameFail, (state) => {
      state.allGamesLoading = false;
    }),

    immerOn(RegularAsyncMultiplayerActions.giveUpGameSuccess, (state, { response }) => {
      state.currentGame = response;
      state.stepLoading = false;
    }),
    immerOn(RegularAsyncMultiplayerActions.giveUpGameFail, (state) => {
      state.stepLoading = false;
    }),

    immerOn(RegularAsyncMultiplayerActions.declineChallengeSuccess, (state) => {
      state.challengeDeclined = true;
      state.stepLoading = false;
    }),
    immerOn(RegularAsyncMultiplayerActions.declineChallengeFail, (state) => {
      state.stepLoading = false;
    }),

    immerOn(RegularAsyncMultiplayerActions.resetError, (state) => {
      state.error = null;
    }),

    immerOn(RegularAsyncMultiplayerActions.doNotShowGameAlreadyRunningDialog, (state) => {
      state.showGameAlreadyRunningDialog = false;
    }),

    immerOn(RegularAsyncMultiplayerActions.doNotShowChallengeAlreadySendDialog, (state) => {
      state.showChallengeAlreadySendDialog = false;
    }),
  ),
});

export const {
  name, // feature name
  selectAllGamesLoading,
  selectGameLoading,
  selectStepLoading,
  selectError,
  selectAllAsyncMultiplayerGames,
  selectCurrentGame,
  selectUserPlayerNumber,
  selectOpponentPlayerNumber,
  selectCurrentOpponent,
  selectCurrentAsyncState,
  selectCurrentCategory,
  selectIntervalRunning,
  selectTimeLeftQuestion,
  selectTimeLeftQuestionInPercent,
  selectShownOpponentScore,
  selectOpponentUserMap,
  selectShowGameAlreadyRunningDialog,
  selectShowChallengeAlreadySendDialog,
  selectUserGamePoints,
  selectOpponentGamePoints,
  selectChallengeDeclined,
  selectIsNextQuestionLoading,
} = regularAsyncMultiplayerFeature;

export const selectRegularAsmVm = createSelector(
  selectCurrentGame,
  selectUserPlayerNumber,
  selectOpponentPlayerNumber,
  selectCurrentCategory,
  selectShowGameAlreadyRunningDialog,
  selectShowChallengeAlreadySendDialog,
  selectCurrentAsyncState,
  selectCurrentOpponent,
  selectGameLoading,
  selectStepLoading,
  selectIsNextQuestionLoading,
  selectShownOpponentScore,
  selectError,
  selectUserGamePoints,
  selectOpponentGamePoints,
  (
    currentGame: RegularAsyncMultiplayerGame | null,
    userPlayerNumber: PlayerNumber | null,
    opponentPlayerNumber: PlayerNumber | null,
    currentCategory: AsyncGameRoundCategory | null,
    showGameAlreadyRunningDialog: boolean,
    showChallengeAlreadySendDialog: boolean,
    currentAsyncState: AsyncState | null,
    currentOpponent: AsyncMultiplayerUser | null,
    gameLoading: boolean,
    stepLoading: boolean,
    isNextQuestionLoading: boolean,
    shownOpponentScore: number | null,
    error: KikkzError | null,
    userGamePoints: number | null,
    opponentGamePoints: number | null,
  ) => {
    const vm: RegularAsyncMultiplayerViewModel = {
      currentGame,
      userPlayerNumber,
      opponentPlayerNumber,
      currentCategory,
      showGameAlreadyRunningDialog,
      showChallengeAlreadySendDialog,
      currentAsyncState,
      currentOpponent,
      gameLoading,
      stepLoading,
      isNextQuestionLoading,
      shownOpponentScore,
      error,
      userGamePoints,
      opponentGamePoints,
      asyncMultiplayerType: AsyncMultiplayerType.regular,
    };
    return vm;
  },
);
