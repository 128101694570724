import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { scopeLoader } from '@kiq/shared/util/translation';

@Component({
    selector: 'kiq-challengers-loader',
    templateUrl: './challengers-loader.component.html',
    styleUrl: './challengers-loader.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: {
                scope: 'loader',
                loader: scopeLoader((lang: string, root: string) => import(`../${root}/${lang}.json`)),
            },
        },
    ],
    standalone: false
})
export class ChallengersLoaderComponent {
  @Input() loaderColor = 'rgb(var(--text-primary))';
}
