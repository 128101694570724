import { BaseAsyncMultiplayerViewModel } from './base-async-multiplayer-view-model';
import { VipUserQuizduelGameUserView } from './vip-user-quizduel-game-user-view';
import { VipAsyncMultiplayerGame } from './vip-async-multiplayer-game';
import { VipUserView } from './vip-user-view';

export interface VipAsyncMultiplayerViewModel extends BaseAsyncMultiplayerViewModel<VipAsyncMultiplayerGame> {
  headlinerVipGames: VipUserQuizduelGameUserView[] | null;
  myVisibleGameInstances: VipAsyncMultiplayerGame[] | null;
  gameInstanceVipUserMap: Map<string, VipUserView>;

  showGameAlreadyRunningDialog?: never;
  showChallengeAlreadySendDialog?: never;
  userGamePoints?: never;
  opponentGamePoints?: never;
}
