import { GameTopscorerQuestionOption } from './game-topscorer-question-option';
import { GameTopscorerQuestionType } from '@kiq/shared/enums';

export interface GameTopscorerQuestion {
  id?: string;
  type: GameTopscorerQuestionType;
  options: Array<GameTopscorerQuestionOption>;
  correctAnswerOption?: GameTopscorerQuestionOption;

  // if type = strongFoot
  questionPlaceholderValues?: Array<string>;
}
