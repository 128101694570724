import { GameQuizduelCategoryInternal } from './game-quizduel-category-internal';
import { VipUserQuizduelGameRound } from './vip-user-quizduel-game-round';
import { GameQuizduelQuestionInternal } from './game-quizduel-question-internal';

export interface VipUserQuizduelGameData {
  categories: Array<GameQuizduelCategoryInternal>;
  questionsByCategoryId: Map<Array<GameQuizduelQuestionInternal>, number>;
  currentRound: VipUserQuizduelGameRound;
  playedRounds: Array<VipUserQuizduelGameRound>;
  gameCompleted: boolean;
  gameCompletedAt: Date;
}
