import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectCurrentAdvertisementType,
  selectCurrentGamesOverviewAdvertisementAsset,
  selectCurrentInGameAdvertisementAsset,
  selectCurrentRankingAdvertisementAsset,
  selectGamesOverviewLoading,
  selectInGameLoading,
  selectRankingLoading,
} from './advertisement.reducer';
import { AdvertisementActions } from './advertisement.actions';
import { AdvertisementPlace, AdvertisementType } from '@kiq/shared/enums';
import { combineLatest, map, Observable } from 'rxjs';
import { ConfigFacade } from '@kiq/client/data-access/config';
import { NativeAppsService } from '@kiq/shared/data-access/native-apps';
import { toObservable } from '@angular/core/rxjs-interop';
import { SelfAdvertisementAsset, SelfAdvertisementViewportAsset } from '@kiq/shared/interfaces';
import { SnackbarData, SnackbarService, SnackbarType } from 'shared/util/snackbar';
import { translate } from '@jsverse/transloco';

@Injectable()
export class AdvertisementFacade {
  private readonly store = inject(Store);
  private readonly configFacade = inject(ConfigFacade);
  private readonly nativeAppsService = inject(NativeAppsService);
  private readonly snackbarService = inject(SnackbarService);

  readonly viewSize$ = toObservable(this.configFacade.viewSize);
  readonly currentRankingAdvertisementAsset$: Observable<SelfAdvertisementAsset | null> = this.store.select(
    selectCurrentRankingAdvertisementAsset,
  );
  readonly currentInGameAdvertisementAsset$: Observable<SelfAdvertisementAsset | null> = this.store.select(
    selectCurrentInGameAdvertisementAsset,
  );
  readonly currentGamesOverviewAdvertisementAsset$: Observable<SelfAdvertisementAsset | null> = this.store.select(
    selectCurrentGamesOverviewAdvertisementAsset,
  );

  readonly rankingLoading$: Observable<boolean> = this.store.select(selectRankingLoading);
  readonly inGameLoading$: Observable<boolean> = this.store.select(selectInGameLoading);
  readonly gamesOverviewLoading$: Observable<boolean> = this.store.select(selectGamesOverviewLoading);
  readonly currentAdvertisementType$: Observable<AdvertisementType> = this.store.select(selectCurrentAdvertisementType);

  readonly currentInGameAdvertisementViewportAsset$: Observable<SelfAdvertisementViewportAsset | null> = combineLatest([
    this.currentInGameAdvertisementAsset$,
    this.viewSize$,
  ]).pipe(map(([advertisementAsset, viewSize]) => advertisementAsset?.viewports[viewSize] ?? null));

  readonly currentRankingAdvertisementViewportAsset$: Observable<SelfAdvertisementViewportAsset | null> = combineLatest(
    [this.currentRankingAdvertisementAsset$, this.viewSize$],
  ).pipe(map(([advertisementAsset, viewSize]) => advertisementAsset?.viewports[viewSize] ?? null));

  readonly currentGamesOverviewAdvertisementViewportAsset$: Observable<SelfAdvertisementViewportAsset | null> =
    combineLatest([this.currentGamesOverviewAdvertisementAsset$, this.viewSize$]).pipe(
      map(([advertisementAsset, viewSize]) => advertisementAsset?.viewports[viewSize] ?? null),
    );

  getNextAdvertisementAsset(placement: AdvertisementPlace) {
    this.store.dispatch(AdvertisementActions.getNextAdvertisement({ placement }));
  }

  handleLinkClick(advertisementLink: string) {
    if (this.configFacade.nativeAppType() !== null) {
      this.nativeAppsService.goToUrl(advertisementLink);
    } else {
      window.open(advertisementLink, '_blank');
    }
  }

  handleLinkCopied(advertisementLink: string) {
    if (advertisementLink !== null) {
      navigator.clipboard.writeText(advertisementLink).then(() => {
        const snackbarData: SnackbarData = {
          message: translate('snackbar.linkCopied'),
          type: SnackbarType.SUCCESS,
        };

        this.snackbarService.show(snackbarData);
      });
    }
  }
}
