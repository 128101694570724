import { GameMode, GameType } from '@kiq/shared/enums';

export interface Game {
  id: string;
  name: string;
  icon: string;
  disabled?: boolean;
  type: GameType;
  gameMode?: GameMode;
}
