import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BACKEND_TOKEN } from '@kiq/client/util/config';
import { map, Observable } from 'rxjs';
import { BaseQuizduelAction } from '../interfaces/base-quizduel-action';
import {
  VipAsyncMultiplayerGame,
  VipUserQuizduelGameOverview,
  VipUserQuizduelGameState,
  VipUserQuizduelGameUserView,
} from '@kiq/shared/interfaces';
import { VipUserAsyncMultiplayerGameInstanceActionType } from '@kiq/shared/enums';
import { serializePayload } from '@kiq/shared/util/helper';

@Injectable()
export class VipAsyncMultiplayerService {
  private readonly http = inject(HttpClient);
  private readonly BACKEND_BASE_URL = inject(BACKEND_TOKEN);
  private readonly BACKEND_URL = `${this.BACKEND_BASE_URL}/api/v1/game`;
  private readonly GAME_PATH = '/game';
  private readonly QUIZ_DUEL_VIP_PATH = '/quizduel/vipUser';
  private readonly VIP_PATH = '/vip';
  private readonly OVERVIEW = '/overview';
  private readonly OPEN_OR_FIRST_INSTANCE_STATE = '/openOrFirstInstanceState';
  private readonly INSTANCE = '/instance';
  private readonly ACTION_URL = '/action';

  getAllVipGames$(): Observable<VipUserQuizduelGameOverview> {
    const url = `${this.BACKEND_URL}${this.QUIZ_DUEL_VIP_PATH}${this.GAME_PATH}${this.OVERVIEW}`;
    return this.http.get<VipUserQuizduelGameOverview>(url).pipe(
      map((overview) => {
        const myVisibleGameInstances = overview.myVisibleGameInstances.map((game) => this.getGameWithMaps(game));
        const myPlayedGameIds = new Set<string>(overview.myPlayedGameIds);

        return {
          ...overview,
          myPlayedGameIds,
          myVisibleGameInstances,
        };
      }),
    );
  }

  getVipUserGame$(vipUserQuizduelGameId: string) {
    const url = `${this.BACKEND_URL}${this.QUIZ_DUEL_VIP_PATH}${this.GAME_PATH}/${vipUserQuizduelGameId}`;
    return this.http.get<VipUserQuizduelGameUserView>(url);
  }

  getVipUserGameOpenOrFirstInstance$(vipUserQuizduelGameId: string) {
    const url = `${this.BACKEND_URL}${this.QUIZ_DUEL_VIP_PATH}${this.GAME_PATH}/${vipUserQuizduelGameId}${this.OPEN_OR_FIRST_INSTANCE_STATE}`;
    return this.http.get<VipAsyncMultiplayerGame>(url).pipe(map((game) => this.getGameWithMaps(game)));
  }

  createVipUserGameInstance$(vipUserQuizduelGameId: string) {
    const url = `${this.BACKEND_URL}${this.QUIZ_DUEL_VIP_PATH}${this.GAME_PATH}/${vipUserQuizduelGameId}`;
    return this.http.post<VipAsyncMultiplayerGame>(url, {}).pipe(map((game) => this.getGameWithMaps(game)));
  }

  postVipUserGameInstanceAction$(
    vipUserQuizduelGameId: string,
    vipUserQuizduelGameInstanceId: string,
    action: BaseQuizduelAction<VipAsyncMultiplayerGame, VipUserAsyncMultiplayerGameInstanceActionType>,
  ) {
    const url = `${this.BACKEND_URL}${this.QUIZ_DUEL_VIP_PATH}${this.GAME_PATH}/${vipUserQuizduelGameId}${this.INSTANCE}/${vipUserQuizduelGameInstanceId}${this.ACTION_URL}`;
    return this.http
      .post<VipAsyncMultiplayerGame>(url, serializePayload(action))
      .pipe(map((game) => this.getGameWithMaps(game)));
  }

  getProGameVipView$(gameLinkId: string): Observable<VipUserQuizduelGameState> {
    const url = `${this.BACKEND_URL}${this.QUIZ_DUEL_VIP_PATH}${this.VIP_PATH}/${gameLinkId}`;
    return this.http.get<VipUserQuizduelGameState>(url);
  }

  sendProGameActionVip$(
    gameLinkId: string,
    action: BaseQuizduelAction<VipUserQuizduelGameState, VipUserAsyncMultiplayerGameInstanceActionType>,
  ): Observable<VipUserQuizduelGameState> {
    const url = `${this.BACKEND_URL}${this.QUIZ_DUEL_VIP_PATH}${this.VIP_PATH}/${gameLinkId}${this.ACTION_URL}`;
    return this.http.post<VipUserQuizduelGameState>(url, serializePayload(action));
  }

  private getGameWithMaps(asyncGame: VipAsyncMultiplayerGame) {
    const playerScores = new Map();
    if (asyncGame.playerScores) {
      for (const [key, value] of Object.entries(asyncGame.playerScores)) {
        playerScores.set(key, value);
      }
      asyncGame.playerScores = playerScores;
    }

    const communityAnswerRatiosByCategoryIdQuestionIndexAnswerIdMap = new Map<
      string,
      Map<number, Map<string, number>>
    >();

    for (const [key, value] of Object.entries(
      asyncGame.gameStatistics.communityAnswerRatiosByCategoryIdQuestionIndexAnswerId,
    )) {
      const questionIndexAnswerIdMap = new Map<number, Map<string, number>>();
      for (const [key2, value2] of Object.entries(value)) {
        const answerIdMap = new Map<string, number>();
        for (const [key3, value3] of Object.entries(value2 as object)) {
          answerIdMap.set(key3, value3);
        }
        questionIndexAnswerIdMap.set(parseInt(key2), answerIdMap);
      }
      communityAnswerRatiosByCategoryIdQuestionIndexAnswerIdMap.set(key, questionIndexAnswerIdMap);
    }

    asyncGame.gameStatistics.communityAnswerRatiosByCategoryIdQuestionIndexAnswerId =
      communityAnswerRatiosByCategoryIdQuestionIndexAnswerIdMap;

    if (asyncGame.currentRound) {
      const playerAnswers = new Map();
      if (asyncGame.currentRound?.playerAnswers) {
        for (const [key, value] of Object.entries(asyncGame.currentRound?.playerAnswers)) {
          playerAnswers.set(key, value);
        }
        asyncGame.currentRound.playerAnswers = playerAnswers;
      }
    }

    if (asyncGame.playedRounds) {
      asyncGame.playedRounds.forEach((playedRound) => {
        const playerAnswers = new Map();
        if (playedRound.playerAnswers) {
          for (const [key, value] of Object.entries(playedRound.playerAnswers)) {
            playerAnswers.set(key, value);
          }
        }
        playedRound.playerAnswers = playerAnswers;
      });
    }
    return asyncGame;
  }
}
