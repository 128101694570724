import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'shared-favorite-club-icon',
    imports: [CommonModule],
    templateUrl: './favorite-club-icon.component.html',
    styleUrl: './favorite-club-icon.component.scss'
})
export class FavoriteClubIconComponent {
  @Input() hexFillColor1: string | undefined;
  @Input() hexFillColor2: string | undefined;
  @Input() iconSize = 62;
}
