import { FootballPlayerPosition, PreferredFoot } from '@kiq/shared/enums';

export interface FootballPlayerSearchRequest {
  pageNumber: number;
  pageSize: number;

  inFootballTeams?: Array<string>;
  displayName?: string;
  minAge?: number;
  maxAge?: number;
  nationality?: string;
  position?: FootballPlayerPosition;
  preferredFoot?: PreferredFoot;
}
