import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'kiq-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LoaderComponent {
  @Input() loaderColor = '#000';
}
