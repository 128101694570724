import { EventEmitter, Inject, inject, Injectable, Injector, Output, Renderer2, RendererFactory2 } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { BehaviorSubject, Subject, take, takeUntil } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { MODAL_CONFIG, MODAL_REF } from '../token/modal-data.token';
import { GameModalWrapperComponent } from '../component/game-modal-wrapper/game-modal-wrapper.component';
import { v4 as uuidv4 } from 'uuid';
import { ModalWrapperComponent } from '../component/modal-wrapper/modal-wrapper.component';
import { ModalConfig, ModalRef } from '@kiq/shared/interfaces';

interface ModalMetaData<T> {
  modalConfig: BehaviorSubject<ModalConfig>;
  modalRef: ModalRef<T>;
}

enum ModalType {
  GENERAL = 'general',
  NESTED = 'nested',
}

@Injectable({ providedIn: 'root' })
export class ModalService {
  @Output() closed = new EventEmitter<void>();

  // store the active modals in a map to be able to retrieve them by id
  private activeModals: Map<string, ModalMetaData<any>> = new Map();
  private activeNestedModals: Map<string, ModalMetaData<any>> = new Map();
  private injector = inject(Injector);
  private overlay: Overlay = inject(Overlay);
  private renderer: Renderer2;

  get hasNestedModalOpen() {
    return this.activeNestedModals.size > 0;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  // use this method to open a general modal (like login, register, etc.)
  openModal<T>(modalConfig: ModalConfig): ModalRef<T> | undefined {
    const activeModalMetaData = this.activeModals.values().next().value;

    if (this.activeModals.size > 0 && activeModalMetaData) {
      this.updateModalConfig(modalConfig, activeModalMetaData.modalRef.id, this.activeModals);
      const modalRef: ModalRef<T> = activeModalMetaData.modalRef as ModalRef<T>;
      return modalRef;
    }

    // generate a unique id for the modal and store it in the map
    const id = uuidv4();

    let positionStrategy: PositionStrategy = this.overlay.position().global().centerVertically().centerHorizontally();

    if (modalConfig.notFullscreen && modalConfig.useFullscreenModal) {
      positionStrategy = this.overlay.position().global().bottom().centerHorizontally();
    }

    const backdropClasses = ['overlay-backdrop'];

    backdropClasses.push('dark');

    let config: OverlayConfig = {
      positionStrategy,
      hasBackdrop: true,
      backdropClass: backdropClasses,
      panelClass: 'overlay-panel',
      width: 'auto',
    };

    if (modalConfig.overlayConfig) {
      config = {
        ...config,
        ...modalConfig.overlayConfig,
      };
    }

    if (modalConfig.useFullscreenModal) {
      config.width = '100vw';
      if (modalConfig.notFullscreen) {
        config.height = 'fit';
      } else {
        config.height = '100%';
      }
    } else {
      config.width = '600px';
      config.height = '800px';
      if (modalConfig.notFullscreen) {
        config.width = '600px';
        config.height = 'fit';
      }
    }

    const closeSubject = new Subject<T | undefined>();
    const overlayRef = this.overlay.create(config);

    const closeFn = (value?: T) => {
      this.removeComponent(overlayRef, id, closeSubject, modalConfig.useFullscreenModal, ModalType.GENERAL, value);
    };

    const afterClosed$ = closeSubject.asObservable().pipe(take(1));

    if (modalConfig?.enableBackdropClick) {
      overlayRef
        .backdropClick()
        .pipe(takeUntil(closeSubject))
        .subscribe(() => {
          closeFn();
        });
    }

    const modalRef: ModalRef<T> = {
      afterClosed$,
      id,
      close: closeFn,
    };

    const modalConfig$ = new BehaviorSubject<ModalConfig>(modalConfig);

    const portal = new ComponentPortal(ModalWrapperComponent, null, this.createInjector(modalConfig$, modalRef));

    overlayRef.attach(portal);

    const modalMetaData: ModalMetaData<T> = {
      modalConfig: modalConfig$,
      modalRef,
    };

    // store the modalRef in the map to be able to retrieve it by id
    this.activeModals.set(id, modalMetaData);

    return modalRef;
  }

  openNestedModal<T, R = undefined>(modalConfig: ModalConfig, closeAllOther = true): ModalRef<T> | undefined {
    const activeModalMetaData = this.activeNestedModals.values().next().value;

    if (this.activeNestedModals.size > 0 && activeModalMetaData) {
      this.updateModalConfig(modalConfig, activeModalMetaData.modalRef?.id, this.activeNestedModals);

      return activeModalMetaData.modalRef;
    }

    // generate a unique id for the modal and store it in the map
    const id = uuidv4();

    const positionStrategy: PositionStrategy = this.overlay.position().global().centerVertically().centerHorizontally();
    const backdropClasses = ['overlay-backdrop'];

    backdropClasses.push('dark');

    let config: OverlayConfig = {
      positionStrategy,
      hasBackdrop: true,
      backdropClass: backdropClasses,
      panelClass: 'overlay-panel',
      width: 'auto',
    };

    if (modalConfig.overlayConfig) {
      config = {
        ...config,
        ...modalConfig.overlayConfig,
      };
    }

    if (modalConfig.useFullscreenModal) {
      config.width = '100vw';
      config.height = '100%';
    } else {
      config.width = '600px';
      config.height = '800px';
    }

    const closeSubject = new Subject<T | undefined>();
    const overlayRef = this.overlay.create(config);

    const closeFn = (value?: T) => {
      this.removeComponent(overlayRef, id, closeSubject, modalConfig.useFullscreenModal, ModalType.NESTED, value);
    };

    const afterClosed$ = closeSubject.asObservable().pipe(take(1));

    if (modalConfig?.enableBackdropClick) {
      overlayRef
        .backdropClick()
        .pipe(takeUntil(closeSubject))
        .subscribe(() => {
          closeFn();
        });
    }

    const modalRef: ModalRef<T> = {
      afterClosed$,
      id,
      close: closeFn,
    };

    const modalConfig$ = new BehaviorSubject<ModalConfig>(modalConfig);

    const portal = new ComponentPortal(GameModalWrapperComponent, null, this.createInjector(modalConfig$, modalRef));

    overlayRef.attach(portal);

    const modalMetaData: ModalMetaData<T> = {
      modalConfig: modalConfig$,
      modalRef,
    };

    // store the modalRef in the map to be able to retrieve it by id
    this.activeNestedModals.set(id, modalMetaData);

    return modalRef;
  }

  close(id: string, modalType: ModalType = ModalType.GENERAL) {
    const modalMap = this.getModalMap(modalType);
    const activeModalMetaData = modalMap.get(id);

    if (!activeModalMetaData) {
      console.error('Modal with id ' + id + ' not found');
      return;
    }

    this.closed.emit();

    activeModalMetaData.modalRef.close();
  }

  closeAll(modalType: ModalType = ModalType.GENERAL) {
    const modalMap = this.getModalMap(modalType);
    for (const [id, modalMetaData] of modalMap) {
      this.close(id);
    }
    return true;
  }

  private getModalMap(modalType: ModalType) {
    switch (modalType) {
      case ModalType.GENERAL:
        return this.activeModals;
      case ModalType.NESTED:
        return this.activeNestedModals;
    }
  }

  private removeComponent<T>(
    overlayRef: OverlayRef,
    id: string,
    closeSubject: Subject<T | undefined>,
    withAnimation: boolean,
    modalType: ModalType,
    value?: T,
  ) {
    overlayRef.detach();

    const cleanUp = () => {
      overlayRef.dispose();

      // remove the modalRef from the map
      const modalMap = this.getModalMap(modalType);
      modalMap.delete(id);

      closeSubject.next(value);
      closeSubject.complete();
    };

    if (withAnimation) {
      // TODO: improve to dispose the overlayRef after the animation finish event.
      // Let the fadeout animation finish on mobile.
      setTimeout(() => {
        cleanUp();
      }, 350);
    } else {
      cleanUp();
    }
  }

  private createInjector<T>(modalConfig: BehaviorSubject<ModalConfig>, modalRef: ModalRef<T>) {
    return Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: MODAL_CONFIG,
          useValue: modalConfig,
        },
        {
          provide: MODAL_REF,
          useValue: modalRef,
        },
      ],
    });
  }

  /*
   * If another modal is opened while a modal is pending, the pending modal will be opened in place the current modal.
   */
  private updateModalConfig(modalConfig: ModalConfig, id: string, modalMap: Map<string, ModalMetaData<any>>) {
    const activeModalMetaData = modalMap.get(id);
    const modalConfig$ = activeModalMetaData?.modalConfig;
    modalConfig$?.next(modalConfig);
  }
}
