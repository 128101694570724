import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { CommonAsyncGameState } from '../../interfaces/common-async-game-state';
import {
  AsyncGameRoundCategory,
  KikkzError,
  VipAsyncMultiplayerGame,
  VipAsyncMultiplayerViewModel,
  VipUserQuizduelGameOverview,
  VipUserQuizduelGameUserView,
  VipUserView,
} from '@kiq/shared/interfaces';
import { AsyncMultiplayerType, AsyncState, PlayerNumber } from '@kiq/shared/enums';
import { immerOn } from 'ngrx-immer/store';
import { VipAsyncMultiplayerActions } from './vip-async-multiplayer.actions';
import { convertUserToMultiplayerUser } from '@kiq/shared/util/helper';
import { provideCommonAsyncActionHandlers } from '../../helper/shared-reducer.helpers';
import { createInitialCommonAsyncGameState } from '../../helper/initial-state.const';
import { getTimeLeft, getTimeLeftInPercent } from 'client/util/time-calculations';
import { RegularAsyncMultiplayerActions } from '../../regular/+state/regular-async-multiplayer.actions';

export interface VipAsyncMultiplayerState extends CommonAsyncGameState<VipAsyncMultiplayerGame> {
  currentVipUserGame: VipUserQuizduelGameUserView | null;
  hasActiveGameInstance: boolean | null;
  instanceLoading: boolean;
  myVisibleGameInstances: VipAsyncMultiplayerGame[] | null;
  headlinerVipGames: VipUserQuizduelGameUserView[] | null;
  gameInstanceVipUserMap: Map<string, VipUserView>;
  overview: VipUserQuizduelGameOverview | null;
  vipGameIdWhichHasInstances: Set<string>;

  currentAsyncState: AsyncState | null;
}

const initialState: VipAsyncMultiplayerState = {
  ...createInitialCommonAsyncGameState<VipAsyncMultiplayerGame>(),
  currentVipUserGame: null,
  hasActiveGameInstance: null,
  instanceLoading: false,
  myVisibleGameInstances: null,
  headlinerVipGames: null,
  overview: null,
  gameInstanceVipUserMap: new Map<string, VipUserView>(),
  vipGameIdWhichHasInstances: new Set<string>(),

  currentOpponent: null,
  currentAsyncState: null,
};

export const vipAsyncMultiplayerFeature = createFeature({
  name: 'vipAsyncMultiplayer',
  reducer: createReducer(
    initialState,
    ...provideCommonAsyncActionHandlers<
      VipAsyncMultiplayerState,
      VipAsyncMultiplayerGame,
      KikkzError,
      AsyncGameRoundCategory
    >({
      showStepLoader: VipAsyncMultiplayerActions.showStepLoader,
      showGameLoader: VipAsyncMultiplayerActions.showGameLoader,
      refreshGameStateOnFocus: VipAsyncMultiplayerActions.refreshGameStateOnFocus,
      startIntervalSuccess: VipAsyncMultiplayerActions.startIntervalSuccess,
      stopInterval: VipAsyncMultiplayerActions.stopInterval,
      timeTick: VipAsyncMultiplayerActions.timeTick,
      getNextQuestion: VipAsyncMultiplayerActions.getNextQuestion,
      getNextQuestionSuccess: VipAsyncMultiplayerActions.getNextQuestionSuccess,
      getNextQuestionFailure: VipAsyncMultiplayerActions.getNextQuestionFailure,
      selectCategory: VipAsyncMultiplayerActions.selectCategory,
      selectCategorySuccess: VipAsyncMultiplayerActions.selectCategorySuccess,
      selectCategoryFailure: VipAsyncMultiplayerActions.selectCategoryFailure,
      answerQuestion: VipAsyncMultiplayerActions.answerQuestion,
      answerQuestionSuccess: VipAsyncMultiplayerActions.answerQuestionSuccess,
      answerQuestionFailure: VipAsyncMultiplayerActions.answerQuestionFailure,
      setCurrentCategory: VipAsyncMultiplayerActions.setCurrentCategory,
      setShownOpponentScore: VipAsyncMultiplayerActions.setShownOpponentScore,
    }),
    immerOn(VipAsyncMultiplayerActions.closeCurrentGame, (state) => {
      const newState = {
        ...initialState,
        myVisibleGameInstances: state.myVisibleGameInstances,
        headlinerVipGames: state.headlinerVipGames,
        overview: state.overview,
        gameInstanceVipUserMap: state.gameInstanceVipUserMap,
      };

      Object.assign(state, newState);
    }),
    immerOn(VipAsyncMultiplayerActions.showInstanceLoader, (state) => {
      state.instanceLoading = true;
    }),
    immerOn(VipAsyncMultiplayerActions.getVipGamesOverview, (state) => {
      state.allGamesLoading = true;
      state.error = null;
    }),
    immerOn(VipAsyncMultiplayerActions.getVipGamesOverviewSuccess, (state, { response }) => {
      state.allGamesLoading = false;

      state.myVisibleGameInstances = response.myVisibleGameInstances;

      const newGameInstanceVipUserMap = new Map<string, VipUserView>();

      response.myVisibleGameInstances.forEach((instance) => {
        const gameId = instance.gameId;

        const vipGame = response.availableGames.find((game) => game.id === gameId);

        if (vipGame) {
          newGameInstanceVipUserMap.set(instance.instanceId, vipGame.vipUser);
        }
      });

      const vipGameIdWhichHasInstances = new Set<string>();
      response.myVisibleGameInstances.forEach((instance) => {
        vipGameIdWhichHasInstances.add(instance.gameId);
      });

      state.headlinerVipGames = generateHeadliner(response.availableGames, vipGameIdWhichHasInstances);
      state.gameInstanceVipUserMap = newGameInstanceVipUserMap;
      state.overview = response;
      state.vipGameIdWhichHasInstances = vipGameIdWhichHasInstances;
    }),
    immerOn(VipAsyncMultiplayerActions.getVipGamesOverviewFailure, (state, { error }) => {
      state.allGamesLoading = false;
      state.error = error ?? null;
    }),
    immerOn(VipAsyncMultiplayerActions.getVipUserGame, (state) => {
      state.error = null;
      state.gameLoading = true;
    }),
    immerOn(VipAsyncMultiplayerActions.getVipUserGameSuccess, (state, { response }) => {
      state.currentVipUserGame = response;
      state.gameLoading = false;
      state.currentOpponent = convertUserToMultiplayerUser(response);
    }),
    immerOn(VipAsyncMultiplayerActions.getVipUserGameFailure, (state, { error }) => {
      state.gameLoading = false;
      state.error = error ?? null;
    }),
    immerOn(VipAsyncMultiplayerActions.getVipUserGameInstanceForVipUserGame, (state) => {
      state.hasActiveGameInstance = null;
      state.instanceLoading = true;
    }),
    immerOn(VipAsyncMultiplayerActions.getVipUserGameInstanceForVipUserGameSuccess, (state, { response }) => {
      state.instanceLoading = false;
      state.hasActiveGameInstance = true;
      state.currentGame = response;
      state.userPlayerNumber = PlayerNumber.PLAYER_1;
      state.opponentPlayerNumber = PlayerNumber.PLAYER_2;

      if (response.currentRound?.currentQuestionTimeoutTimestamp) {
        state.timeLeftQuestion = getTimeLeft(
          response?.currentRound?.currentQuestionTimeoutTimestamp,
          response.currentServerTime,
        );
        state.timeLeftQuestionInPercent = getTimeLeftInPercent(
          state.timeLeftQuestion,
          response?.currentRound?.currentQuestion?.maxAnswerDurationSeconds,
        );
      }
    }),
    immerOn(VipAsyncMultiplayerActions.getVipUserGameInstanceForVipUserGameFailure, (state, { error }) => {
      state.instanceLoading = false;

      if (error?.errorCode === 20002) {
        state.hasActiveGameInstance = false;
      } else {
        state.error = error ?? null;
      }
    }),
    immerOn(VipAsyncMultiplayerActions.createVipUserGameInstance, (state) => {
      state.error = null;
    }),
    immerOn(VipAsyncMultiplayerActions.createVipUserGameInstanceSuccess, (state, { response }) => {
      state.instanceLoading = false;
      state.currentGame = response;
      state.userPlayerNumber = PlayerNumber.PLAYER_1;
      state.opponentPlayerNumber = PlayerNumber.PLAYER_2;
    }),
    immerOn(VipAsyncMultiplayerActions.createVipUserGameInstanceFailure, (state, { error }) => {
      state.instanceLoading = false;
      state.error = error ?? null;
    }),
    immerOn(VipAsyncMultiplayerActions.setCurrentAsyncState, (state, { currentAsyncState }) => {
      state.currentAsyncState = currentAsyncState;
    }),
    immerOn(RegularAsyncMultiplayerActions.deleteEndedGameSuccess, (state) => {
      state.headlinerVipGames = generateHeadliner(
        state.overview?.availableGames ?? [],
        state.vipGameIdWhichHasInstances,
      );
    }),
  ),
});

export const {
  selectCurrentGame,
  selectIntervalRunning,
  selectTimeLeftQuestion,
  selectHeadlinerVipGames,
  selectMyVisibleGameInstances,
  selectUserPlayerNumber,
  selectOpponentPlayerNumber,
  selectCurrentCategory,
  selectCurrentAsyncState,
  selectCurrentOpponent,
  selectGameLoading,
  selectStepLoading,
  selectIsNextQuestionLoading,
  selectShownOpponentScore,
  selectError,
  selectCurrentVipUserGame,
  selectHasActiveGameInstance,
  selectGameInstanceVipUserMap,
  selectInstanceLoading,
  selectTimeLeftQuestionInPercent,
  selectOverview,
} = vipAsyncMultiplayerFeature;

export const selectVipViewModel = createSelector(
  selectHeadlinerVipGames,
  selectCurrentGame,
  selectUserPlayerNumber,
  selectOpponentPlayerNumber,
  selectCurrentCategory,
  selectCurrentAsyncState,
  selectCurrentOpponent,
  selectGameLoading,
  selectStepLoading,
  selectIsNextQuestionLoading,
  selectShownOpponentScore,
  selectError,
  selectMyVisibleGameInstances,
  selectGameInstanceVipUserMap,
  (
    headlinerVipGames,
    currentGame,
    userPlayerNumber,
    opponentPlayerNumber,
    currentCategory,
    currentAsyncState,
    currentOpponent,
    gameLoading,
    stepLoading,
    isNextQuestionLoading,
    shownOpponentScore,
    error,
    myVisibleGameInstances,
    gameInstanceVipUserMap,
  ) => {
    const vm: VipAsyncMultiplayerViewModel = {
      headlinerVipGames,
      currentGame,
      userPlayerNumber,
      opponentPlayerNumber,
      currentCategory,
      currentAsyncState,
      currentOpponent,
      gameLoading,
      stepLoading,
      isNextQuestionLoading,
      shownOpponentScore,
      error,
      myVisibleGameInstances,
      gameInstanceVipUserMap,
      asyncMultiplayerType: AsyncMultiplayerType.vip,
    };

    return vm;
  },
);

export const selectGameAndInstanceLoading = createSelector(
  selectGameLoading,
  selectInstanceLoading,
  (gameLoading, instanceLoading) => gameLoading || instanceLoading,
);

function generateHeadliner(allGames: VipUserQuizduelGameUserView[], vipGameIdWhichHasInstances: Set<string>) {
  const headlinerVipGames: VipUserQuizduelGameUserView[] = [];

  allGames.forEach((game) => {
    if (game.isHeadliner && !vipGameIdWhichHasInstances.has(game.id)) {
      headlinerVipGames.push(game);
    }
  });

  return headlinerVipGames;
}
