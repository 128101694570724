import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { HttpClient } from '@angular/common/http';
import { Inject, inject, Injectable, Optional } from '@angular/core';
import { ASSET_TRANSFORMER } from '@kiq/shared/util/asset-helper';
import { AssetTransformer } from '@kiq/shared/interfaces';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  constructor(@Optional() @Inject(ASSET_TRANSFORMER) private assetTransformer?: AssetTransformer) {}

  getTranslation(lang: string) {
    const assetPath = `shared-assets/i18n/${lang}.json`;

    const url = this.assetTransformer ? this.assetTransformer.getAssetWithHash(assetPath) : assetPath;
    return this.http.get<Translation>(url);
  }
}
