import { GameMixedZoneConfig } from './game-mixed-zone-config';
import { GameMixedZoneQuestion } from './game-mixed-zone-question';
import { GameEndReason } from '../../../../enums/src/lib/enum/game-end-reason';

export interface GameMixedZoneState {
  gameId: string;
  config: GameMixedZoneConfig;
  maxTurnDurationSeconds?: number;
  turnTimeoutTimestamp?: Date;
  currentQuestion: GameMixedZoneQuestion;
  turnEnded?: boolean;
  gameEnded: boolean;
  gameEndReason?: GameEndReason;
  userScore?: number;
}
