import { BoDashboardGameCount } from './bo-dashboard-game-count';
import { RankingGameType } from '@kiq/shared/enums';

export interface BoDashboardGameCounts {
  gameType: RankingGameType;
  countsLastWeek: Array<BoDashboardGameCount>;
  countThisMonth: BoDashboardGameCount;
  countLastMonth: BoDashboardGameCount;
  countAll: BoDashboardGameCount;
  activeCountLastHour: BoDashboardGameCount;
}
