import { PlayerNumber } from '@kiq/shared/enums';
import { AsyncQuestion } from './async-question';
import { AsyncDuellAnswer } from './async-duell-answer';

export interface AsyncGameRound {
  categoryId: string;
  playerAtTurn: PlayerNumber;
  maxRoundDurationSeconds: number;
  roundTimeoutTimestamp?: Date;
  currentQuestionIndex?: number;
  currentQuestion?: AsyncQuestion;
  currentQuestionTimeoutTimestamp?: Date;
  playerAnswers: Map<PlayerNumber, Array<AsyncDuellAnswer>>;
}
