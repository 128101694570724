import { inject, Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, switchMap, take, throwError, timeout } from 'rxjs';
import { BACKEND_TOKEN } from '@kiq/client/util/config';
import { AUTH_SERVICE } from '@kiq/shared/data-access/authentication';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  private readonly BACKEND_BASE_URL = inject(BACKEND_TOKEN) as string;
  authenticationService = inject(AUTH_SERVICE);

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!req.url.includes(this.BACKEND_BASE_URL)) {
      return next.handle(req);
    }

    return this.authenticationService.token$.pipe(
      timeout(2000),
      take(1),
      switchMap((token) => {
        const modifiedReq = token
          ? req.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`,
                'Cache-Control': 'no-cache, no-store, must-revalidate',
              },
            })
          : req;

        return next.handle(modifiedReq);
      }),
      catchError((error) => {
        if (error.name === 'TimeoutError') {
          console.error('Error while waiting for token in interceptor', error);
          return next.handle(req);
        } else {
          return throwError(() => error);
        }
      }),
    );
  }
}
