import { AsyncMultiplayerType, AsyncState, PlayerNumber } from '@kiq/shared/enums';
import { BaseAsyncMultiplayerGame } from './base-async-multiplayer-game';
import { AsyncGameRoundCategory } from './async-game-round-category';
import { AsyncMultiplayerUser } from './async-multiplayer-user';
import { KikkzError } from './kikkz-error';

export interface BaseAsyncMultiplayerViewModel<TGame extends BaseAsyncMultiplayerGame> {
  currentGame: TGame | null;
  userPlayerNumber: PlayerNumber | null;
  opponentPlayerNumber: PlayerNumber | null;
  currentCategory: AsyncGameRoundCategory | null;
  currentAsyncState: AsyncState | null;
  currentOpponent: AsyncMultiplayerUser | null;
  gameLoading: boolean;
  stepLoading: boolean;
  isNextQuestionLoading: boolean;
  shownOpponentScore: number | null;
  error: KikkzError | null;
  asyncMultiplayerType: AsyncMultiplayerType;
}
