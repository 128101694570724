import { GameTttMultiplayerEventType, PlayerNumber } from '@kiq/shared/enums';
import { GameTttState } from './game-ttt-state';
import { GameTttAction } from './game-ttt-action';
import { KikkzError } from './kikkz-error';

export interface GameTttMultiplayerEvent {
  type: GameTttMultiplayerEventType;
  state: GameTttState | null;
  clientId: string | null;

  //only for type error
  error?: KikkzError;

  // only for type action
  invokedByPlayer: PlayerNumber;
  relatedAction: GameTttAction;
}
