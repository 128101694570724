import { emptyProps, props } from '@ngrx/store';
import { FootballPlayer, GameTopscorerQuestionOption, KikkzError } from '@kiq/shared/interfaces';

export function createGetNextQuestionActions<TResponse>() {
  return {
    'get next question': emptyProps(),
    'get next question success': props<{ response: TResponse }>(),
    'get next question failure': props<{ error?: KikkzError }>(),
  };
}

export function createSelectNextCategoryActions<TResponse>() {
  return {
    'select category': props<{ categoryId: string }>(),
    'select category success': props<{ response: TResponse }>(),
    'select category failure': props<{ error?: KikkzError }>(),
  };
}

export function createAnswerQuestionActions<TResponse>() {
  return {
    'answer question': props<{ footballPlayer?: FootballPlayer; topscorerAnswer?: GameTopscorerQuestionOption }>(),
    'answer question success': props<{ response: TResponse }>(),
    'answer question failure': props<{ error?: KikkzError }>(),
  };
}

export function createSetCurrentCategoryActions<TCategory>() {
  return {
    'set current category': props<{ currentCategory: TCategory | null }>(),
  };
}

export function setShownOpponentScoreActions() {
  return {
    'set shown opponent score': props<{ shownOpponentScore: number | null }>(),
    'set shown opponent score failure': props<{ shownOpponentScore: number | null }>(),
  };
}
