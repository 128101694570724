import { inject, Injectable } from '@angular/core';
import { RegularAsyncMultiplayerGame } from '@kiq/shared/interfaces';
import {
  AsyncGameEndReason,
  AsyncGameStateServer,
  AsyncMultiplayerActionType,
  AsyncState,
  KikkzErrorType,
  PlayerNumber,
} from '@kiq/shared/enums';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { RegularAsyncMultiplayerService } from '../../service/regular-async-multiplayer.service';
import { RegularAsyncMultiplayerFacade } from './regular-async-multiplayer.facade';
import { BasicUserView } from '@kiq/shared/classes';
import { catchError, concatMap, filter, forkJoin, map, of, withLatestFrom } from 'rxjs';
import { UserBackendService, UserFacade } from '@kiq/client/data-access/user';
import { translate } from '@jsverse/transloco';
import { SnackbarService, SnackbarType } from 'shared/util/snackbar';
import { BaseQuizduelAction } from '../../interfaces/base-quizduel-action';
import {
  convertUserToMultiplayerUser,
  createIntervalTimerEffect,
  createSimpleRequestEffect,
  createSimpleRequestWithLoaderEffect,
  createTimeTickEffect,
} from '@kiq/shared/util/helper';
import { createAsyncGameActionEffect, createSetCurrentCategoryEffect } from '../../helper/shared-effects.helpers';
import { RegularAsyncMultiplayerActions } from './regular-async-multiplayer.actions';

@Injectable()
export class RegularAsyncMultiplayerEffects {
  private readonly actions = inject(Actions);
  private readonly regularAsyncMultiplayerService = inject(RegularAsyncMultiplayerService);
  private readonly regularAsyncMultiplayerFacade = inject(RegularAsyncMultiplayerFacade);
  private readonly userFacade = inject(UserFacade);
  private readonly snackbarService = inject(SnackbarService);
  private readonly userBackendService = inject(UserBackendService);

  private readonly TICK_TIME = 1000;

  getAsyncGame = createSimpleRequestWithLoaderEffect(this.actions, {
    triggerAction: RegularAsyncMultiplayerActions.getAsyncGame,
    successAction: RegularAsyncMultiplayerActions.getAsyncGameSuccess,
    failAction: RegularAsyncMultiplayerActions.getAsyncGameFailure,
    showLoaderAction: RegularAsyncMultiplayerActions.showGameLoader,
    serviceMethod: (gameId) => this.regularAsyncMultiplayerService.getAsyncMultiplayerGame(gameId),
    mapSuccessPayload: (response) => ({ response }),
    mapTriggerPayload: (triggerPayload) => [triggerPayload.gameId] as [string],
  });

  getAllAsyncGames = createEffect(() => {
    return this.actions.pipe(
      ofType(
        RegularAsyncMultiplayerActions.getAllAsyncGames,
        RegularAsyncMultiplayerActions.deleteEndedGameSuccess,
        RegularAsyncMultiplayerActions.challengeUserSuccess,
        RegularAsyncMultiplayerActions.challengeUserAgainSuccess,
      ),
      withLatestFrom(this.userFacade.user$),
      filter((user) => user !== null),
      concatMap(([, user]) => {
        return this.regularAsyncMultiplayerService.getAllAsyncMultiplayerGames().pipe(
          map((allAsyncMultiplayerGames) => {
            return RegularAsyncMultiplayerActions.getAllAsyncGamesSuccess({ allAsyncMultiplayerGames, user });
          }),
          catchError((error) => {
            return of(RegularAsyncMultiplayerActions.getAllAsyncGamesFailure({ error: error }));
          }),
        );
      }),
    );
  });

  createOrGetGameFromInvitationLink = createSimpleRequestWithLoaderEffect(this.actions, {
    triggerAction: RegularAsyncMultiplayerActions.createOrGetAsyncGameFromInvitationLink,
    successAction: RegularAsyncMultiplayerActions.createOrGetAsyncGameFromInvitationLinkSuccess,
    failAction: RegularAsyncMultiplayerActions.createOrGetAsyncGameFromInvitationLinkFailure,
    showLoaderAction: RegularAsyncMultiplayerActions.showStepLoader,
    serviceMethod: (invitationLinkId) =>
      this.regularAsyncMultiplayerService.createOrGetGameFromInviteLink(invitationLinkId),
    mapSuccessPayload: (response) => ({ response }),
    mapTriggerPayload: (triggerPayload) => [triggerPayload.invitationLinkId] as [string],
  });

  getOpponentUserFromInvitationLink = createSimpleRequestEffect(this.actions, {
    triggerAction: RegularAsyncMultiplayerActions.getOpponentFromInvitationLink,
    successAction: RegularAsyncMultiplayerActions.getOpponentFromInvitationLinkSuccess,
    failAction: RegularAsyncMultiplayerActions.getOpponentFromInvitationLinkFailure,
    serviceMethod: (invitationLinkId) =>
      this.regularAsyncMultiplayerService.getOpponentFromInvitationLink(invitationLinkId),
    mapSuccessPayload: (response) => ({ opponent: response }),
    mapTriggerPayload: (triggerPayload) => [triggerPayload.invitationLinkId] as [string],
  });

  getNextQuestion = createAsyncGameActionEffect(this.actions, {
    triggerAction: RegularAsyncMultiplayerActions.getNextQuestion,
    successAction: RegularAsyncMultiplayerActions.getNextQuestionSuccess,
    failAction: RegularAsyncMultiplayerActions.getNextQuestionFailure,
    showLoaderAction: RegularAsyncMultiplayerActions.showStepLoader,
    serviceMethod: (
      gameId: string,
      action: BaseQuizduelAction<RegularAsyncMultiplayerGame, AsyncMultiplayerActionType>,
    ) => this.regularAsyncMultiplayerService.sendAction(gameId, action),
    currentGame$: () => this.regularAsyncMultiplayerFacade.currentGame$,
    payload: {
      type: AsyncMultiplayerActionType.NEXT_QUESTION,
    },
    mapSuccessPayload: (response) => ({ response }),
    mapTriggerPayload: (triggerPayload) => ({}),
  });

  selectCategory = createAsyncGameActionEffect(this.actions, {
    triggerAction: RegularAsyncMultiplayerActions.selectCategory,
    successAction: RegularAsyncMultiplayerActions.selectCategorySuccess,
    failAction: RegularAsyncMultiplayerActions.selectCategoryFailure,
    showLoaderAction: RegularAsyncMultiplayerActions.showStepLoader,
    serviceMethod: (
      gameId: string,
      action: BaseQuizduelAction<RegularAsyncMultiplayerGame, AsyncMultiplayerActionType>,
    ) => this.regularAsyncMultiplayerService.sendAction(gameId, action),
    currentGame$: () => this.regularAsyncMultiplayerFacade.currentGame$,
    payload: {
      type: AsyncMultiplayerActionType.SELECT_CATEGORY,
    },
    mapSuccessPayload: (response) => ({ response }),
    mapTriggerPayload: (triggerPayload) => ({
      categoryId: triggerPayload.categoryId,
    }),
  });

  answerQuestion = createAsyncGameActionEffect(this.actions, {
    triggerAction: RegularAsyncMultiplayerActions.answerQuestion,
    successAction: RegularAsyncMultiplayerActions.answerQuestionSuccess,
    failAction: RegularAsyncMultiplayerActions.answerQuestionFailure,
    showLoaderAction: RegularAsyncMultiplayerActions.showStepLoader,
    serviceMethod: (
      gameId: string,
      action: BaseQuizduelAction<RegularAsyncMultiplayerGame, AsyncMultiplayerActionType>,
    ) => this.regularAsyncMultiplayerService.sendAction(gameId, action),
    currentGame$: () => this.regularAsyncMultiplayerFacade.currentGame$,
    payload: {
      type: AsyncMultiplayerActionType.ANSWER_QUESTION,
    },
    mapSuccessPayload: (response) => ({ response }),
    mapTriggerPayload: (triggerPayload) => ({
      answerOptionTicTacToe: triggerPayload?.footballPlayer,
      answerOptionTransferHistory: triggerPayload?.footballPlayer,
      answerOptionTopscorer: triggerPayload?.topscorerAnswer,
    }),
  });

  giveUpGame = createAsyncGameActionEffect(this.actions, {
    triggerAction: RegularAsyncMultiplayerActions.giveUpGame,
    successAction: RegularAsyncMultiplayerActions.giveUpGameSuccess,
    failAction: RegularAsyncMultiplayerActions.giveUpGameFail,
    showLoaderAction: RegularAsyncMultiplayerActions.showStepLoader,
    serviceMethod: (
      gameId: string,
      action: BaseQuizduelAction<RegularAsyncMultiplayerGame, AsyncMultiplayerActionType>,
    ) => this.regularAsyncMultiplayerService.sendAction(gameId, action),
    currentGame$: () => this.regularAsyncMultiplayerFacade.currentGame$,
    payload: {
      type: AsyncMultiplayerActionType.GIVE_UP,
    },
    mapSuccessPayload: (response) => ({ response }),
    mapTriggerPayload: (triggerPayload) => ({}),
  });

  declineChallenge = createAsyncGameActionEffect(this.actions, {
    triggerAction: RegularAsyncMultiplayerActions.declineChallenge,
    successAction: RegularAsyncMultiplayerActions.declineChallengeSuccess,
    failAction: RegularAsyncMultiplayerActions.declineChallengeFail,
    showLoaderAction: RegularAsyncMultiplayerActions.showStepLoader,
    serviceMethod: (
      gameId: string,
      action: BaseQuizduelAction<RegularAsyncMultiplayerGame, AsyncMultiplayerActionType>,
    ) => this.regularAsyncMultiplayerService.sendAction(gameId, action),
    currentGame$: () => this.regularAsyncMultiplayerFacade.currentGame$,
    payload: {
      type: AsyncMultiplayerActionType.DECLINE_CHALLENGE,
    },
    mapSuccessPayload: (response) => ({ response }),
    mapTriggerPayload: (triggerPayload) => ({}),
  });

  acceptChallenge = createAsyncGameActionEffect(this.actions, {
    triggerAction: RegularAsyncMultiplayerActions.acceptChallenge,
    successAction: RegularAsyncMultiplayerActions.acceptChallengeSuccess,
    failAction: RegularAsyncMultiplayerActions.acceptChallengeFail,
    showLoaderAction: RegularAsyncMultiplayerActions.showStepLoader,
    serviceMethod: (
      gameId: string,
      action: BaseQuizduelAction<RegularAsyncMultiplayerGame, AsyncMultiplayerActionType>,
    ) => this.regularAsyncMultiplayerService.sendAction(gameId, action),
    currentGame$: () => this.regularAsyncMultiplayerFacade.currentGame$,
    payload: {
      type: AsyncMultiplayerActionType.ACCEPT_CHALLENGE,
    },
    mapSuccessPayload: (response) => ({ response }),
    mapTriggerPayload: (triggerPayload) => ({}),
  });

  challengeUser = createSimpleRequestWithLoaderEffect(this.actions, {
    triggerAction: RegularAsyncMultiplayerActions.challengeUser,
    successAction: RegularAsyncMultiplayerActions.challengeUserSuccess,
    failAction: RegularAsyncMultiplayerActions.challengeUserFail,
    showLoaderAction: RegularAsyncMultiplayerActions.showStepLoader,
    serviceMethod: (opponentId) => this.regularAsyncMultiplayerService.challengeUser(opponentId),
    mapSuccessPayload: (response) => ({ response }),
    mapTriggerPayload: (triggerPayload) => [triggerPayload.opponentId] as [string],
    errorCallback: (error) => {
      if (!error) {
        return;
      }

      let errorMessage = translate('gameAsyncDataAccess.genericErrorMessage');
      if (error.error.errorCode === KikkzErrorType.ASYNC_GAME_TOO_MANY_PENDING_GAMES_USER) {
        errorMessage = translate('gameAsyncDataAccess.tooManyChallengesSent');
      }
      if (error.error.errorCode === KikkzErrorType.ASYNC_GAME_TOO_MANY_PENDING_GAMES_OPPONENT) {
        errorMessage = translate('gameAsyncDataAccess.tooManyChallengesOpponent');
      }

      this.snackbarService.show({
        message: errorMessage,
        type: SnackbarType.ERROR,
      });
    },
  });

  challengeUserAgain = createSimpleRequestWithLoaderEffect(this.actions, {
    triggerAction: RegularAsyncMultiplayerActions.challengeUserAgain,
    successAction: RegularAsyncMultiplayerActions.challengeUserAgainSuccess,
    failAction: RegularAsyncMultiplayerActions.challengeUserAgainFail,
    showLoaderAction: RegularAsyncMultiplayerActions.showStepLoader,
    serviceMethod: (previousGameId) =>
      this.regularAsyncMultiplayerService.challengeUserAfterPreviousGame(previousGameId),
    mapSuccessPayload: (response) => ({ response }),
    mapTriggerPayload: (triggerPayload) => [triggerPayload.previousGameId] as [string],
  });

  hideGameWhenEnded = createSimpleRequestEffect(this.actions, {
    triggerAction: RegularAsyncMultiplayerActions.deleteEndedGame,
    successAction: RegularAsyncMultiplayerActions.deleteEndedGameSuccess,
    failAction: RegularAsyncMultiplayerActions.deleteEndedGameFail,
    serviceMethod: () => this.regularAsyncMultiplayerService.hideWhenEnded(),
    mapSuccessPayload: () => ({}),
    mapTriggerPayload: () => [] as [],
  });

  startInterval = createIntervalTimerEffect(this.actions, {
    triggerAction: RegularAsyncMultiplayerActions.startInterval,
    tickAction: RegularAsyncMultiplayerActions.timeTick,
    stopAction: RegularAsyncMultiplayerActions.stopInterval,
    tickTime: this.TICK_TIME,
    timerAlreadyRunning$: this.regularAsyncMultiplayerFacade.intervalRunning$,
  });

  timeTick = createTimeTickEffect<void, { footballPlayer: undefined; topscorerAnswer: undefined }>(this.actions, {
    triggerAction: RegularAsyncMultiplayerActions.timeTick,
    timeUpAction: RegularAsyncMultiplayerActions.answerQuestion,
    timeLeft$: this.regularAsyncMultiplayerFacade.timeLeftQuestion$,
    condition$: this.regularAsyncMultiplayerFacade.currentGame$.pipe(
      map((game) => game?.currentRound?.currentQuestionTimeoutTimestamp !== null && !game?.gameEnded),
    ),
    timeUpPayload: () => {
      return { footballPlayer: undefined, topscorerAnswer: undefined };
    },
  });

  setCurrentCategory = createSetCurrentCategoryEffect<RegularAsyncMultiplayerGame>(this.actions, {
    triggerActions: [
      RegularAsyncMultiplayerActions.getAsyncGameSuccess,
      RegularAsyncMultiplayerActions.createOrGetAsyncGameFromInvitationLinkSuccess,
      RegularAsyncMultiplayerActions.challengeUserSuccess,
      RegularAsyncMultiplayerActions.challengeUserAgainSuccess,
      RegularAsyncMultiplayerActions.acceptChallengeSuccess,
      RegularAsyncMultiplayerActions.selectCategorySuccess,
    ],
    returnAction: RegularAsyncMultiplayerActions.setCurrentCategory,
  });

  onGetAllAsyncMultiplayerGamesSuccess = createEffect(() => {
    return this.actions.pipe(
      ofType(RegularAsyncMultiplayerActions.getAllAsyncGamesSuccess),
      concatLatestFrom(() => this.regularAsyncMultiplayerFacade.opponentUserMap$),
      concatMap(([, opponentUserMap]) => {
        const opponentUserIds = Array.from(opponentUserMap.entries())
          .filter(([id, opponent]) => opponent === null)
          .map(([id, opponent]) => id);

        return of(RegularAsyncMultiplayerActions.getAllUsersForIds({ userIds: opponentUserIds }));
      }),
    );
  });

  getAllUserIdsForIds = createEffect(() => {
    return this.actions.pipe(
      ofType(RegularAsyncMultiplayerActions.getAllUsersForIds),
      concatMap(({ userIds }) => {
        const userRequests$ = userIds.map((userId) =>
          this.userBackendService.getUserWithId$(userId).pipe(
            catchError((error) => {
              console.error('Error while fetching user with id: ', userId, error);
              return of(null);
            }),
          ),
        );

        if (userRequests$.length === 0) {
          return of(RegularAsyncMultiplayerActions.getAllUsersForIdsSuccess({ users: [] }));
        }

        return forkJoin(userRequests$).pipe(
          map((users) => {
            const usersFilteredForNull = users.filter((user) => user != null) as BasicUserView[];

            return RegularAsyncMultiplayerActions.getAllUsersForIdsSuccess({ users: usersFilteredForNull });
          }),
          catchError((error) => {
            return of(RegularAsyncMultiplayerActions.getAllUsersForIdsFailure({ error: error }));
          }),
        );
      }),
    );
  });

  setUserPlayerNumberInCurrentGame = createEffect(() => {
    return this.actions.pipe(
      ofType(
        RegularAsyncMultiplayerActions.getAsyncGameSuccess,
        RegularAsyncMultiplayerActions.createOrGetAsyncGameFromInvitationLinkSuccess,
        RegularAsyncMultiplayerActions.challengeUserSuccess,
        RegularAsyncMultiplayerActions.challengeUserAgainSuccess,
        RegularAsyncMultiplayerActions.acceptChallengeSuccess,
      ),
      concatLatestFrom(() => this.userFacade.user$),
      concatMap(([{ response }, user]) => {
        if (response.playerIds.get(PlayerNumber.PLAYER_1) === user?.id) {
          return of(
            RegularAsyncMultiplayerActions.setPlayerNumbers({
              userPlayerNumber: PlayerNumber.PLAYER_1,
              opponentPlayerNumber: PlayerNumber.PLAYER_2,
            }),
          );
        } else {
          return of(
            RegularAsyncMultiplayerActions.setPlayerNumbers({
              userPlayerNumber: PlayerNumber.PLAYER_2,
              opponentPlayerNumber: PlayerNumber.PLAYER_1,
            }),
          );
        }
      }),
    );
  });

  setCurrentOpponent = createEffect(() => {
    return this.actions.pipe(
      ofType(
        RegularAsyncMultiplayerActions.getAsyncGameSuccess,
        RegularAsyncMultiplayerActions.createOrGetAsyncGameFromInvitationLinkSuccess,
      ),
      concatLatestFrom(() => [this.userFacade.user$, this.regularAsyncMultiplayerFacade.currentOpponent$]),
      concatMap(([{ response }, user, opponent]) => {
        if (
          !opponent ||
          (opponent.id !== response.playerIds.get(PlayerNumber.PLAYER_1) &&
            opponent.id !== response.playerIds.get(PlayerNumber.PLAYER_2))
        ) {
          let opponentId = response.playerIds.get(PlayerNumber.PLAYER_1);
          if (response.playerIds.get(PlayerNumber.PLAYER_1) === user?.id) {
            opponentId = response.playerIds.get(PlayerNumber.PLAYER_2);
          }
          if (opponentId) {
            return this.userBackendService.getUserWithId$(opponentId).pipe(
              map((currentOpponent) =>
                RegularAsyncMultiplayerActions.setCurrentOpponent({
                  currentOpponent: convertUserToMultiplayerUser(currentOpponent),
                }),
              ),
              catchError((error) => {
                return of(RegularAsyncMultiplayerActions.setCurrentOpponentFailure({ error: error }));
              }),
            );
          } else {
            return of(RegularAsyncMultiplayerActions.setCurrentOpponent({ currentOpponent: null }));
          }
        } else return of(RegularAsyncMultiplayerActions.setCurrentOpponent({ currentOpponent: opponent }));
      }),
    );
  });

  setCurrentAsyncState = createEffect(() => {
    return this.actions.pipe(
      ofType(
        RegularAsyncMultiplayerActions.getAsyncGameSuccess,
        RegularAsyncMultiplayerActions.createOrGetAsyncGameFromInvitationLinkSuccess,
        RegularAsyncMultiplayerActions.answerQuestionSuccess,
        RegularAsyncMultiplayerActions.giveUpGameSuccess,
        RegularAsyncMultiplayerActions.challengeUserSuccess,
        RegularAsyncMultiplayerActions.challengeUserAgainSuccess,
        RegularAsyncMultiplayerActions.acceptChallengeSuccess,
      ),
      concatLatestFrom(() => [this.userFacade.user$]),
      concatMap(([{ response }, user]) => {
        let userPlayer = PlayerNumber.PLAYER_1;
        let opponentPlayer = PlayerNumber.PLAYER_2;
        let currentAsyncState = AsyncState.CHALLENGED;
        if (user?.id === response.playerIds.get(PlayerNumber.PLAYER_2)) {
          userPlayer = PlayerNumber.PLAYER_2;
          opponentPlayer = PlayerNumber.PLAYER_1;
        }
        if (response.gameWinner && response.gameEndReason === AsyncGameEndReason.PLAYER_DID_WIN) {
          if (response.gameWinner === userPlayer) {
            currentAsyncState = AsyncState.USER_WON;
          } else {
            currentAsyncState = AsyncState.USER_LOST;
          }
        } else {
          if (response.currentRound?.playerAtTurn === userPlayer) {
            if (response.gameEndReason === AsyncGameEndReason.ROUND_TIMEOUT_EXCEEDED) {
              currentAsyncState = AsyncState.TIME_IS_UP;
            }
            if (response.gameEndReason === AsyncGameEndReason.PLAYER_GAVE_UP) {
              currentAsyncState = AsyncState.USER_GAVE_UP;
            } else {
              currentAsyncState = AsyncState.USER_TURN;
            }
          } else {
            if (response.gameEndReason === AsyncGameEndReason.ROUND_TIMEOUT_EXCEEDED) {
              currentAsyncState = AsyncState.TIME_IS_UP;
            }
            if (response.gameEndReason === AsyncGameEndReason.PLAYER_GAVE_UP) {
              currentAsyncState = AsyncState.OPPONENT_GAVE_UP;
            } else {
              currentAsyncState = AsyncState.OPPONENT_TURN;
            }
          }
        }
        if (response.gameEnded && !response.gameWinner) {
          currentAsyncState = AsyncState.DRAW;
        }
        if (
          response.gameStatus === AsyncGameStateServer.CHALLENGE_REQUESTED &&
          user?.id !== response.challengerUserId
        ) {
          currentAsyncState = AsyncState.CHALLENGED;
        }
        if (response.gameEnded && response.gameEndReason === AsyncGameEndReason.ROUND_TIMEOUT_EXCEEDED) {
          currentAsyncState = AsyncState.TIME_IS_UP;
        }
        return of(RegularAsyncMultiplayerActions.setCurrentAsyncState({ currentAsyncState }));
      }),
    );
  });

  setPlayerGamePoints = createEffect(() => {
    return this.actions.pipe(
      ofType(
        RegularAsyncMultiplayerActions.getAsyncGameSuccess,
        RegularAsyncMultiplayerActions.createOrGetAsyncGameFromInvitationLinkSuccess,
        RegularAsyncMultiplayerActions.answerQuestionSuccess,
        RegularAsyncMultiplayerActions.giveUpGameSuccess,
        RegularAsyncMultiplayerActions.challengeUserSuccess,
        RegularAsyncMultiplayerActions.challengeUserAgainSuccess,
        RegularAsyncMultiplayerActions.acceptChallengeSuccess,
      ),
      concatLatestFrom(() => [this.userFacade.user$]),
      concatMap(([{ response }, user]) => {
        let userPlayer = PlayerNumber.PLAYER_1;
        let opponentPlayer = PlayerNumber.PLAYER_2;
        if (user?.id === response.playerIds.get(PlayerNumber.PLAYER_2)) {
          userPlayer = PlayerNumber.PLAYER_2;
          opponentPlayer = PlayerNumber.PLAYER_1;
        }
        const userGamePoints = response.gamePoints?.get(userPlayer) ?? null;
        const opponentGamePoints = response.gamePoints?.get(opponentPlayer) ?? null;
        return of(RegularAsyncMultiplayerActions.setPlayerGamePoints({ userGamePoints, opponentGamePoints }));
      }),
    );
  });

  setShownOpponentScore$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        RegularAsyncMultiplayerActions.getAsyncGameSuccess,
        RegularAsyncMultiplayerActions.createOrGetAsyncGameFromInvitationLinkSuccess,
        RegularAsyncMultiplayerActions.answerQuestionSuccess,
        RegularAsyncMultiplayerActions.challengeUserSuccess,
        RegularAsyncMultiplayerActions.challengeUserAgainSuccess,
        RegularAsyncMultiplayerActions.acceptChallengeSuccess,
      ),
      withLatestFrom(this.userFacade.user$),
      concatMap(([currentGame, user]) => {
        let opponentPlayerNumber = PlayerNumber.PLAYER_1;
        if (currentGame.response.playerIds.get(PlayerNumber.PLAYER_1) === user?.id) {
          opponentPlayerNumber = PlayerNumber.PLAYER_2;
        }
        if (opponentPlayerNumber && currentGame) {
          let shownOpponentScore: number | null = 0;
          currentGame.response.playedRounds.forEach((playedRound) => {
            playedRound?.playerAnswers?.get(opponentPlayerNumber)?.forEach((opponentAnswer) => {
              if (opponentAnswer.answerIsCorrect) {
                if (shownOpponentScore) {
                  shownOpponentScore++;
                } else {
                  shownOpponentScore = 1;
                }
              }
            });
          });
          currentGame.response.currentRound?.playerAnswers.get(opponentPlayerNumber)?.forEach((opponentAnswer) => {
            if (opponentAnswer.answerIsCorrect) {
              if (shownOpponentScore) {
                shownOpponentScore++;
              } else {
                shownOpponentScore = 1;
              }
            }
          });
          return of(RegularAsyncMultiplayerActions.setShownOpponentScore({ shownOpponentScore }));
        } else return of(RegularAsyncMultiplayerActions.setShownOpponentScoreFailure({ shownOpponentScore: null }));
      }),
    ),
  );

  onStartInterval = createEffect(() => {
    return this.actions.pipe(
      ofType(RegularAsyncMultiplayerActions.startInterval),
      map(({ game }) => RegularAsyncMultiplayerActions.startIntervalSuccess({ game })),
    );
  });

  onMultiplayerGameStart = createEffect(() => {
    return this.actions.pipe(
      ofType(RegularAsyncMultiplayerActions.getNextQuestionSuccess),
      map(({ response }) => RegularAsyncMultiplayerActions.startInterval({ game: response })),
    );
  });

  stopInterval = createEffect(() => {
    return this.actions.pipe(
      ofType(RegularAsyncMultiplayerActions.answerQuestion, RegularAsyncMultiplayerActions.closeCurrentGame),
      map(() => RegularAsyncMultiplayerActions.stopInterval()),
    );
  });

  onReconnectToOngoingGame = createEffect(() => {
    return this.actions.pipe(
      ofType(RegularAsyncMultiplayerActions.getAsyncGameSuccess),
      concatLatestFrom(() => this.regularAsyncMultiplayerFacade.currentGame$),
      filter(([{ response }, game]) => {
        const currentQuestionTimeoutTimestamp = game?.currentRound?.currentQuestionTimeoutTimestamp;
        const currentRound = game?.currentRound;
        const currentQuestionIndex = currentRound?.currentQuestionIndex;
        const playerAnswers = game?.currentRound?.playerAnswers?.get(game?.currentRound?.playerAtTurn) ?? [];

        return (
          currentQuestionTimeoutTimestamp != null &&
          !game?.gameEnded &&
          new Date(response.currentServerTime)?.getTime() < new Date(currentQuestionTimeoutTimestamp)?.getTime() &&
          currentQuestionIndex !== undefined &&
          currentQuestionIndex >= 0 &&
          playerAnswers[currentQuestionIndex] == null
        );
      }),
      map(([, game]) => RegularAsyncMultiplayerActions.startInterval({ game: game! })),
    );
  });
}
