import { FootballPlayerPosition, FootballPlayerPositionDetail, PreferredFoot } from '@kiq/shared/enums';
import { FootballPlayerTransfer } from './football-player-transfer';

export interface BoFootballPlayerDetailView {
  id: string;
  providerId: string;
  displayName: string;
  dateOfBirth?: string;
  height?: number;
  position?: FootballPlayerPosition;
  positionDetail?: FootballPlayerPositionDetail;
  nationality: string;
  nationalitySubdivision: string;
  preferredFoot?: PreferredFoot;
  outfitter?: string;
  maxMarketValue?: number;
  transfers: Array<FootballPlayerTransfer>;
}
