export interface Player {
  uid: string;
  league?: string;
  club: string;
  name: string;
  position: string;
  dateOfBirth: string;
  nationality: string;
  height: number;
  foot: string;
  jerseyNumber: number;
  teamMemberSince: string;
  marketValue: number;
  previousClub: string;
}
