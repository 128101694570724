import { SocialMediaLink } from '@kiq/shared/core';

export interface VipUserView {
  name: string;
  title: string;
  socialMediaLinks?: Array<SocialMediaLink>;
  nameTextColor: string;
  backgroundColor: string;
  previewImageUrl?: string;
  profileImageUrl?: string;
}
