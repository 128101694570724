import { ActionCreator } from '@ngrx/store';
import {
  catchError,
  concatMap,
  filter,
  interval,
  map,
  merge,
  Observable,
  of,
  shareReplay,
  takeUntil,
  timer,
} from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { HttpErrorResponse } from '@angular/common/http';

export interface SimpleRequestEffectParams<
  TSuccessResponse = unknown,
  TTriggerPayload = unknown,
  TSuccessPayload = unknown,
  TFailPayload = unknown,
  TServiceMethodArgs extends any[] = any[],
> {
  /** The action that triggers the effect (its payload is of type TTriggerPayload) */
  triggerAction: ActionCreator<string, (props: TTriggerPayload) => any>;
  /** The action to dispatch on success */
  successAction: ActionCreator<string, (props: TSuccessPayload) => any>;
  /** The action to dispatch on failure */
  failAction: ActionCreator<string, (props: { error?: TFailPayload }) => any>;
  /**
   * The service method which sends http request to the server.
   * It receives the args and returns an Observable.
   * IMPORTANT: Function needs to be an arrow function to keep the context of 'this'.
   */
  serviceMethod: (...args: TServiceMethodArgs) => Observable<TSuccessResponse>;
  /** A function that maps the API response to the payload required by successAction */
  mapSuccessPayload: (response: TSuccessResponse) => TSuccessPayload;
  /**
   * A function that maps the trigger action’s payload (e.g. { categoryId: '123' })
   * to additional properties that should be merged into the service request.
   * For a "get next question" effect this can simply return an empty object.
   */
  mapTriggerPayload: (request: TTriggerPayload) => TServiceMethodArgs;
  errorCallback?: (error?: any) => void;
  successCallback?: () => void;
}

export interface SimpleRequestEffectWithLoaderParams<
  TSuccessResponse = unknown,
  TTriggerPayload = unknown,
  TSuccessPayload = unknown,
  TFailPayload = unknown,
  TServiceMethodArgs extends any[] = any[],
> extends SimpleRequestEffectParams<
    TSuccessResponse,
    TTriggerPayload,
    TSuccessPayload,
    TFailPayload,
    TServiceMethodArgs
  > {
  /** An action (usually to show a loader) that is dispatched if the request is delayed */
  showLoaderAction: ActionCreator<string, () => any>;
}

export interface IntervalTimerEffectParams<TTriggerPayload, TStopPayload> {
  /** The action that triggers the effect */
  triggerAction: ActionCreator<string, (props: TTriggerPayload) => any>;
  /** The action which stops the interval */
  stopAction: ActionCreator<string, (props: TStopPayload) => any>;
  /** The action to dispatch every tick */
  tickAction: ActionCreator<string, () => any>;
  /**
   * The time between each tick in milliseconds.
   */
  tickTime: number;
  timerAlreadyRunning$: Observable<boolean>;
}

export interface TimeTickEffectParams<TTriggerPayload, TTimeUpPayload> {
  /** The action that triggers the effect */
  triggerAction: ActionCreator<string, (props: TTriggerPayload) => any>;
  /** The action which is dispatched when no time is left */
  timeUpAction: ActionCreator<string, (props: TTimeUpPayload) => any>;
  /** The observable which holds the current time left value */
  timeLeft$: Observable<number | null>;
  /** The observable which holds the value if base condition is fulfilled */
  condition$: Observable<boolean>;
  timeUpPayload: () => TTimeUpPayload;
}

export function createSimpleRequestEffect<
  TSuccessResponse,
  TTriggerPayload,
  TSuccessPayload,
  TFailPayload,
  TServiceMethodArgs extends any[],
>(
  actions$: Actions,
  params: SimpleRequestEffectParams<
    TSuccessResponse,
    TTriggerPayload,
    TSuccessPayload,
    TFailPayload,
    TServiceMethodArgs
  >,
) {
  return createEffect(() =>
    actions$.pipe(
      ofType(params.triggerAction),
      concatMap((triggerPayload) => {
        const serviceMethodArgument = params.mapTriggerPayload(triggerPayload);

        return params.serviceMethod(...serviceMethodArgument).pipe(
          map((response) => {
            if (params.successCallback) {
              params.successCallback();
            }

            return params.successAction(params.mapSuccessPayload(response));
          }),
          catchError((errorRes: HttpErrorResponse) => {
            if (params.errorCallback) {
              params.errorCallback();
            }

            return of(params.failAction({ error: errorRes.error }));
          }),
        );
      }),
    ),
  );
}

export function createSimpleRequestWithLoaderEffect<
  TSuccessResponse,
  TTriggerPayload,
  TSuccessPayload,
  TFailPayload,
  TServiceMethodArgs extends any[],
>(
  actions$: Actions,
  params: SimpleRequestEffectWithLoaderParams<
    TSuccessResponse,
    TTriggerPayload,
    TSuccessPayload,
    TFailPayload,
    TServiceMethodArgs
  >,
) {
  return createEffect(() =>
    actions$.pipe(
      ofType(params.triggerAction),
      concatMap((triggerPayload) => {
        const serviceMethodArgument = params.mapTriggerPayload(triggerPayload);

        const service$ = params.serviceMethod(...serviceMethodArgument).pipe(
          map((response) => {
            if (params?.successCallback) {
              params.successCallback();
            }

            return params.successAction(params.mapSuccessPayload(response));
          }),
          catchError((errorRes: HttpErrorResponse) => {
            if (params.errorCallback) {
              params.errorCallback();
            }
            return of(params.failAction({ error: errorRes.error }));
          }),
          shareReplay(1),
        );

        const loaderDelay = 500;
        const loader$ = timer(loaderDelay).pipe(
          map(() => params.showLoaderAction()),
          takeUntil(service$),
        );

        return merge(loader$, service$);
      }),
    ),
  );
}

export function createIntervalTimerEffect<TTriggerPayload, TStopPayload>(
  actions: Actions,
  params: IntervalTimerEffectParams<TTriggerPayload, TStopPayload>,
) {
  return createEffect(() =>
    actions.pipe(
      ofType(params.triggerAction),
      concatLatestFrom(() => params.timerAlreadyRunning$),
      filter(([, intervalRunning]) => !intervalRunning),
      concatMap(() =>
        interval(params.tickTime).pipe(
          // Stop the interval as soon as the stopPing action is dispatched
          takeUntil(actions.pipe(ofType(params.stopAction))),
          map(() => {
            return params.tickAction();
          }),
        ),
      ),
    ),
  );
}

export function createTimeTickEffect<TTriggerPayload, TTimeUpPayload>(
  actions: Actions,
  params: TimeTickEffectParams<TTriggerPayload, TTimeUpPayload>,
) {
  return createEffect(() =>
    actions.pipe(
      ofType(params.triggerAction),
      concatLatestFrom(() => [params.timeLeft$, params.condition$]),
      filter(([, timeLeft, condition]) => {
        if (!condition) {
          return false;
        }

        return (timeLeft ?? 0) <= 0;
      }),
      map(() => params.timeUpAction(params.timeUpPayload())),
    ),
  );
}
