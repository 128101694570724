import { GameEndReason, GameTypes } from '@kiq/shared/enums';
import { Observable } from 'rxjs';
import { GameMixedZoneState } from './game-mixed-zone-state';
import { GameFakeOrFactState } from './game-fake-or-fact-state';
import { GameTopscorerState } from './game-topscorer-state';
import { GameTopscorerQuestionOption } from './game-topscorer-question-option';

export interface GameWsBaseFacade {
  readonly type: GameTypes;

  game$: Observable<GameMixedZoneState | GameFakeOrFactState | GameTopscorerState | null>;
  getGameLoading$: Observable<boolean>;
  nextStepLoading$: Observable<boolean>;
  timeLeftTurn$: Observable<number | null>;
  intervalRunning$: Observable<boolean>;
  vm$: Observable<{
    getGameLoading: boolean;
    nextStepLoading: boolean;
    error: string | null;
    timeLeftTurn: number | null;
    timeLeftTurnInPercent: number | null;
    isNextQuestionLoading: boolean;
    gameEnded: boolean;
    userScore: number;
    gameEndReason: GameEndReason | null;
    lastGivenAnswer: string | boolean | GameTopscorerQuestionOption | null;
  }>;

  createGame(): void;
  getGame(id: string): void;
  solveQuestion(answer: string | boolean): void;
  resetGame(): void;
}
