import { FootballPlayer } from './football-player';
import { GameTopscorerQuestionOption } from './game-topscorer-question-option';

export interface GameQuizduelAnswersInternal {
  questionTimeoutReached: boolean;
  answerIsCorrect: boolean;
  ticTacToe?: FootballPlayer;
  topscorer?: GameTopscorerQuestionOption;
  transferHistory?: FootballPlayer;
}
