import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslationActions } from './translation.actions';
import { selectLanguageSettings } from './translation.reducer';
import { TranslationService } from '../service/translation.service';

@Injectable()
export class TranslationFacade {
  private readonly store = inject(Store);
  private readonly translationService = inject(TranslationService);
  selectLanguageSettings$ = this.store.select(selectLanguageSettings);

  setSelectedLanguage(selectedLanguage: string | null) {
    this.store.dispatch(TranslationActions.setLanguageSettingsStart({ languageSettings: selectedLanguage }));
  }

  getLastLanguageSettingsFromUser() {
    this.store.dispatch(TranslationActions.retrieveLanguageSettingsStart());
  }

  getTranslation(key: string) {
    return this.translationService.getTranslation(key);
  }

  getTranslation$(key: string) {
    return this.translationService.getTranslation$(key);
  }
}
