import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { VipAsyncMultiplayerService } from '../../service/vip-async-multiplayer.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { VipAsyncMultiplayerActions } from './vip-async-multiplayer.actions';
import {
  FootballPlayer,
  GameTopscorerQuestionOption,
  KikkzError,
  VipAsyncMultiplayerGame,
  VipUserQuizduelGameOverview,
  VipUserQuizduelGameUserView,
} from '@kiq/shared/interfaces';
import {
  AsyncGameEndReason,
  AsyncGameStateServer,
  AsyncState,
  PlayerNumber,
  VipUserAsyncMultiplayerGameInstanceActionType,
} from '@kiq/shared/enums';
import { BaseQuizduelAction } from '../../interfaces/base-quizduel-action';
import { VipAsyncMultiplayerFacade } from './vip-async-multiplayer.facade';
import { concatMap, map, of } from 'rxjs';
import {
  createIntervalTimerEffect,
  createSimpleRequestEffect,
  createSimpleRequestWithLoaderEffect,
  createTimeTickEffect,
} from '@kiq/shared/util/helper';
import { createSetCurrentCategoryEffect, createVipGameActionEffect } from '../../helper/shared-effects.helpers';

@Injectable()
export class VipAsyncMultiplayerEffects {
  private readonly store = inject(Store);
  private readonly actions = inject(Actions);
  private readonly vipAsyncMultiplayerService = inject(VipAsyncMultiplayerService);
  private readonly vipAsyncMultiplayerFacade = inject(VipAsyncMultiplayerFacade);

  private readonly TICK_TIME = 1000;

  getVipGamesOverview$ = createSimpleRequestEffect(this.actions, {
    triggerAction: VipAsyncMultiplayerActions.getVipGamesOverview,
    serviceMethod: () => this.vipAsyncMultiplayerService.getAllVipGames$(),
    successAction: VipAsyncMultiplayerActions.getVipGamesOverviewSuccess,
    failAction: VipAsyncMultiplayerActions.getVipGamesOverviewFailure,
    mapSuccessPayload: (response) => ({ response }),
    mapTriggerPayload: () => [] as [],
  });

  getVipUserGame$ = createSimpleRequestWithLoaderEffect(this.actions, {
    triggerAction: VipAsyncMultiplayerActions.getVipUserGame,
    successAction: VipAsyncMultiplayerActions.getVipUserGameSuccess,
    failAction: VipAsyncMultiplayerActions.getVipUserGameFailure,
    showLoaderAction: VipAsyncMultiplayerActions.showGameLoader,
    serviceMethod: (vipUserQuizduelGameId) => this.vipAsyncMultiplayerService.getVipUserGame$(vipUserQuizduelGameId),
    mapSuccessPayload: (response) => ({ response }),
    mapTriggerPayload: (payload) => [payload.vipUserQuizduelGameId] as [string],
  });

  onGetVipUserGameSuccess$ = createEffect(() => {
    return this.actions.pipe(
      ofType(VipAsyncMultiplayerActions.getVipUserGameSuccess),
      map(({ response }) =>
        VipAsyncMultiplayerActions.getVipUserGameInstanceForVipUserGame({ vipUserQuizduelGameId: response.id }),
      ),
    );
  });

  getOpenOrFirstInstance$ = createSimpleRequestEffect(this.actions, {
    triggerAction: VipAsyncMultiplayerActions.getVipUserGameInstanceForVipUserGame,
    successAction: VipAsyncMultiplayerActions.getVipUserGameInstanceForVipUserGameSuccess,
    failAction: VipAsyncMultiplayerActions.getVipUserGameInstanceForVipUserGameFailure,
    serviceMethod: (vipUserQuizduelGameId) =>
      this.vipAsyncMultiplayerService.getVipUserGameOpenOrFirstInstance$(vipUserQuizduelGameId),
    mapSuccessPayload: (response) => ({ response }),
    mapTriggerPayload: (payload) => [payload.vipUserQuizduelGameId] as [string],
  });

  createNewInstance$ = createSimpleRequestWithLoaderEffect(this.actions, {
    triggerAction: VipAsyncMultiplayerActions.createVipUserGameInstance,
    successAction: VipAsyncMultiplayerActions.createVipUserGameInstanceSuccess,
    failAction: VipAsyncMultiplayerActions.createVipUserGameInstanceFailure,
    showLoaderAction: VipAsyncMultiplayerActions.showInstanceLoader,
    serviceMethod: (vipUserQuizduelGameId) =>
      this.vipAsyncMultiplayerService.createVipUserGameInstance$(vipUserQuizduelGameId),
    mapSuccessPayload: (response) => ({ response }),
    mapTriggerPayload: (payload) => [payload.vipUserQuizduelGameId] as [string],
  });

  getNextQuestion = createVipGameActionEffect<
    VipAsyncMultiplayerGame,
    object,
    {
      response: VipAsyncMultiplayerGame;
    },
    [
      vipUserQuizduelGameId: string,
      vipUserQuizduelGameInstanceId: string,
      action: BaseQuizduelAction<VipAsyncMultiplayerGame, VipUserAsyncMultiplayerGameInstanceActionType>,
    ]
  >(this.actions, {
    triggerAction: VipAsyncMultiplayerActions.getNextQuestion,
    successAction: VipAsyncMultiplayerActions.getNextQuestionSuccess,
    failAction: VipAsyncMultiplayerActions.getNextQuestionFailure,
    showLoaderAction: VipAsyncMultiplayerActions.showStepLoader,
    serviceMethod: (
      vipUserQuizduelGameId: string,
      vipUserQuizduelGameInstanceId: string,
      action: BaseQuizduelAction<VipAsyncMultiplayerGame, VipUserAsyncMultiplayerGameInstanceActionType>,
    ) =>
      this.vipAsyncMultiplayerService.postVipUserGameInstanceAction$(
        vipUserQuizduelGameId,
        vipUserQuizduelGameInstanceId,
        action,
      ),
    currentGame$: () => this.vipAsyncMultiplayerFacade.currentGame$,
    payload: {
      type: VipUserAsyncMultiplayerGameInstanceActionType.NEXT_QUESTION,
    },
    mapSuccessPayload: (response) => ({ response }),
    mapServiceMethodArgs: (triggerPayload, currentGame, staticPayload) => [
      currentGame.gameId,
      currentGame.instanceId,
      { ...staticPayload, currentState: currentGame },
    ],
  });

  answerQuestion = createVipGameActionEffect<
    VipAsyncMultiplayerGame,
    { footballPlayer?: FootballPlayer; topscorerAnswer?: GameTopscorerQuestionOption },
    {
      response: VipAsyncMultiplayerGame;
    },
    [
      vipUserQuizduelGameId: string,
      vipUserQuizduelGameInstanceId: string,
      action: BaseQuizduelAction<VipAsyncMultiplayerGame, VipUserAsyncMultiplayerGameInstanceActionType>,
    ]
  >(this.actions, {
    triggerAction: VipAsyncMultiplayerActions.answerQuestion,
    successAction: VipAsyncMultiplayerActions.answerQuestionSuccess,
    failAction: VipAsyncMultiplayerActions.answerQuestionFailure,
    showLoaderAction: VipAsyncMultiplayerActions.showStepLoader,
    serviceMethod: (
      vipUserQuizduelGameId: string,
      vipUserQuizduelGameInstanceId: string,
      action: BaseQuizduelAction<VipAsyncMultiplayerGame, VipUserAsyncMultiplayerGameInstanceActionType>,
    ) =>
      this.vipAsyncMultiplayerService.postVipUserGameInstanceAction$(
        vipUserQuizduelGameId,
        vipUserQuizduelGameInstanceId,
        action,
      ),
    currentGame$: () => this.vipAsyncMultiplayerFacade.currentGame$,
    payload: {
      type: VipUserAsyncMultiplayerGameInstanceActionType.ANSWER_QUESTION,
    },
    mapSuccessPayload: (response) => ({ response }),
    mapServiceMethodArgs: (triggerPayload, currentGame, staticPayload) => [
      currentGame.gameId,
      currentGame.instanceId,
      {
        ...staticPayload,
        currentState: currentGame,
        answerOptionTicTacToe: triggerPayload?.footballPlayer,
        answerOptionTransferHistory: triggerPayload?.footballPlayer,
        answerOptionTopscorer: triggerPayload?.topscorerAnswer,
      },
    ],
  });

  selectCategory = createVipGameActionEffect<
    VipAsyncMultiplayerGame,
    { categoryId: string },
    {
      response: VipAsyncMultiplayerGame;
    },
    [
      vipUserQuizduelGameId: string,
      vipUserQuizduelGameInstanceId: string,
      action: BaseQuizduelAction<VipAsyncMultiplayerGame, VipUserAsyncMultiplayerGameInstanceActionType>,
    ]
  >(this.actions, {
    triggerAction: VipAsyncMultiplayerActions.selectCategory,
    successAction: VipAsyncMultiplayerActions.selectCategorySuccess,
    failAction: VipAsyncMultiplayerActions.selectCategoryFailure,
    showLoaderAction: VipAsyncMultiplayerActions.showStepLoader,
    serviceMethod: (
      vipUserQuizduelGameId: string,
      vipUserQuizduelGameInstanceId: string,
      action: BaseQuizduelAction<VipAsyncMultiplayerGame, VipUserAsyncMultiplayerGameInstanceActionType>,
    ) =>
      this.vipAsyncMultiplayerService.postVipUserGameInstanceAction$(
        vipUserQuizduelGameId,
        vipUserQuizduelGameInstanceId,
        action,
      ),
    currentGame$: () => this.vipAsyncMultiplayerFacade.currentGame$,
    payload: {
      type: VipUserAsyncMultiplayerGameInstanceActionType.SELECT_CATEGORY,
    },
    mapSuccessPayload: (response) => ({ response }),
    mapServiceMethodArgs: (triggerPayload, currentGame, staticPayload) => [
      currentGame.gameId,
      currentGame.instanceId,
      { ...staticPayload, currentState: currentGame, categoryId: triggerPayload.categoryId },
    ],
  });

  startInterval = createIntervalTimerEffect(this.actions, {
    triggerAction: VipAsyncMultiplayerActions.startInterval,
    tickAction: VipAsyncMultiplayerActions.timeTick,
    stopAction: VipAsyncMultiplayerActions.stopInterval,
    tickTime: this.TICK_TIME,
    timerAlreadyRunning$: this.vipAsyncMultiplayerFacade.intervalRunning$,
  });

  timeTick = createTimeTickEffect<void, { footballPlayer: undefined; topscorerAnswer: undefined }>(this.actions, {
    triggerAction: VipAsyncMultiplayerActions.timeTick,
    timeUpAction: VipAsyncMultiplayerActions.answerQuestion,
    timeLeft$: this.vipAsyncMultiplayerFacade.timeLeftQuestion$,
    condition$: this.vipAsyncMultiplayerFacade.currentGame$.pipe(
      map((game) => game?.currentRound?.currentQuestionTimeoutTimestamp !== null && !game?.gameEnded),
    ),
    timeUpPayload: () => {
      return { footballPlayer: undefined, topscorerAnswer: undefined };
    },
  });

  setCurrentCategory = createSetCurrentCategoryEffect<VipAsyncMultiplayerGame>(this.actions, {
    triggerActions: [
      VipAsyncMultiplayerActions.createVipUserGameInstanceSuccess,
      VipAsyncMultiplayerActions.getVipUserGameInstanceForVipUserGameSuccess,
      VipAsyncMultiplayerActions.selectCategorySuccess,
    ],
    returnAction: VipAsyncMultiplayerActions.setCurrentCategory,
  });

  onMultiplayerGameStart = createEffect(() => {
    return this.actions.pipe(
      ofType(VipAsyncMultiplayerActions.getNextQuestionSuccess),
      map(({ response }) => VipAsyncMultiplayerActions.startInterval({ game: response })),
    );
  });

  onVipProDuelGameEnd$ = createEffect(() => {
    return this.actions.pipe(
      ofType(VipAsyncMultiplayerActions.answerQuestion, VipAsyncMultiplayerActions.closeCurrentGame),
      map(() => VipAsyncMultiplayerActions.stopInterval()),
    );
  });

  setShownOpponentScore$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        VipAsyncMultiplayerActions.getVipUserGameInstanceForVipUserGameSuccess,
        VipAsyncMultiplayerActions.answerQuestionSuccess,
        VipAsyncMultiplayerActions.createVipUserGameInstanceSuccess,
      ),
      concatMap(({ response }) => {
        const opponentPlayerNumber = PlayerNumber.PLAYER_2;

        if (opponentPlayerNumber && response) {
          let shownOpponentScore: number | null = 0;
          response.playedRounds.forEach((playedRound) => {
            playedRound?.playerAnswers?.get(opponentPlayerNumber)?.forEach((opponentAnswer) => {
              if (opponentAnswer.answerIsCorrect) {
                if (shownOpponentScore) {
                  shownOpponentScore++;
                } else {
                  shownOpponentScore = 1;
                }
              }
            });
          });
          response.currentRound?.playerAnswers.get(opponentPlayerNumber)?.forEach((opponentAnswer) => {
            if (opponentAnswer.answerIsCorrect) {
              if (shownOpponentScore) {
                shownOpponentScore++;
              } else {
                shownOpponentScore = 1;
              }
            }
          });
          return of(VipAsyncMultiplayerActions.setShownOpponentScore({ shownOpponentScore }));
        } else return of(VipAsyncMultiplayerActions.setShownOpponentScoreFailure({ shownOpponentScore: null }));
      }),
    ),
  );

  setAsyncState$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        VipAsyncMultiplayerActions.getVipUserGameInstanceForVipUserGameSuccess,
        VipAsyncMultiplayerActions.createVipUserGameInstanceSuccess,
        VipAsyncMultiplayerActions.answerQuestionSuccess,
        VipAsyncMultiplayerActions.selectCategorySuccess,
        VipAsyncMultiplayerActions.getNextQuestionSuccess,
      ),
      map(({ response }) => {
        const userPlayer = PlayerNumber.PLAYER_1;
        let currentAsyncState = AsyncState.CHALLENGED;

        if (response.gameWinner && response.gameEndReason === AsyncGameEndReason.PLAYER_DID_WIN) {
          if (response.gameWinner === userPlayer) {
            currentAsyncState = AsyncState.USER_WON;
          } else {
            currentAsyncState = AsyncState.USER_LOST;
          }
        } else {
          if (response.currentRound?.playerAtTurn === userPlayer) {
            if (response.gameEndReason === AsyncGameEndReason.ROUND_TIMEOUT_EXCEEDED) {
              currentAsyncState = AsyncState.TIME_IS_UP;
            } else {
              currentAsyncState = AsyncState.USER_TURN;
            }
          } else {
            if (response.gameEndReason === AsyncGameEndReason.ROUND_TIMEOUT_EXCEEDED) {
              currentAsyncState = AsyncState.TIME_IS_UP;
            } else {
              currentAsyncState = AsyncState.OPPONENT_TURN;
            }
          }
        }
        if (response.gameEnded && !response.gameWinner) {
          currentAsyncState = AsyncState.DRAW;
        }
        if (response.gameEnded && response.gameEndReason === AsyncGameEndReason.ROUND_TIMEOUT_EXCEEDED) {
          currentAsyncState = AsyncState.TIME_IS_UP;
        }

        return VipAsyncMultiplayerActions.setCurrentAsyncState({ currentAsyncState });
      }),
    ),
  );
}
