import { VipUserQuizduelGameState } from './vip-user-quizduel-game-state';
import { AsyncMultiplayerActionType } from '@kiq/shared/enums';
import { FootballPlayer } from './football-player';
import { GameTopscorerQuestionOption } from './game-topscorer-question-option';

export interface VipUserQuizduelGameAction {
  currentState: VipUserQuizduelGameState;
  type: AsyncMultiplayerActionType;
  categoryId?: string;
  answerOptionTicTacToe?: FootballPlayer;
  answerOptionTopscorer?: GameTopscorerQuestionOption;
  answerOptionTransferHistory?: FootballPlayer;
}
