import { Component, inject, OnInit } from '@angular/core';
import { TranslationFacade } from 'shared/data-access/translation-settings';
import { ConfigFacade } from '@kiq/client/data-access/config';

@Component({
    selector: 'kiq-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {
  private translationFacade = inject(TranslationFacade);
  private configFacade = inject(ConfigFacade);

  ngOnInit() {
    if (this.configFacade.nativeAppType() === null) {
      this.translationFacade.getLastLanguageSettingsFromUser();
    }
    this.emitAppLoadedEvent();
  }

  private emitAppLoadedEvent(): void {
    const event = new CustomEvent('app-loaded', { detail: { loaded: true } });
    window.dispatchEvent(event);
  }
}
