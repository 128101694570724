import { GameTopscorerQuestionOption } from './game-topscorer-question-option';
import { AsyncMultiplayerActionType } from '@kiq/shared/enums';
import { FootballPlayer } from './football-player';
import { RegularAsyncMultiplayerGame } from './regular-async-multiplayer-game';

export interface AsyncAction {
  currentState: RegularAsyncMultiplayerGame;
  type: AsyncMultiplayerActionType;
  categoryId?: string;
  answerOptionTicTacToe?: FootballPlayer;
  answerOptionTopscorer?: GameTopscorerQuestionOption;
  answerOptionTransferHistory?: FootballPlayer;
  roundTimeoutExceeded?: boolean;
}
