export enum GameTypes {
  whoIsIt = 'who-is-it',
  ticTacToe = 'tic-tac-toe',
  topscorer = 'topscorer',
  mixedZone = 'mixed-zone',
  fakeOrFact = 'fake-or-fact',
  asyncMultiplayer = 'zweikampf',
  asyncMultiplayerVip = 'duelVsPro',
  asyncMultiplayerVipSingle = 'duelProVsCommunity',
}
