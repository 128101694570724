export enum EtrackerPageTitle {
  GAMES = 'GAMES',
  RANKING = 'Ranking',
  PROFILE = 'Profile',
  TOPSCORER = 'Topscorer',
  BOX_TO_BOX = 'Box to Box',
  MORE = 'More',
  CHEFSCOUT = 'Chefscout',
  DUEL = 'Duel',
  LOGIN = 'Login',
  REGISTER = 'Register',
  PASSWORD_RESET = 'Password Reset',
  PROFILE_SETTINGS = 'Profile Settings',
  GAME_SETTINGS = 'Game Settings',
  STANDARD_EDITION = 'Standard Edition',
  TRANSFER_EDITION = 'Transfer Edition',
  EURO_24 = 'Euro2024',
  EURO_24_TOP_TEAMS = 'Euro2024 Top Teams',
  TOP_NATIONS_EDITION = 'Top Nations Edition',
  ALL_NATIONS_EDITION = 'All Nations Edition',
  IMPRINT = 'Imprint',
  PRIVACY_POLICY = 'Privacy Policy',
  TERMS_OF_USE = 'Terms of use',
  MAINTENANCE = 'Maintenance',
  EMAIL_VERIFICATION = 'Email Verification',
  MIXED_ZONE = 'Mixed Zone',
  FAKE_OR_FACT = 'Fake or Fact',
  DUEL_VS_PRO = 'Duel vs Pro',
}
