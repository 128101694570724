import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectAllAsyncMultiplayerGames,
  selectCurrentGame,
  selectCurrentOpponent,
  selectGameLoading,
  selectIntervalRunning,
  selectOpponentUserMap,
  selectRegularAsmVm,
  selectStepLoading,
  selectTimeLeftQuestion,
  selectTimeLeftQuestionInPercent,
} from './regular-async-multiplayer.reducer';
import { Observable } from 'rxjs';
import {
  FootballPlayer,
  GameTopscorerQuestionOption,
  RegularAsyncMultiplayerGame,
  RegularAsyncMultiplayerViewModel,
} from '@kiq/shared/interfaces';
import { RegularAsyncMultiplayerActions } from './regular-async-multiplayer.actions';
import { AsyncMultiplayerFacade } from '../../interfaces/async-multiplayer-facade';
import { AsyncMultiplayerType } from '@kiq/shared/enums';

@Injectable({ providedIn: 'root' })
export class RegularAsyncMultiplayerFacade implements AsyncMultiplayerFacade<RegularAsyncMultiplayerGame> {
  private readonly store = inject(Store);

  readonly vm$: Observable<RegularAsyncMultiplayerViewModel> = this.store.select(selectRegularAsmVm);
  readonly currentGame$: Observable<RegularAsyncMultiplayerGame | null> = this.store.select(selectCurrentGame);
  readonly intervalRunning$: Observable<boolean> = this.store.select(selectIntervalRunning);
  readonly opponentUserMap$ = this.store.select(selectOpponentUserMap);
  readonly currentOpponent$ = this.store.select(selectCurrentOpponent);
  readonly timeLeftQuestion$ = this.store.select(selectTimeLeftQuestion);
  readonly allAsyncMultiplayerGames$ = this.store.select(selectAllAsyncMultiplayerGames);
  readonly gameLoading$ = this.store.select(selectGameLoading);
  readonly stepLoading$ = this.store.select(selectStepLoading);
  readonly timeLeftQuestionInPercent$ = this.store.select(selectTimeLeftQuestionInPercent);
  readonly asyncMultiplayerType = AsyncMultiplayerType.regular;

  readonly deleteEndedGameSuccess = RegularAsyncMultiplayerActions.deleteEndedGameSuccess;

  getAllAsyncMultiplayerGames() {
    this.store.dispatch(RegularAsyncMultiplayerActions.getAllAsyncGames());
  }

  getAsyncMultiplayerGame(gameId: string) {
    this.store.dispatch(RegularAsyncMultiplayerActions.getAsyncGame({ gameId }));
  }

  getAsyncMultiplayerGameFromInvitationLink(invitationLinkId: string) {
    this.store.dispatch(RegularAsyncMultiplayerActions.createOrGetAsyncGameFromInvitationLink({ invitationLinkId }));
  }

  getOpponentFromInvitationLink(invitationLinkId: string) {
    this.store.dispatch(RegularAsyncMultiplayerActions.getOpponentFromInvitationLink({ invitationLinkId }));
  }

  giveUpGame() {
    this.store.dispatch(RegularAsyncMultiplayerActions.giveUpGame());
  }

  answerQuestion(footballPlayer?: FootballPlayer, topscorerAnswer?: GameTopscorerQuestionOption) {
    this.store.dispatch(RegularAsyncMultiplayerActions.answerQuestion({ footballPlayer, topscorerAnswer }));
  }

  selectCategory(categoryId: string) {
    this.store.dispatch(RegularAsyncMultiplayerActions.selectCategory({ categoryId }));
  }

  getNextQuestion() {
    this.store.dispatch(RegularAsyncMultiplayerActions.getNextQuestion());
  }

  closeCurrentGame() {
    this.store.dispatch(RegularAsyncMultiplayerActions.closeCurrentGame());
  }

  deleteEndedGame() {
    this.store.dispatch(RegularAsyncMultiplayerActions.deleteEndedGame());
  }

  challengeUserAgain(previousGameId: string) {
    this.store.dispatch(RegularAsyncMultiplayerActions.challengeUserAgain({ previousGameId }));
  }

  challengeUser(opponentId: string) {
    this.store.dispatch(RegularAsyncMultiplayerActions.challengeUser({ opponentId }));
  }

  acceptChallenge() {
    this.store.dispatch(RegularAsyncMultiplayerActions.acceptChallenge());
  }

  acknowledgedKikkzError() {
    this.store.dispatch(RegularAsyncMultiplayerActions.resetError());
  }

  doNotShowGameAlreadyRunningDialog() {
    this.store.dispatch(RegularAsyncMultiplayerActions.doNotShowGameAlreadyRunningDialog());
  }

  doNotShowChallengeAlreadySendDialog() {
    this.store.dispatch(RegularAsyncMultiplayerActions.doNotShowChallengeAlreadySendDialog());
  }

  declineChallenge() {
    this.store.dispatch(RegularAsyncMultiplayerActions.declineChallenge());
  }
}
