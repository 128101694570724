import { BaseAsyncMultiplayerGame } from './base-async-multiplayer-game';
import { VipUserQuizDuelGameStatistics } from './vip-user-quiz-duel-game-statistics';
import { Media } from './media';

export interface VipAsyncMultiplayerGame extends BaseAsyncMultiplayerGame {
  instanceId: string;
  gameStatistics: VipUserQuizDuelGameStatistics;
  vipUserReactionOnGameEnd: Media | null;

  // Not applicable for VIP games:
  challengerUserId?: never;
  playerIds?: never;
  gameStatus?: never;
  gamePoints?: never;
  isRunningOrSelfChallengeRequestedGame?: never;
}
