import { GameTopscorerConfig } from './game-topscorer-config';
import { GameTopscorerQuestion } from './game-topscorer-question';
import { GameEndReason } from '@kiq/shared/enums';

export interface GameTopscorerState {
  gameId: string;
  config: GameTopscorerConfig;
  currentQuestion: GameTopscorerQuestion;
  maxTurnDurationSeconds?: number;
  turnTimeoutTimestamp?: Date;
  gameEnded: boolean;
  gameEndReason?: GameEndReason;
  turnEnded?: boolean;
  userScore?: number;
  currentServerTime: Date;
}
