import { Media } from './media';
import { VipUserQuizduelGameData } from './vip-user-quizduel-game-data';

export interface VipUserQuizduelGameBackofficeView {
  id?: string;
  gameLinkId?: string;
  gameData: VipUserQuizduelGameData;
  communityPreviewStart?: Date;
  communityPreviewWithAllDetails: boolean;
  communityGameStart?: Date;
  showAsHeadlinerUntil?: Date;
  reactionOnWinMedia?: Media;
  reactionOnLossMedia?: Media;
  reactionOnDrawMedia?: Media;
  deletionAllowed: boolean;
}
