export enum PageTitleIdentifier {
  GAMES = 'games',
  RANKING = 'ranking',
  PROFILE = 'profile',
  TOPSCORER = 'topscorer',
  BOX_TO_BOX = 'boxToBox',
  MORE = 'more',
  CHEFSCOUT = 'chefscout',
  DUEL = 'duel',
  LOGIN = 'login',
  REGISTER = 'register',
  PASSWORD_RESET = 'passwordReset',
  PROFILE_SETTINGS = 'profileSettings',
  GAME_SETTINGS = 'gameSettings',
  STANDARD_EDITION = 'standardEdition',
  TRANSFER_EDITION = 'transferEdition',
  EURO_24 = 'euro2024',
  EURO_24_TOP_TEAMS = 'euro2024TopTeams',
  TOP_NATIONS_EDITION = 'topNationsEdition',
  ALL_NATIONS_EDITION = 'allNationsEdition',
  IMPRINT = 'imprint',
  PRIVACY_POLICY = 'privacyPolicy',
  TERMS_OF_USE = 'termsOfUse',
  MAINTENANCE = 'maintenance',
  EMAIL_VERIFICATION = 'emailVerification',
  MIXED_ZONE = 'mixedZone',
  FAKE_OR_FACT = 'fakeOrFact',
  DUEL_VS_PRO = 'duelVsPro',
}
