import { CommonAsyncGameState } from '../interfaces/common-async-game-state';
import { RegularAsyncMultiplayerGame, VipAsyncMultiplayerGame, VipUserQuizduelGameState } from '@kiq/shared/interfaces';
import { immerOn } from 'ngrx-immer/store';
import { getTimeLeft, getTimeLeftInPercent } from 'client/util/time-calculations';
import { CommonAsyncActions } from '../interfaces/common-async-actions';

export function provideCommonAsyncActionHandlers<
  TState extends CommonAsyncGameState<VipAsyncMultiplayerGame | RegularAsyncMultiplayerGame | VipUserQuizduelGameState>,
  TGame,
  TError,
  TCategory,
>(actions: CommonAsyncActions<TGame, TError, TCategory>) {
  const actionHandlers = [
    immerOn<TState, [typeof actions.showGameLoader]>(actions.showGameLoader, (state, action) => {
      state.gameLoading = true;
    }),
    immerOn<TState, [typeof actions.showStepLoader]>(actions.showStepLoader, (state, action) => {
      state.stepLoading = true;
    }),
    immerOn<TState, [typeof actions.refreshGameStateOnFocus]>(actions.refreshGameStateOnFocus, (state) => {
      if (state.currentGame) {
        state.timeLeftQuestion = getTimeLeft(
          state.currentGame.currentRound?.currentQuestionTimeoutTimestamp,
          state?.currentGame?.currentServerTime,
        );
        state.timeLeftQuestionInPercent = getTimeLeftInPercent(
          state.timeLeftQuestion,
          state.currentGame.currentRound?.currentQuestion?.maxAnswerDurationSeconds,
        );
      }
    }),
    immerOn<TState, [typeof actions.startIntervalSuccess]>(actions.startIntervalSuccess, (state) => {
      state.intervalRunning = true;
    }),
    immerOn<TState, [typeof actions.stopInterval]>(actions.stopInterval, (state) => {
      state.intervalRunning = false;
    }),

    immerOn<TState, [typeof actions.timeTick]>(actions.timeTick, (state) => {
      if (state?.currentGame?.currentRound?.currentQuestionTimeoutTimestamp && state.timeLeftQuestion) {
        state.timeLeftQuestion = --state.timeLeftQuestion;
        state.timeLeftQuestionInPercent = getTimeLeftInPercent(
          state.timeLeftQuestion,
          state.currentGame?.currentRound?.currentQuestion?.maxAnswerDurationSeconds,
        );
      }
    }),
    immerOn<TState, [typeof actions.getNextQuestion]>(actions.getNextQuestion, (state) => {
      state.isNextQuestionLoading = true;
      state.error = null;
    }),
    immerOn<TState, [typeof actions.getNextQuestionSuccess]>(actions.getNextQuestionSuccess, (state, { response }) => {
      state.stepLoading = false;
      state.isNextQuestionLoading = false;

      state.timeLeftQuestion = getTimeLeft(
        response?.currentRound?.currentQuestionTimeoutTimestamp,
        response?.currentGame?.currentServerTime,
      );
      state.timeLeftQuestionInPercent = getTimeLeftInPercent(
        state.timeLeftQuestion,
        response?.currentRound?.currentQuestion?.maxAnswerDurationSeconds,
      );

      state.currentGame = response;
    }),
    immerOn<TState, [typeof actions.getNextQuestionFailure]>(actions.getNextQuestionFailure, (state, { error }) => {
      state.stepLoading = false;
      state.error = error ?? null;
    }),
    immerOn<TState, [typeof actions.answerQuestion]>(actions.answerQuestion, (state) => {
      state.error = null;
    }),
    immerOn<TState, [typeof actions.answerQuestionSuccess]>(actions.answerQuestionSuccess, (state, { response }) => {
      state.stepLoading = false;
      state.currentGame = response;
    }),
    immerOn<TState, [typeof actions.answerQuestionFailure]>(actions.answerQuestionFailure, (state, { error }) => {
      state.stepLoading = false;
      state.error = error ?? null;
    }),
    immerOn<TState, [typeof actions.selectCategory]>(actions.selectCategory, (state) => {
      state.error = null;
    }),
    immerOn<TState, [typeof actions.selectCategorySuccess]>(actions.selectCategorySuccess, (state, { response }) => {
      state.stepLoading = false;
      state.currentGame = response;
    }),
    immerOn<TState, [typeof actions.selectCategoryFailure]>(actions.selectCategoryFailure, (state, { error }) => {
      state.stepLoading = false;
      state.error = error ?? null;
    }),
    immerOn<TState, [typeof actions.setCurrentCategory]>(actions.setCurrentCategory, (state, { currentCategory }) => {
      state.currentCategory = currentCategory;
    }),
  ];

  if (actions.setShownOpponentScore) {
    actionHandlers.push(
      immerOn<TState, [typeof actions.setShownOpponentScore]>(
        actions.setShownOpponentScore,
        (state, { shownOpponentScore }) => {
          state.shownOpponentScore = shownOpponentScore;
        },
      ),
    );
  }

  return actionHandlers;
}
