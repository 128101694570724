import { VipUserView } from './vip-user-view';

export interface VipUserQuizduelGameUserView {
  id: string;
  communityGameStart: Date;
  isPreview: boolean;
  isHeadliner: boolean;
  vipUser: VipUserView;
  vipUserWinRatio?: number;
}
