import { AsyncState } from '@kiq/shared/enums';
import { KikkzError } from './kikkz-error';
import { VipUserQuizduelGameState } from './vip-user-quizduel-game-state';
import { AsyncGameRoundCategory } from './async-game-round-category';

export interface VipSingleAsyncMultiplayerViewModel<TGame extends VipUserQuizduelGameState> {
  error: KikkzError | null;
  gameLoading: boolean;
  stepLoading: boolean;
  currentGame: TGame | null;
  timeLeftQuestion: number | null;
  intervalRunning: boolean;
  timeLeftQuestionInPercent: number | null;
  isNextQuestionLoading: boolean;
  currentCategory: AsyncGameRoundCategory | null;
  currentAsyncState: AsyncState | null;
}
