import {
  AsyncGameRoundCategory,
  BaseAsyncMultiplayerGame,
  RegularAsyncMultiplayerGame,
  VipAsyncMultiplayerGame,
  VipUserQuizduelGameState,
} from '@kiq/shared/interfaces';
import { ActionCreator } from '@ngrx/store';
import {
  catchError,
  concatMap,
  filter,
  interval,
  map,
  merge,
  Observable,
  of,
  shareReplay,
  takeUntil,
  timer,
} from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { BaseQuizduelAction } from '../interfaces/base-quizduel-action';
import { AsyncMultiplayerActionType, VipUserAsyncMultiplayerGameInstanceActionType } from '@kiq/shared/enums';

/**
 * This interface is used by the single factory for creating effects that call the API.
 * It is generic over:
 * - TGame: the game state type (or null)
 * - TActionType: your action type enum (for example AsyncMultiplayerActionType)
 * - TSuccessPayload: the payload expected by your success action
 * - TTriggerPayload: the payload coming from the trigger action
 */
export interface AsyncGameActionEffectParams<TTriggerPayload = unknown, TSuccessPayload = unknown> {
  /** The action that triggers the effect (its payload is of type TTriggerPayload) */
  triggerAction: ActionCreator<string, (props: TTriggerPayload) => any>;
  /** The action to dispatch on success */
  successAction: ActionCreator<string, (props: TSuccessPayload) => any>;
  /** The action to dispatch on failure */
  failAction: ActionCreator<string, (props: { error?: any }) => any>;
  /** An action (usually to show a loader) that is dispatched if the request is delayed */
  showLoaderAction: ActionCreator<string, () => any>;
  /**
   * The service method which sends an action to the server.
   * It receives the gameId and a request action (of type BaseQuizduelAction) and returns an Observable.
   * IMPORTANT: Function needs to be an arrow function to keep the context of 'this'.
   */
  serviceMethod: (
    gameId: string,
    action: BaseQuizduelAction<RegularAsyncMultiplayerGame, AsyncMultiplayerActionType>,
  ) => Observable<RegularAsyncMultiplayerGame>;
  /** A function returning the current game as an Observable */
  currentGame$: () => Observable<RegularAsyncMultiplayerGame | null>;
  /**
   * The static part of the payload for the service request.
   * We omit 'currentState' (which we add later) from this payload.
   */
  payload: Omit<BaseQuizduelAction<RegularAsyncMultiplayerGame, AsyncMultiplayerActionType>, 'currentState'>;
  /** A function that maps the API response to the payload required by successAction */
  mapSuccessPayload: (response: RegularAsyncMultiplayerGame) => TSuccessPayload;
  /**
   * A function that maps the trigger action’s payload (e.g. { categoryId: '123' })
   * to additional properties that should be merged into the service request.
   * For a "get next question" effect this can simply return an empty object.
   */
  mapTriggerPayload: (
    triggerPayload: TTriggerPayload,
  ) => Partial<BaseQuizduelAction<RegularAsyncMultiplayerGame, AsyncMultiplayerActionType>>;
}

/**
 * Interface for VIP game action effect parameters.
 * This factory is specialized for VIP service methods that require two IDs plus an action.
 */
export interface VipGameActionEffectParams<TGame, TTriggerPayload, TSuccessPayload, TServiceMethodArgs extends any[]> {
  triggerAction: ActionCreator<string, (props: TTriggerPayload) => any>;
  successAction: ActionCreator<string, (props: TSuccessPayload) => any>;
  failAction: ActionCreator<string, (props: { error?: any }) => any>;
  showLoaderAction: ActionCreator<string, () => any>;
  /**
   * VIP service method that expects:
   * - vipUserQuizduelGameId: string
   * - vipUserQuizduelGameInstanceId: string
   * - action: BaseQuizduelAction<TGame, TActionType>
   */
  serviceMethod: (...args: TServiceMethodArgs) => Observable<TGame>;
  currentGame$: () => Observable<TGame | null>;
  /**
   * The static payload for the service request (excluding currentState, which is added later).
   */
  payload: Omit<BaseQuizduelAction<TGame, VipUserAsyncMultiplayerGameInstanceActionType>, 'currentState'>;
  mapSuccessPayload: (response: TGame) => TSuccessPayload;
  /**
   * A function that maps the trigger action’s payload (e.g. { categoryId: '123' })
   * to additional properties that should be merged into the service request.
   * For a "get next question" effect this can simply return an empty object.
   */
  mapServiceMethodArgs: (
    triggerPayload: TTriggerPayload,
    currentGame: TGame,
    payload: Omit<BaseQuizduelAction<TGame, VipUserAsyncMultiplayerGameInstanceActionType>, 'currentState'>,
  ) => TServiceMethodArgs;
}

export interface SetCurrentCategoryEffectParams<
  TTriggerPayload extends BaseAsyncMultiplayerGame | VipUserQuizduelGameState,
> {
  triggerActions: ActionCreator<string, (props: { response: TTriggerPayload }) => any>[];
  returnAction: ActionCreator<string, (props: { currentCategory: AsyncGameRoundCategory | null }) => any>;
}

/**
 * A single factory function that creates an effect for an async game action.
 * This factory is flexible enough to be used for both "get next question" and "select category"
 * (and potentially other actions) by using a mapping function to add extra properties.
 */
export function createAsyncGameActionEffect<TTriggerPayload, TSuccessPayload>(
  actions$: Actions,
  params: AsyncGameActionEffectParams<TTriggerPayload, TSuccessPayload>,
) {
  return createEffect(() =>
    actions$.pipe(
      ofType(params.triggerAction),
      concatLatestFrom(() => params.currentGame$()),
      concatMap(([triggerPayload, currentGame]) => {
        if (currentGame) {
          // Use the mapping function to obtain extra properties from the trigger payload.
          const additionalProps = params.mapTriggerPayload(triggerPayload);
          // Build the final action to send by merging:
          // - The static payload (params.payload)
          // - The current state from the store
          // - The extra properties from the trigger payload
          const actionToSend: BaseQuizduelAction<RegularAsyncMultiplayerGame, AsyncMultiplayerActionType> = {
            ...params.payload,
            currentState: currentGame,
            ...additionalProps,
          };

          const service$ = params.serviceMethod(currentGame.gameId, actionToSend).pipe(
            map((response) => params.successAction(params.mapSuccessPayload(response))),
            catchError((error) => of(params.failAction({ error }))),
            shareReplay(1),
          );

          const loaderDelay = 500;
          const loader$ = timer(loaderDelay).pipe(
            map(() => params.showLoaderAction()),
            takeUntil(service$),
          );

          return merge(loader$, service$);
        } else {
          return of(params.failAction({ error: 'No current game' }));
        }
      }),
    ),
  );
}

/**
 * Factory function for creating a VIP game action effect.
 */
export function createVipGameActionEffect<TGame, TTriggerPayload, TSuccessPayload, TServiceMethodArgs extends any[]>(
  actions$: Actions,
  params: VipGameActionEffectParams<TGame, TTriggerPayload, TSuccessPayload, TServiceMethodArgs>,
) {
  return createEffect(() =>
    actions$.pipe(
      ofType(params.triggerAction),
      concatLatestFrom(() => params.currentGame$()),
      concatMap(([triggerPayload, currentGame]) => {
        if (currentGame) {
          // Use the mapping function to obtain extra properties from the trigger payload.
          const serviceMethodArgs = params.mapServiceMethodArgs(triggerPayload, currentGame, params.payload);

          const service$ = params.serviceMethod(...serviceMethodArgs).pipe(
            map((response) => params.successAction(params.mapSuccessPayload(response))),
            catchError((error) => of(params.failAction({ error }))),
            shareReplay(1),
          );

          const loaderDelay = 500;
          const loader$ = timer(loaderDelay).pipe(
            map(() => params.showLoaderAction()),
            takeUntil(service$),
          );

          return merge(loader$, service$);
        } else {
          return of(params.failAction({ error: 'No current game' }));
        }
      }),
    ),
  );
}

export function createSetCurrentCategoryEffect<
  TTriggerPayload extends BaseAsyncMultiplayerGame | VipUserQuizduelGameState,
>(actions: Actions, params: SetCurrentCategoryEffectParams<TTriggerPayload>) {
  return createEffect(() =>
    actions.pipe(
      ofType(...params.triggerActions),
      map(({ response }) => {
        const currentCategory = response.categories.find(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          (category) => category.id === response?.currentRound?.categoryId,
        );

        return params.returnAction({ currentCategory: currentCategory ?? null });
      }),
    ),
  );
}
