import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BACKEND_TOKEN } from '@kiq/client/util/config';
import { Observable } from 'rxjs';
import {
  UserGameStatisticOpponentDetail,
  UserGameStatisticOpponentOverview,
  UserGameStatisticOverview,
  UserVipGameStatisticsOverview,
} from '@kiq/shared/interfaces';

@Injectable()
export class OnlineStatisticsService {
  private readonly http = inject(HttpClient);

  private readonly BACKEND_BASE_URL = inject(BACKEND_TOKEN);
  private readonly BACKEND_URL = `${this.BACKEND_BASE_URL}/api/v1/user`;
  private readonly BACKEND_URL_STATISTIC = '/gameStatistics';
  private readonly BACKEND_URL_VIP_STATISTICS = '/vipGameStatistics';
  private readonly BACKEND_URL_STATISTIC_OPPONENT = '/opponent';

  // own user
  //list of opponents
  getAllUserGameStatisticOpponentOverviews(): Observable<Array<UserGameStatisticOpponentOverview>> {
    const url = this.BACKEND_URL + this.BACKEND_URL_STATISTIC;
    return this.http.get<Array<UserGameStatisticOpponentOverview>>(url);
  }

  //overview
  getOwnUserStatisticOverview(userId: string): Observable<UserGameStatisticOverview> {
    const url = this.BACKEND_URL + '/' + userId + this.BACKEND_URL_STATISTIC;
    return this.http.get<UserGameStatisticOverview>(url);
  }

  //for other users
  //other users overview
  getUserGameStatisticOpponentOverview(otherUsersId: string): Observable<UserGameStatisticOverview> {
    const url = this.BACKEND_URL + '/' + otherUsersId + this.BACKEND_URL_STATISTIC;
    return this.http.get<UserGameStatisticOpponentOverview>(url);
  }

  //details of opponent against own user
  getAllUserGameStatisticOpponentDetails(otherUsersId: string): Observable<Array<UserGameStatisticOpponentDetail>> {
    const url =
      this.BACKEND_URL + this.BACKEND_URL_STATISTIC + this.BACKEND_URL_STATISTIC_OPPONENT + '/' + otherUsersId;
    return this.http.get<Array<UserGameStatisticOpponentDetail>>(url);
  }

  getVIPGameStatisticsOverviewForUser$(userId: string) {
    const url = `${this.BACKEND_URL}/${userId}${this.BACKEND_URL_VIP_STATISTICS}`;
    return this.http.get<UserVipGameStatisticsOverview>(url);
  }
}
