import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUiButtonModule } from '@kiq/shared/ui/button';
import { SNACKBAR_DATA, SNACKBAR_REF, SnackbarData, SnackbarRef } from 'shared/util/snackbar';
import { SharedUiIconModule } from 'shared/ui/icon';

@Component({
    selector: 'shared-snackbar',
    imports: [CommonModule, SharedUiButtonModule, SharedUiIconModule],
    templateUrl: './snackbar.component.html',
    styleUrl: './snackbar.component.scss'
})
export class SnackbarComponent implements OnInit, OnDestroy {
  private timeoutId: number | undefined;

  constructor(
    @Inject(SNACKBAR_DATA) readonly data: SnackbarData,
    @Inject(SNACKBAR_REF) readonly snackbarRef: SnackbarRef,
  ) {}

  ngOnInit(): void {
    this.timeoutId = window.setTimeout(() => {
      this.close();
    }, 4000);
  }

  ngOnDestroy(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  close() {
    this.snackbarRef.close();
  }
}
