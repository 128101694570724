import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { VipSingleAsyncMultiplayerActions } from './vip-single-async-multiplayer.actions';
import {
  selectCurrentGame,
  selectGameLoading,
  selectIntervalRunning,
  selectProUser,
  selectStepLoading,
  selectTimeLeftQuestion,
  selectVipSingleAsyncMultiplayerViewModel,
} from './vip-single-async-multiplayer.reducer';
import {
  AsyncMultiplayerUser,
  FootballPlayer,
  GameTopscorerQuestionOption,
  VipSingleAsyncMultiplayerViewModel,
  VipUserQuizduelGameState,
} from '@kiq/shared/interfaces';
import { AsyncMultiplayerSingleFacade } from '../../interfaces/async-multiplayer-single-facade';
import { Observable } from 'rxjs';

export class VipSingleAsyncMultiplayerFacade implements AsyncMultiplayerSingleFacade<VipUserQuizduelGameState> {
  private readonly store = inject(Store);

  readonly currentGame$ = this.store.select(selectCurrentGame);
  readonly intervalRunning$ = this.store.select(selectIntervalRunning);
  readonly timeLeftQuestion$ = this.store.select(selectTimeLeftQuestion);
  readonly gameLoading$: Observable<boolean> = this.store.select(selectGameLoading);
  readonly stepLoading$: Observable<boolean> = this.store.select(selectStepLoading);
  readonly vm$: Observable<VipSingleAsyncMultiplayerViewModel<VipUserQuizduelGameState>> = this.store.select(
    selectVipSingleAsyncMultiplayerViewModel,
  );
  readonly activeSinglePlayer$: Observable<AsyncMultiplayerUser | null> = this.store.select(selectProUser);

  getGame(gameLinkId: string) {
    this.store.dispatch(VipSingleAsyncMultiplayerActions.getVipSingleGame({ gameLinkId }));
  }

  startVipProDuelGame(gameLinkId: string) {
    // this.store.dispatch(VipSingleAsyncMultiplayerActions.startVipProGame({ gameLinkId }));
  }

  getNextQuestion() {
    this.store.dispatch(VipSingleAsyncMultiplayerActions.getNextQuestion());
  }

  answerQuestion(footballPlayer?: FootballPlayer, topscorerAnswer?: GameTopscorerQuestionOption) {
    this.store.dispatch(VipSingleAsyncMultiplayerActions.answerQuestion({ footballPlayer, topscorerAnswer }));
  }

  selectCategory(categoryId: string) {
    this.store.dispatch(VipSingleAsyncMultiplayerActions.selectCategory({ categoryId }));
  }

  closeCurrentGame() {
    this.store.dispatch(VipSingleAsyncMultiplayerActions.closeCurrentGame());
  }

  acknowledgedKikkzError() {
    this.store.dispatch(VipSingleAsyncMultiplayerActions.resetError());
  }
}
