export enum GameType {
  TTT = 'TTT',
  WII = 'WII',
  WS = 'WS',
  MZ = 'MZ',
  FOF = 'FOF',
  ASM = 'ASM',
  ASM_VS_PRO = 'AsmVsPro',
  ASM_PRO_VS_C = 'AsmProVsC',
}
