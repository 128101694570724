import { Authentication } from './authentication.interface';
import { inject, Injectable } from '@angular/core';
import { ConfigFacade } from '@kiq/client/data-access/config';
import { InGameModalService, ModalService } from '@kiq/shared/util/modal';
import { from, of, skip, switchMap, take } from 'rxjs';
import { FirebaseService, UserFacade } from '@kiq/client/data-access/user';
import { Actions, ofType } from '@ngrx/effects';
import { ComponentType } from '@angular/cdk/overlay';
import { InGameModalConfig, ModalConfig, RedirectUrlWithParams } from '@kiq/shared/interfaces';

@Injectable()
export class AuthenticationService implements Authentication {
  private readonly configFacade = inject(ConfigFacade);
  private readonly modalService = inject(ModalService);
  private readonly userFacade = inject(UserFacade);
  private readonly firebaseService = inject(FirebaseService);
  private readonly actions = inject(Actions);

  readonly authChanges$ = this.firebaseService.authChanges$;
  readonly token$ = this.firebaseService.firebaseUser$.pipe(
    // tap((user) => console.log('user in firebase$ stream', user)),
    switchMap((user) => (user ? from(user.getIdToken()) : of(null))),
  );

  constructor() {
    this.actions.pipe(ofType(this.userFacade.getBackendUserFail)).subscribe(() => this.logout());
  }

  login(
    component: ComponentType<unknown>,
    inGameModalService?: InGameModalService,
    redirectUrlWithParams?: RedirectUrlWithParams,
  ): void {
    this.openNestedOrInGameModal(component, inGameModalService, redirectUrlWithParams);
  }

  register(
    component: ComponentType<unknown>,
    inGameModalService?: InGameModalService,
    redirectUrlWithParams?: RedirectUrlWithParams,
  ): void {
    this.openNestedOrInGameModal(component, inGameModalService, redirectUrlWithParams);
  }

  private openNestedOrInGameModal(
    component: ComponentType<unknown>,
    inGameModalService?: InGameModalService,
    redirectUrlWithParams?: RedirectUrlWithParams,
  ) {
    const inGameModalConfig: InGameModalConfig = {
      component: component,
      isFullModalHeight: true,
      inputs: { redirectUrlWithParams },
    };

    if (inGameModalService && this.modalService.hasNestedModalOpen) {
      if (inGameModalService?.hasModalOpen) {
        inGameModalService.updateInGameModal(inGameModalConfig);
      } else {
        inGameModalService.openInGameModal(inGameModalConfig);
      }
    } else {
      const modalConfig: ModalConfig = {
        component: component,
        useFullscreenModal: this.configFacade.useFullscreenModal(),
        inputs: { redirectUrlWithParams },
      };

      this.modalService.openNestedModal(modalConfig);
    }
  }

  logout(): void {
    this.userFacade.logout();
  }

  deleteAccount(): void {
    this.userFacade.deleteAccount();
    this.userFacade.user$.pipe(skip(1), take(1)).subscribe((user) => {
      if (!user) {
        this.logout();
      }
    });
  }
}
