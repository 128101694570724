import { Route } from '@angular/router';
import { clientRoutes } from '@kiq/client/util/routing';
import { PageTitleIdentifier } from '@kiq/shared/enums';

export const clientFeatureShellRoutes: Route[] = [
  {
    path: ``,
    loadChildren: () =>
      import('@kiq/client/feature/landing-overview').then((m) => m.ClientFeatureLandingOverviewModule),
    data: { title: PageTitleIdentifier.GAMES },
  },

  {
    path: clientRoutes.AUTH.BASE,
    loadChildren: () => import('@kiq/client/feature/auth').then((m) => m.ClientFeatureAuthModule),
    data: { title: 'Authentifizierung' },
  },
  {
    path: `${clientRoutes.HEADER.BASE}`,
    loadChildren: () => import('@kiq/client/feature/imprint').then((m) => m.ClientFeatureImprintModule),
    data: { title: PageTitleIdentifier.IMPRINT },
  },
];
