import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { SnackbarService, SnackbarType } from 'shared/util/snackbar';
import { catchError, concatMap, filter, map, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { TrophyActions } from './trophy.actions';
import { UserFacade } from '@kiq/client/data-access/user';
import { TrophyService } from '../service/trophy.service';
import { translate, TranslocoService } from '@jsverse/transloco';
import { TrophyFacade } from '../+state/trophy.facade';
import { TrophyActionContext } from '@kiq/shared/enums';

@Injectable()
export class TrophyEffects {
  private readonly actions = inject(Actions);
  private readonly snackbarService = inject(SnackbarService);
  private readonly trophyService = inject(TrophyService);
  private readonly userFacade = inject(UserFacade);
  translocoService = inject(TranslocoService);
  private readonly trophyFacade = inject(TrophyFacade);

  getTrophiesList$ = createEffect(() => {
    return this.actions.pipe(
      ofType(TrophyActions.getTrophiesStart),
      concatMap(({ userId, trophyActionContext }) => {
        if (!userId) {
          this.snackbarService.show({
            message: translate('trophiesDataAccess.noTrophiesFound'),
            type: SnackbarType.ERROR,
          });
          return of(TrophyActions.getTrophiesFail());
        }
        return this.trophyService.getTrophies$(userId).pipe(
          map((trophies) => TrophyActions.getTrophiesSuccess({ trophies, trophyActionContext })),
          catchError((errorRes: HttpErrorResponse) => {
            this.snackbarService.show({
              message: translate('trophiesDataAccess.errorWhileGettingTrophies'),
              type: SnackbarType.ERROR,
            });
            return of(TrophyActions.getTrophiesFail());
          }),
        );
      }),
    );
  });

  getTrophyCountForUser$ = createEffect(() => {
    return this.actions.pipe(
      ofType(TrophyActions.getTrophyCountForUser, TrophyActions.getTrophiesSuccess),
      concatLatestFrom(() => this.userFacade.user$),
      concatMap(([trophies, user]) => {
        if (!user) {
          return of(TrophyActions.getTrophyCountForUserFail());
        }

        return this.trophyService.getTrophies$(user.id).pipe(
          map((trophies) => TrophyActions.getTrophyCountForUserSuccess({ trophies })),
          catchError((errorRes: HttpErrorResponse) => {
            this.snackbarService.show({
              message: translate('trophiesDataAccess.errorWhileGettingCount'),
              type: SnackbarType.ERROR,
            });
            return of(TrophyActions.getTrophyCountForUserFail());
          }),
        );
      }),
    );
  });

  setInitialTrophyCountToLocalstorage = createEffect(() => {
    return this.actions.pipe(
      ofType(TrophyActions.getTrophiesSuccess),
      filter(({ trophyActionContext }) => trophyActionContext === TrophyActionContext.TROPHY_COLLECTION),
      map((event) => TrophyActions.setTrophyCountNotification()),
    );
  });

  retrieveLastTrophyCountOnGetCurrentTrophyCount = createEffect(() => {
    return this.actions.pipe(
      ofType(TrophyActions.getTrophyCountForUserSuccess),
      map((event) => TrophyActions.retrieveLastTrophyCount()),
    );
  });

  setTrophyCountNotification$ = createEffect(() => {
    return this.actions.pipe(
      ofType(TrophyActions.setTrophyCountNotification),
      concatLatestFrom(() => this.trophyFacade.trophyCounter$),
      concatMap(([, currentTrophyCounter]) => {
        this.trophyService.setLastTrophyCountToLocalStorage(currentTrophyCounter);
        return of(TrophyActions.setTrophyCountNotificationSuccess({ trophyCounterNotification: currentTrophyCounter }));
      }),
    );
  });

  retrieveLastTrophyCount$ = createEffect(() => {
    return this.actions.pipe(
      ofType(TrophyActions.retrieveLastTrophyCount),
      concatMap(() => {
        return this.trophyService.retrieveLastTrophyCountFromLocalStorage$().pipe(
          map((lastTrophyCount) =>
            TrophyActions.retrieveLastTrophyCountSuccess({ trophyCounterNotification: lastTrophyCount }),
          ),
          catchError((errorRes: HttpErrorResponse) => {
            this.snackbarService.show({
              message: 'Error while getting the last user trophies count.',
              type: SnackbarType.ERROR,
            });
            return of(TrophyActions.retrieveLastTrophyCountFail());
          }),
        );
      }),
    );
  });
}
