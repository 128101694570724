import { SocialMediaLink } from '@kiq/shared/core';
import { VipUserQuizduelGameBackofficeView } from './vip-user-quizduel-game-backoffice-view';

export interface VipUserBackofficeView {
  name: string;
  title: string;
  socialMediaLinks: Array<SocialMediaLink>;
  nameTextColor: string;
  backgroundColor: string;
  quizduelGames: Array<VipUserQuizduelGameBackofficeView>;
  deletionAllowed: boolean;
  id?: string;
  previewImageUrl?: string;
  profileImageUrl?: string;
}
