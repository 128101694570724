import { NotificationActionType, NotificationType } from '@kiq/shared/enums';

export interface PushNotificationPayload {
  actionType: NotificationActionType;
  notificationType: NotificationType;
  gameId: string;

  // For for quiz duel vip games
  userInstanceId?: string;
}
