import { FootballLeague, GameDifficultyLevel } from '@kiq/shared/enums';

export interface GamePlayerGuessingConfig {
  leagues: FootballLeague[];
  difficultyLevel: GameDifficultyLevel;
  gameStartDelaySeconds: number;
  euro2024Mode: boolean;
  maxGameDurationInSeconds?: number;
  maxTurnDurationInSeconds?: number;
}
