import { BasicUserView, UserOwnerView } from '@kiq/shared/classes';
import { AsyncMultiplayerUser, VipUserQuizduelGameUserView } from '@kiq/shared/interfaces';

export const serializePayload = (value: any): any => {
  if (value instanceof Map) {
    return Object.fromEntries(value);
  } else if (Array.isArray(value)) {
    return value.map(serializePayload);
  } else if (value !== null && typeof value === 'object') {
    return Object.keys(value).reduce((acc, key) => {
      acc[key] = serializePayload(value[key]);
      return acc;
    }, {} as any);
  }

  // If it's a primitive value, return it as is
  return value;
};

export function convertUserToMultiplayerUser(
  user: UserOwnerView | BasicUserView | VipUserQuizduelGameUserView,
): AsyncMultiplayerUser {
  if ((user as VipUserQuizduelGameUserView).vipUser) {
    return {
      username: (user as VipUserQuizduelGameUserView).vipUser.name,
      imageUrl: (user as VipUserQuizduelGameUserView).vipUser?.profileImageUrl ?? null,
      id: (user as VipUserQuizduelGameUserView).id,
    };
  } else {
    return {
      username: (user as UserOwnerView | BasicUserView).username,
      nickname: (user as UserOwnerView | BasicUserView)?.nickname,
      imageUrl: (user as UserOwnerView | BasicUserView).imageUrl ?? null,
      id: (user as UserOwnerView | BasicUserView).id,
    };
  }
}

export function isValidUUID(uuid: string): boolean {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
}
