import { GameEndReason } from '@kiq/shared/enums';
import { GameTopscorerQuestionOption } from './game-topscorer-question-option';

export interface GameWsBaseState {
  nextStepLoading: boolean;
  getGameLoading: boolean;
  error: string | null;
  intervalRunning: boolean;
  timeLeftTurn: number | null;
  timeLeftTurnInPercent: number | null;
  isNextQuestionLoading: boolean;
  gameEnded: boolean;
  userScore: number;
  gameEndReason: GameEndReason | null;
  lastGivenAnswer: string | boolean | GameTopscorerQuestionOption | null;
  gameTimeoutTimestamp: Date | null;
  turnTimeoutTimestamp: Date | null;
  maxGameDurationSeconds: number | null;
  maxTurnDurationSeconds: number | null;
  gameStartDelaySeconds: number | null;
}
