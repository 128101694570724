import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  inject,
  Injector,
  Input,
  OnChanges,
  RendererFactory2,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { slideUpChild } from '@kiq/shared/ui/animations';
import { DynamicChildLoaderDirective } from '../../directives/load-child.directive';
import { InGameModalConfig } from '@kiq/shared/interfaces';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import { InGameModalService } from '@kiq/shared/util/modal';

@Component({
    selector: 'in-game-modal-wrapper',
    imports: [CommonModule, DynamicChildLoaderDirective, CdkPortalOutlet],
    templateUrl: './in-game-modal-wrapper.component.html',
    styleUrl: './in-game-modal-wrapper.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [slideUpChild]
})
export class InGameModalWrapperComponent implements OnChanges {
  @HostBinding('@slideUpChild') tooltipAnimation = true;

  @HostBinding('class') get getClasses() {
    if (this.isFullModalHeight()) {
      return 'absolute inset-0 z-30 block';
    } else {
      return 'absolute bottom-0 left-0 right-0 z-30 block';
    }
  }

  private readonly injector = inject(Injector);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly rendererFactory = inject(RendererFactory2);

  private isFullModalHeight = signal(false);

  @Input() config: InGameModalConfig | undefined;

  @ViewChild(DynamicChildLoaderDirective, { static: true }) dynamicChildLoader!: DynamicChildLoaderDirective;
  @ViewChild('componentPortalOutlet', { static: true, read: CdkPortalOutlet }) componentPortalOutlet!: CdkPortalOutlet;
  @ViewChild('container', { static: true }) backdrop!: ElementRef<unknown>;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['config']?.currentValue) {
      this.createComponent();

      this.isFullModalHeight.set(this.config?.isFullModalHeight ?? false);
    }
  }

  private createComponent() {
    if (!this.config?.component) return;

    this.dynamicChildLoader?.viewContainerRef.clear();

    const injector = Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: InGameModalService,
          useFactory: () => new InGameModalService(this.rendererFactory, this.componentPortalOutlet, this.backdrop),
        },
      ],
    });
    const componentRef = this.dynamicChildLoader.viewContainerRef.createComponent(this.config.component, {
      injector,
    });

    // Pass inputs to the component. Ths is useful if the component requires inputs. You can pass them in the modal config.
    // Inputs are not required for the modal to work. You can also pass data via the data injection token.
    if (this.config?.inputs) {
      Object.keys(this.config.inputs).forEach((key) => {
        componentRef.setInput(key, this.config!.inputs![key]);
      });
    }

    this.cdr.detectChanges();
  }
}
