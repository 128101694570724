import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { VipSingleAsyncMultiplayerEffects } from './+state/vip-single-async-multiplayer.effects';
import { VipAsyncMultiplayerService } from '../service/vip-async-multiplayer.service';
import { VipSingleAsyncMultiplayerFacade } from './+state/vip-single-async-multiplayer.facade';
import { vipSingleAsyncMultiplayerFeature } from './+state/vip-single-async-multiplayer.reducer';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(vipSingleAsyncMultiplayerFeature),
    EffectsModule.forFeature([VipSingleAsyncMultiplayerEffects]),
  ],
  providers: [VipSingleAsyncMultiplayerFacade, VipAsyncMultiplayerService],
})
export class ClientDataAccessVipSingleAsyncMultiplayerDataModule {}
