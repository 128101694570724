import { FootballLeague, PlayerMode } from '@kiq/shared/enums';
import { GameTttWinMode } from '../../../../enums/src/lib/enum/game-ttt-win-mode';
import { GameMode } from '../../../../enums/src/lib/enum/game-mode';

export interface GameTttConfig {
  gameMode: GameMode;
  playerMode: PlayerMode;
  gameStartDelaySeconds: number;
  league?: FootballLeague;
  multiplayerWinMode?: GameTttWinMode;
  allowPlayerHints?: boolean;
  maxGameDurationSeconds?: number;
  maxTurnDurationSeconds?: number;
  stealsPerPlayer?: number;
  allowPlayerSteals?: boolean;
}
