import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { concatMap, filter, map, of } from 'rxjs';
import { SnackbarService, SnackbarType } from 'shared/util/snackbar';
import { VipSingleAsyncMultiplayerActions } from './vip-single-async-multiplayer.actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AsyncState, VipUserAsyncMultiplayerGameInstanceActionType } from '@kiq/shared/enums';
import { VipSingleAsyncMultiplayerFacade } from './vip-single-async-multiplayer.facade';
import { UserFacade } from '@kiq/client/data-access/user';
import { VipAsyncMultiplayerService } from '../../service/vip-async-multiplayer.service';
import {
  createIntervalTimerEffect,
  createSimpleRequestWithLoaderEffect,
  createTimeTickEffect,
} from '@kiq/shared/util/helper';
import { createSetCurrentCategoryEffect, createVipGameActionEffect } from '../../helper/shared-effects.helpers';
import { BaseQuizduelAction } from '../../interfaces/base-quizduel-action';
import {
  FootballPlayer,
  GameTopscorerQuestionOption,
  KikkzError,
  VipUserQuizduelGameState,
} from '@kiq/shared/interfaces';

@Injectable()
export class VipSingleAsyncMultiplayerEffects {
  private actions = inject(Actions);
  private snackbar = inject(SnackbarService);
  private vipAsyncMultiplayerService = inject(VipAsyncMultiplayerService);
  private duelVsProFacade = inject(VipSingleAsyncMultiplayerFacade);
  private userFacade = inject(UserFacade);
  private router = inject(Router);
  public store = inject(Store);

  private readonly TICK_TIME = 1000;

  getVipUserGame$ = createSimpleRequestWithLoaderEffect(this.actions, {
    triggerAction: VipSingleAsyncMultiplayerActions.getVipSingleGame,
    successAction: VipSingleAsyncMultiplayerActions.getVipSingleGameSuccess,
    failAction: VipSingleAsyncMultiplayerActions.getVipSingleGameFailure,
    showLoaderAction: VipSingleAsyncMultiplayerActions.showGameLoader,
    serviceMethod: (gameLinkId) => this.vipAsyncMultiplayerService.getProGameVipView$(gameLinkId),
    mapSuccessPayload: (response) => ({ response }),
    mapTriggerPayload: (payload) => [payload.gameLinkId] as [string],
  });

  selectVipCategory$ = createVipGameActionEffect<
    VipUserQuizduelGameState,
    { categoryId: string },
    { response: VipUserQuizduelGameState },
    [string, BaseQuizduelAction<VipUserQuizduelGameState, VipUserAsyncMultiplayerGameInstanceActionType>]
  >(this.actions, {
    triggerAction: VipSingleAsyncMultiplayerActions.selectCategory,
    successAction: VipSingleAsyncMultiplayerActions.selectCategorySuccess,
    failAction: VipSingleAsyncMultiplayerActions.selectCategoryFailure,
    showLoaderAction: VipSingleAsyncMultiplayerActions.showStepLoader,
    currentGame$: () => this.duelVsProFacade.currentGame$,
    payload: { type: VipUserAsyncMultiplayerGameInstanceActionType.SELECT_CATEGORY },
    serviceMethod: (
      gameLinkId: string,
      action: BaseQuizduelAction<VipUserQuizduelGameState, VipUserAsyncMultiplayerGameInstanceActionType>,
    ) => this.vipAsyncMultiplayerService.sendProGameActionVip$(gameLinkId, action),
    mapSuccessPayload: (response) => ({ response }),
    mapServiceMethodArgs: (triggerPayload, currentGame, staticPayload) => [
      currentGame.gameLinkId,
      { ...staticPayload, currentState: currentGame, categoryId: triggerPayload.categoryId },
    ],
  });

  getNextVipQuestion$ = createVipGameActionEffect<
    VipUserQuizduelGameState,
    object,
    { response: VipUserQuizduelGameState },
    [string, BaseQuizduelAction<VipUserQuizduelGameState, VipUserAsyncMultiplayerGameInstanceActionType>]
  >(this.actions, {
    triggerAction: VipSingleAsyncMultiplayerActions.getNextQuestion,
    successAction: VipSingleAsyncMultiplayerActions.getNextQuestionSuccess,
    failAction: VipSingleAsyncMultiplayerActions.getNextQuestionFailure,
    showLoaderAction: VipSingleAsyncMultiplayerActions.showStepLoader,
    currentGame$: () => this.duelVsProFacade.currentGame$,
    payload: { type: VipUserAsyncMultiplayerGameInstanceActionType.NEXT_QUESTION },
    serviceMethod: (
      gameLinkId: string,
      action: BaseQuizduelAction<VipUserQuizduelGameState, VipUserAsyncMultiplayerGameInstanceActionType>,
    ) => this.vipAsyncMultiplayerService.sendProGameActionVip$(gameLinkId, action),
    mapSuccessPayload: (response) => ({ response }),
    mapServiceMethodArgs: (triggerPayload, currentGame, staticPayload) => [
      currentGame.gameLinkId,
      { ...staticPayload, currentState: currentGame },
    ],
  });

  answerVipQuestion$ = createVipGameActionEffect<
    VipUserQuizduelGameState,
    { footballPlayer?: FootballPlayer; topscorerAnswer?: GameTopscorerQuestionOption },
    { response: VipUserQuizduelGameState },
    [string, BaseQuizduelAction<VipUserQuizduelGameState, VipUserAsyncMultiplayerGameInstanceActionType>]
  >(this.actions, {
    triggerAction: VipSingleAsyncMultiplayerActions.answerQuestion,
    successAction: VipSingleAsyncMultiplayerActions.answerQuestionSuccess,
    failAction: VipSingleAsyncMultiplayerActions.answerQuestionFailure,
    showLoaderAction: VipSingleAsyncMultiplayerActions.showStepLoader,
    currentGame$: () => this.duelVsProFacade.currentGame$,
    payload: { type: VipUserAsyncMultiplayerGameInstanceActionType.ANSWER_QUESTION },
    serviceMethod: (
      gameLinkId: string,
      action: BaseQuizduelAction<VipUserQuizduelGameState, VipUserAsyncMultiplayerGameInstanceActionType>,
    ) => this.vipAsyncMultiplayerService.sendProGameActionVip$(gameLinkId, action),
    mapSuccessPayload: (response) => ({ response }),
    mapServiceMethodArgs: (triggerPayload, currentGame, staticPayload) => [
      currentGame.gameLinkId,
      {
        ...staticPayload,
        currentState: currentGame,
        answerOptionTicTacToe: triggerPayload?.footballPlayer,
        answerOptionTopscorer: triggerPayload?.topscorerAnswer,
        answerOptionTransferHistory: triggerPayload?.footballPlayer,
      },
    ],
  });

  startInterval = createIntervalTimerEffect(this.actions, {
    triggerAction: VipSingleAsyncMultiplayerActions.startInterval,
    tickAction: VipSingleAsyncMultiplayerActions.timeTick,
    stopAction: VipSingleAsyncMultiplayerActions.stopInterval,
    tickTime: this.TICK_TIME,
    timerAlreadyRunning$: this.duelVsProFacade.intervalRunning$,
  });

  timeTick = createTimeTickEffect<void, { footballPlayer: undefined; topscorerAnswer: undefined }>(this.actions, {
    triggerAction: VipSingleAsyncMultiplayerActions.timeTick,
    timeUpAction: VipSingleAsyncMultiplayerActions.answerQuestion,
    timeLeft$: this.duelVsProFacade.timeLeftQuestion$,
    condition$: this.duelVsProFacade.currentGame$.pipe(
      map((game) => game?.currentRound?.currentQuestionTimeoutTimestamp !== null && !game?.gameCompleted),
    ),
    timeUpPayload: () => {
      return { footballPlayer: undefined, topscorerAnswer: undefined };
    },
  });

  setCurrentCategory = createSetCurrentCategoryEffect<VipUserQuizduelGameState>(this.actions, {
    triggerActions: [
      VipSingleAsyncMultiplayerActions.getVipSingleGameSuccess,
      VipSingleAsyncMultiplayerActions.selectCategorySuccess,
    ],
    returnAction: VipSingleAsyncMultiplayerActions.setCurrentCategory,
  });

  onStartInterval$ = createEffect(() => {
    return this.actions.pipe(
      ofType(VipSingleAsyncMultiplayerActions.startInterval),
      map(({ game }) => VipSingleAsyncMultiplayerActions.startIntervalSuccess({ game })),
    );
  });

  onVipProDuelNextQuestionStart$ = createEffect(() => {
    return this.actions.pipe(
      ofType(VipSingleAsyncMultiplayerActions.getNextQuestionSuccess),
      map(({ response }) => VipSingleAsyncMultiplayerActions.startInterval({ game: response })),
    );
  });

  onVipProDuelGameEnd$ = createEffect(() => {
    return this.actions.pipe(
      ofType(VipSingleAsyncMultiplayerActions.answerQuestion, VipSingleAsyncMultiplayerActions.closeCurrentGame),
      map(() => VipSingleAsyncMultiplayerActions.stopInterval()),
    );
  });

  onReconnectToOngoingVipGame = createEffect(() => {
    return this.actions.pipe(
      ofType(VipSingleAsyncMultiplayerActions.getVipSingleGameSuccess),
      concatLatestFrom(() => this.duelVsProFacade.currentGame$),
      filter(([, proDuelGameState]) => {
        const currentQuestionTimeoutTimestamp = proDuelGameState?.currentRound?.currentQuestionTimeoutTimestamp;
        const currentRound = proDuelGameState?.currentRound;
        const currentQuestionIndex = currentRound?.currentQuestionIndex;
        const playerAnswers = proDuelGameState?.currentRound?.answers ?? [];

        return (
          currentQuestionTimeoutTimestamp != null &&
          !proDuelGameState?.gameCompleted &&
          Date.now() < new Date(currentQuestionTimeoutTimestamp)?.getTime() &&
          currentQuestionIndex !== undefined &&
          currentQuestionIndex >= 0 &&
          playerAnswers[currentQuestionIndex] == null
        );
      }),
      map(([, proDuelGameState]) => VipSingleAsyncMultiplayerActions.startInterval({ game: proDuelGameState! })),
    );
  });

  onReconnectCheckIfStateValid = createEffect(() => {
    return this.actions.pipe(
      ofType(VipSingleAsyncMultiplayerActions.getVipSingleGameSuccess),
      concatLatestFrom(() => this.duelVsProFacade.currentGame$),
      filter(([, proDuelGameState]) => {
        const currentQuestionTimeoutTimestamp = proDuelGameState?.currentRound?.currentQuestionTimeoutTimestamp;
        const currentRound = proDuelGameState?.currentRound;
        const currentQuestionIndex = currentRound?.currentQuestionIndex;
        const playerAnswers = proDuelGameState?.currentRound?.answers ?? [];
        const timeIsUp = Boolean(
          currentQuestionTimeoutTimestamp && Date.now() >= new Date(currentQuestionTimeoutTimestamp)?.getTime(),
        );

        return (
          currentQuestionTimeoutTimestamp != null &&
          !proDuelGameState?.gameCompleted &&
          timeIsUp &&
          currentQuestionIndex !== undefined &&
          currentQuestionIndex >= 0 &&
          playerAnswers[currentQuestionIndex] == null
        );
      }),
      map(([, proDuelGameState]) =>
        VipSingleAsyncMultiplayerActions.answerQuestion({ footballPlayer: undefined, topscorerAnswer: undefined }),
      ),
    );
  });

  // TODO: works kind of for Vip View but has to be adjusted for Community View
  setVipCurrentAsyncState$ = createEffect(() => {
    return this.actions.pipe(
      ofType(VipSingleAsyncMultiplayerActions.getVipSingleGameSuccess),
      concatLatestFrom(() => [this.userFacade.user$]),
      concatMap(() => {
        const currentAsyncState = AsyncState.USER_TURN;
        return of(VipSingleAsyncMultiplayerActions.setCurrentAsyncState({ currentAsyncState }));
      }),
    );
  });

  private handleError(message: string, error?: unknown): void {
    this.snackbar.show({ message, type: SnackbarType.ERROR });

    if (error) {
      console.error(error);
    }
  }

  private handleSuccess(message: string): void {
    this.snackbar.show({ message, type: SnackbarType.SUCCESS });
  }
}
