import { AsyncCategoryType } from '@kiq/shared/enums';
import { TicTacToeCategory } from './tic-tac-toe-category';
import { TopscorerCategory } from './topscorer-category';
import { TransferHistoryCategory } from './transfer-history-category';

export interface AsyncGameRoundCategory {
  id: string;
  type: AsyncCategoryType;
  isAvailable: boolean;
  ticTacToe?: TicTacToeCategory;
  topscorer?: TopscorerCategory;
  transferHistory?: TransferHistoryCategory;
}
