import { BaseAsyncMultiplayerViewModel } from './base-async-multiplayer-view-model';
import { RegularAsyncMultiplayerGame } from './regular-async-multiplayer-game';

export interface RegularAsyncMultiplayerViewModel extends BaseAsyncMultiplayerViewModel<RegularAsyncMultiplayerGame> {
  showGameAlreadyRunningDialog: boolean;
  showChallengeAlreadySendDialog: boolean;
  userGamePoints: number | null;
  opponentGamePoints: number | null;

  upcomingVipGames?: never;
  headlinerVipGames?: never;
  myVisibleGameInstances?: never;
  gameInstanceVipUserMap?: never;
}
