import { AsyncCategoryType } from '@kiq/shared/enums';
import { TicTacToeCategory } from './tic-tac-toe-category';
import { TopscorerCategory } from './topscorer-category';
import { TransferHistoryCategory } from './transfer-history-category';

// Only used in backoffice
export interface GameQuizduelCategoryInternal {
  id: string;
  type: AsyncCategoryType;
  isSecretCategoryType: boolean;
  isAvailable: boolean;
  ticTacToe: TicTacToeCategory;
  topscorer: TopscorerCategory;
  transferHistory: TransferHistoryCategory;
}
