import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { VipSingleAsyncMultiplayerActions } from './vip-single-async-multiplayer.actions';
import { AsyncState } from '@kiq/shared/enums';
import { CommonAsyncGameState } from '../../interfaces/common-async-game-state';
import { createInitialCommonAsyncGameState } from '../../helper/initial-state.const';
import { provideCommonAsyncActionHandlers } from '../../helper/shared-reducer.helpers';
import { getTimeLeft, getTimeLeftInPercent } from 'client/util/time-calculations';
import {
  AsyncGameRoundCategory,
  AsyncMultiplayerUser,
  KikkzError,
  VipSingleAsyncMultiplayerViewModel,
  VipUserQuizduelGameState,
} from '@kiq/shared/interfaces';
import { convertUserToMultiplayerUser } from '@kiq/shared/util/helper';

export interface VipSingleAsyncMultiplayerState extends CommonAsyncGameState<VipUserQuizduelGameState> {
  currentAsyncState: AsyncState | null;
  proUser: AsyncMultiplayerUser | null;
}

export const initialVipSingleAsyncMultiplayerState: VipSingleAsyncMultiplayerState = {
  ...createInitialCommonAsyncGameState<VipUserQuizduelGameState>(),
  currentAsyncState: null,
  proUser: null,
};

export const vipSingleAsyncMultiplayerFeature = createFeature({
  name: 'vipSingleAsyncMultiplayer',
  reducer: createReducer(
    initialVipSingleAsyncMultiplayerState,
    ...provideCommonAsyncActionHandlers<
      VipSingleAsyncMultiplayerState,
      VipUserQuizduelGameState,
      KikkzError,
      AsyncGameRoundCategory
    >({
      showStepLoader: VipSingleAsyncMultiplayerActions.showStepLoader,
      showGameLoader: VipSingleAsyncMultiplayerActions.showGameLoader,
      refreshGameStateOnFocus: VipSingleAsyncMultiplayerActions.refreshGameStateOnFocus,
      startIntervalSuccess: VipSingleAsyncMultiplayerActions.startIntervalSuccess,
      stopInterval: VipSingleAsyncMultiplayerActions.stopInterval,
      timeTick: VipSingleAsyncMultiplayerActions.timeTick,
      getNextQuestion: VipSingleAsyncMultiplayerActions.getNextQuestion,
      getNextQuestionSuccess: VipSingleAsyncMultiplayerActions.getNextQuestionSuccess,
      getNextQuestionFailure: VipSingleAsyncMultiplayerActions.getNextQuestionFailure,
      selectCategory: VipSingleAsyncMultiplayerActions.selectCategory,
      selectCategorySuccess: VipSingleAsyncMultiplayerActions.selectCategorySuccess,
      selectCategoryFailure: VipSingleAsyncMultiplayerActions.selectCategoryFailure,
      answerQuestion: VipSingleAsyncMultiplayerActions.answerQuestion,
      answerQuestionSuccess: VipSingleAsyncMultiplayerActions.answerQuestionSuccess,
      answerQuestionFailure: VipSingleAsyncMultiplayerActions.answerQuestionFailure,
      setCurrentCategory: VipSingleAsyncMultiplayerActions.setCurrentCategory,
    }),

    immerOn(VipSingleAsyncMultiplayerActions.closeCurrentGame, (state) => {
      Object.assign(state, initialVipSingleAsyncMultiplayerState);
    }),

    immerOn(VipSingleAsyncMultiplayerActions.getVipSingleGame, (state) => {
      state.error = null;
    }),
    immerOn(VipSingleAsyncMultiplayerActions.getVipSingleGameSuccess, (state, { response }) => {
      state.currentGame = response;
      state.gameLoading = false;

      if (response.currentRound?.currentQuestionTimeoutTimestamp) {
        state.timeLeftQuestion = getTimeLeft(
          response?.currentRound?.currentQuestionTimeoutTimestamp,
          response.currentServerTime,
        );
        state.timeLeftQuestionInPercent = getTimeLeftInPercent(
          state.timeLeftQuestion,
          response?.currentRound?.currentQuestion?.maxAnswerDurationSeconds,
        );
      }

      state.proUser = convertUserToMultiplayerUser(response.gameInfo);
    }),
    immerOn(VipSingleAsyncMultiplayerActions.getVipSingleGameFailure, (state, { error }) => {
      state.currentGame = null;
      state.currentCategory = null;
      state.gameLoading = false;
      state.error = error ?? null;
    }),
    immerOn(VipSingleAsyncMultiplayerActions.setCurrentAsyncState, (state, { currentAsyncState }) => {
      state.currentAsyncState = currentAsyncState;
    }),
    immerOn(VipSingleAsyncMultiplayerActions.resetError, (state) => {
      state.error = null;
    }),
  ),
});

export const {
  name, // feature name
  selectError,
  selectGameLoading,
  selectStepLoading,
  selectTimeLeftQuestion,
  selectTimeLeftQuestionInPercent,
  selectIntervalRunning,
  selectIsNextQuestionLoading,
  selectCurrentAsyncState,
  selectCurrentCategory,
  selectCurrentGame,
  selectProUser,
} = vipSingleAsyncMultiplayerFeature;

export const selectVipSingleAsyncMultiplayerViewModel = createSelector(
  selectError,
  selectGameLoading,
  selectStepLoading,
  selectCurrentGame,
  selectTimeLeftQuestion,
  selectIntervalRunning,
  selectTimeLeftQuestionInPercent,
  selectIsNextQuestionLoading,
  selectCurrentCategory,
  selectCurrentAsyncState,
  (
    error: KikkzError | null,
    gameLoading: boolean,
    stepLoading: boolean,
    currentGame: VipUserQuizduelGameState | null,
    timeLeftQuestion: number | null,
    intervalRunning: boolean,
    timeLeftQuestionInPercent: number | null,
    isNextQuestionLoading: boolean,
    currentCategory: AsyncGameRoundCategory | null,
    currentAsyncState: AsyncState | null,
  ) => {
    const vm = {
      error,
      gameLoading,
      stepLoading,
      timeLeftQuestion,
      intervalRunning,
      timeLeftQuestionInPercent,
      isNextQuestionLoading,
      currentCategory,
      currentAsyncState,
      currentGame,
    };
    return vm as VipSingleAsyncMultiplayerViewModel<VipUserQuizduelGameState>;
  },
);
