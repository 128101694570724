import { AttributeType, FootballPlayerAgeType, FootballPlayerPosition, PreferredFoot } from '@kiq/shared/enums';
import { NationalTeam } from './national-team';
import { FootballPlayerNationality } from './football-player-nationality';
import { FootballTeam } from '@kiq/shared/core';

export interface AxisAttribute {
  type: AttributeType;
  team?: FootballTeam;
  nationalTeam?: NationalTeam;
  position?: FootballPlayerPosition;
  preferredFoot?: PreferredFoot;
  nationality?: FootballPlayerNationality;
  ageType?: FootballPlayerAgeType;

  //TODO: fit naming to backend when its implemented
  olderThan28?: boolean;
}
