import { ComponentRef, ElementRef, inject, Injectable, Injector, Renderer2, RendererFactory2 } from '@angular/core';
import { CdkPortalOutlet, ComponentPortal } from '@angular/cdk/portal';
import { MODAL_DATA, MODAL_REF } from '../token/modal-data.token';
import { Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { InGameModalWrapperComponent } from '../component/in-game-modal-wrapper/in-game-modal-wrapper.component';
import { InGameModalConfig, ModalRef } from '@kiq/shared/interfaces';

@Injectable()
export class InGameModalService {
  private readonly renderer: Renderer2;
  private readonly injector = inject(Injector);
  private activeComponentRef: ComponentRef<unknown> | null = null;

  get hasModalOpen() {
    return !!this.activeComponentRef;
  }

  constructor(
    rendererFactory: RendererFactory2,
    private componentPortalOutlet: CdkPortalOutlet,
    private elementRef: ElementRef<unknown>,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  openInGameModal<T, R = undefined>(config: InGameModalConfig, data?: R) {
    const id = uuidv4();

    const element = this.renderer.createElement('div');
    this.renderer.addClass(element, 'in-game-modal-backdrop');
    this.renderer.appendChild(this.elementRef.nativeElement, element);

    const destroyListener = this.renderer.listen(element, 'click', () => {
      if (!config.withoutBackdropClick) {
        close();
      }
    });

    const cleanUp = () => {
      this.componentPortalOutlet.detach();
      destroyListener();
      this.renderer.removeChild(this.elementRef.nativeElement, element);
      this.activeComponentRef = null;
    };

    const close = (data?: T) => {
      cleanUp();
      closeSubject.next(data);
      closeSubject.complete();
    };

    const closeSubject = new Subject<T | undefined>();

    const modalRef: ModalRef<T> = {
      afterClosed$: closeSubject.asObservable(),
      close,
      id,
    };

    const portal = new ComponentPortal(InGameModalWrapperComponent, null, this.createInjector(modalRef, data));

    this.activeComponentRef = this.componentPortalOutlet.attach(portal);
    this.activeComponentRef.setInput('config', config);

    return modalRef;
  }

  updateInGameModal(config: InGameModalConfig) {
    if (this.activeComponentRef) {
      this.activeComponentRef.setInput('config', config);
    }
  }

  private createInjector<T, R>(modalRef: ModalRef<T>, data?: R) {
    return Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: MODAL_REF,
          useValue: modalRef,
        },
        {
          provide: MODAL_DATA,
          useValue: data,
        },
      ],
    });
  }
}
