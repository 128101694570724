import { AsyncGameRoundCategory } from './async-game-round-category';
import { VipUserQuizduelGameRound } from './vip-user-quizduel-game-round';
import { VipUserQuizduelGameUserView } from './vip-user-quizduel-game-user-view';

export interface VipUserQuizduelGameState {
  gameLinkId: string;
  categories: Array<AsyncGameRoundCategory>;
  score: number;
  gameInfo: VipUserQuizduelGameUserView;
  currentRound?: VipUserQuizduelGameRound;
  playedRounds: Array<VipUserQuizduelGameRound>;
  gameCompleted: boolean;
  gameCompletedAd?: Date;
  currentServerTime: Date;
}
