import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { BasicUserView } from '@kiq/shared/classes';
import {
  KikkzError,
  UserGameStatisticOpponentDetail,
  UserGameStatisticOpponentOverview,
  UserGameStatisticOverview,
  UserVipGameStatisticsOverview,
} from '@kiq/shared/interfaces';

export const OnlineStatisticsActions = createActionGroup({
  source: 'OnlineStatistics',
  events: {
    'get own user game statistic overview start': emptyProps(),
    'get own user game statistic overview success': props<{ userGameStatisticOverview: UserGameStatisticOverview }>(),
    'get own user game statistic overview fail': emptyProps(),

    'get other user game statistic overview start': props<{ otherUser: BasicUserView }>(),
    'get other user game statistic overview success': props<{
      userGameStatisticOpponentOverview: UserGameStatisticOverview;
    }>(),
    'get other user game statistic overview fail': emptyProps(),

    'get all user game statistic opponent overviews start': emptyProps(),
    'get all user game statistic opponent overviews success': props<{
      allUserGameStatisticOpponentOverviews: Array<UserGameStatisticOpponentOverview>;
    }>(),
    'get all user game statistic opponent overviews fail': emptyProps(),

    'get user game Statistic opponent details start': props<{ otherUser: BasicUserView }>(),
    'get user game Statistic opponent details success': props<{
      userGameStatisticOpponentDetails: Array<UserGameStatisticOpponentDetail>;
    }>(),
    'get user game Statistic opponent details fail': emptyProps(),

    'get other user start': props<{ userName: string }>(),
    'get other user success': props<{ otherUser: BasicUserView }>(),
    'get other user fail': emptyProps(),

    'show overview loader': emptyProps(),

    'show all statistics loader': emptyProps(),

    'show other user loader': emptyProps(),

    'get vip game statistics overview for user': props<{ userId: string }>(),
    'get vip game statistics overview for user success': props<{ response: UserVipGameStatisticsOverview }>(),
    'get vip game statistics overview for user fail': props<{ error?: KikkzError }>(),

    'reset vip game statistics overview': emptyProps(),
  },
});
