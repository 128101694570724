import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { vipAsyncMultiplayerFeature } from './+state/vip-async-multiplayer.reducer';
import { VipAsyncMultiplayerEffects } from './+state/vip-async-multiplayer.effects';
import { VipAsyncMultiplayerFacade } from './+state/vip-async-multiplayer.facade';
import { VipAsyncMultiplayerService } from '../service/vip-async-multiplayer.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(vipAsyncMultiplayerFeature),
    EffectsModule.forFeature([VipAsyncMultiplayerEffects]),
  ],
  providers: [VipAsyncMultiplayerFacade, VipAsyncMultiplayerService],
})
export class ClientDataAccessVipAsyncMultiplayerDataModule {}
