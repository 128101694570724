import { GameEndReason } from '../../../../enums/src/lib/enum/game-end-reason';
import { GameFakeOrFactConfig } from './game-fake-or-fact-config';
import { GameFakeOrFactStory } from './game-fake-or-fact-story';

export interface GameFakeOrFactState {
  gameId: string;
  config: GameFakeOrFactConfig;
  maxTurnDurationSeconds?: number;
  turnTimeoutTimestamp?: Date;
  currentStory: GameFakeOrFactStory;
  turnEnded?: boolean;
  gameEnded: boolean;
  gameEndReason?: GameEndReason;
  userScore?: number;
}
