import { GamePlayerGuessingHint } from './game-player-guessing-hint';
import { GameState } from '@kiq/shared/enums';
import { GamePlayerGuessingConfig } from './game-player-guessing-config';

export interface WiiGame {
  config: GamePlayerGuessingConfig;
  gameId: string;
  gameTimeoutTimestamp: Date;
  maxNumberOfHints: number;
  receivedHints: GamePlayerGuessingHint[];
  currentHint: GamePlayerGuessingHint | null;
  gameEnded: boolean;
  footballPlayerName: string | null;
  state: GameState;
  errorCode?: number;
  userScore?: number;
  maxGameDurationSeconds: number;
  currentServerTime: Date;
}
