export enum AsyncMultiplayerComponentState {
  ACCEPT_CHALLENGE = 'acceptChallenge',
  OVERVIEW = 'overview',
  CATEGORY_SELECTION = 'categorySelection',
  NEXT_CATEGORY_SCREEN = 'nextCategoryScreen',
  QUESTION = 'question',
  ADVERTISEMENT = 'advertisement',
  TRANSITION_HELPER = 'transitionHelper',
  TRANSITION_HELPER_BEFORE_QUESTION = 'transitionHelperBeforeQuestion',
}
