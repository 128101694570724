import { DestroyRef, inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Game, GameDataService } from '@kiq/client/game-data';
import { combineLatest, map, switchMap, tap } from 'rxjs';
import { GameDifficulty, PlayerNumber } from '@kiq/shared/enums';
import {
  RegularAsyncMultiplayerFacade,
  VipAsyncMultiplayerFacade,
} from '@kiq/client/data-access/async-multiplayer-data';
import { UserFacade } from '@kiq/client/data-access/user';
import { Actions, ofType } from '@ngrx/effects';
import { AsyncGamesOverview, RegularAsyncMultiplayerGame, VipAsyncMultiplayerGame } from '@kiq/shared/interfaces';
import { concatLatestFrom } from '@ngrx/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface GamesOverviewStoreState {
  games: Array<Game> | null;
  loading: boolean;
  selectedDifficulty: GameDifficulty | null;
}

const initialState: GamesOverviewStoreState = {
  games: null,
  loading: false,
  selectedDifficulty: GameDifficulty.EASY,
};

@Injectable({ providedIn: 'root' })
export class GamesOverviewStoreStore extends ComponentStore<GamesOverviewStoreState> {
  private readonly gamesDataService = inject(GameDataService);
  private readonly vipAsyncMultiplayerFacade = inject(VipAsyncMultiplayerFacade);
  private readonly asyncMultiplayerFacade = inject(RegularAsyncMultiplayerFacade);
  private readonly userFacade = inject(UserFacade);
  private readonly actions = inject(Actions);
  private readonly destroyRef = inject(DestroyRef);

  readonly allAsyncMultiplayerGames$ = this.asyncMultiplayerFacade.allAsyncMultiplayerGames$;
  readonly myVisibleVipGames$ = this.vipAsyncMultiplayerFacade.myVisibleVipGames$;
  readonly combinedSortedGames$ = combineLatest([
    this.allAsyncMultiplayerGames$,
    this.vipAsyncMultiplayerFacade.myVisibleVipGames$,
  ]).pipe(
    concatLatestFrom(() => this.userFacade.user$),
    map(([[allRegularAsyncMultiplayerGames$, myVisibleVipGames$], user]) => {
      const allRegularAsyncMultiplayerGames = allRegularAsyncMultiplayerGames$ ?? ([] as AsyncGamesOverview);
      const myVisibleVipGames = myVisibleVipGames$ ?? [];

      const vipEndedGames: VipAsyncMultiplayerGame[] = [];
      const gamesWithUsersTurn: (RegularAsyncMultiplayerGame | VipAsyncMultiplayerGame)[] = [];
      const gamesWithOpponentTurn: (RegularAsyncMultiplayerGame | VipAsyncMultiplayerGame)[] = [];

      const sortByGameEndTimestamp = (
        a: RegularAsyncMultiplayerGame | VipAsyncMultiplayerGame,
        b: RegularAsyncMultiplayerGame | VipAsyncMultiplayerGame,
      ) => new Date(b?.gameEndTimestamp as Date).getTime() - new Date(a.gameEndTimestamp as Date).getTime();

      const sortByRoundTimeoutTimestamp = (
        a: RegularAsyncMultiplayerGame | VipAsyncMultiplayerGame,
        b: RegularAsyncMultiplayerGame | VipAsyncMultiplayerGame,
      ) => {
        const timestampA = a.currentRound?.roundTimeoutTimestamp
          ? new Date(a.currentRound.roundTimeoutTimestamp).getTime()
          : null;
        const timestampB = b.currentRound?.roundTimeoutTimestamp
          ? new Date(b.currentRound.roundTimeoutTimestamp).getTime()
          : null;

        if (timestampA === null && timestampB === null) {
          return 0;
        } else if (timestampA === null) {
          return 1;
        } else if (timestampB === null) {
          return -1;
        }

        // Normal sorting by timestamp
        return timestampB - timestampA;
      };

      myVisibleVipGames.forEach((game) => {
        if (game.gameEnded) {
          vipEndedGames.push(game);
        } else {
          if (game.currentRound?.playerAtTurn === PlayerNumber.PLAYER_1) {
            gamesWithUsersTurn.push(game);
          } else {
            gamesWithOpponentTurn.push(game);
          }
        }
      });

      // Running games
      allRegularAsyncMultiplayerGames.runningGames?.forEach((game) => {
        if (game.playerIds.get(game?.currentRound?.playerAtTurn ?? PlayerNumber.PLAYER_1) === user?.id) {
          gamesWithUsersTurn.push(game);
        } else {
          gamesWithOpponentTurn.push(game);
        }
      });

      gamesWithUsersTurn.sort(sortByRoundTimeoutTimestamp);
      gamesWithOpponentTurn.sort(sortByRoundTimeoutTimestamp);

      const combinedRunningGames = [...gamesWithUsersTurn, ...gamesWithOpponentTurn];

      // Ended games
      const endedRegularGames = allRegularAsyncMultiplayerGames.endedGames ?? [];
      const combinedEndedGames: (RegularAsyncMultiplayerGame | VipAsyncMultiplayerGame)[] = [
        ...endedRegularGames,
        ...vipEndedGames,
      ];

      combinedEndedGames.sort(sortByGameEndTimestamp);

      // Pending games
      const pendingGames = allRegularAsyncMultiplayerGames.pendingGames ?? [];
      const challengedPendingGames: RegularAsyncMultiplayerGame[] = [];
      const challengerPendingGames: RegularAsyncMultiplayerGame[] = [];

      pendingGames.forEach((pendingGame) => {
        if (pendingGame.challengerUserId !== user?.id) {
          challengedPendingGames.push(pendingGame);
        } else {
          challengerPendingGames.push(pendingGame);
        }
      });

      if (challengedPendingGames.length > 1) {
        challengedPendingGames.sort(sortByGameEndTimestamp);
      }

      if (challengerPendingGames.length > 1) {
        challengerPendingGames.sort(sortByGameEndTimestamp);
      }

      return [...challengedPendingGames, ...combinedRunningGames, ...challengerPendingGames, ...combinedEndedGames];
    }),
  );

  constructor() {
    super(initialState);

    this.actions
      .pipe(ofType(this.asyncMultiplayerFacade.deleteEndedGameSuccess), takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => this.vipAsyncMultiplayerFacade.getVipGamesOverview(),
      });
  }

  readonly games$ = this.select((state) => state?.games);
  readonly loading$ = this.select((state) => state?.loading);
  readonly selectedDifficulty$ = this.select((state) => state?.selectedDifficulty);

  readonly viewModel$ = this.select((state) => {
    return {
      games: state.games,
      loading: state.loading,
    };
  });

  readonly setSelectedDifficulty = this.updater((state, difficulty: GameDifficulty) => {
    return {
      ...state,
      selectedDifficulty: difficulty,
    };
  });

  readonly loadAllGames = this.effect((params$) => {
    return params$.pipe(
      tap(() => {
        this.setState((state) => {
          return {
            ...state,
            loading: true,
          };
        });
      }),
      switchMap(() => {
        return this.gamesDataService.getAllGames$().pipe(
          tap((response: Array<Game>) => {
            this.setState((state) => {
              return {
                ...state,
                games: response,
                loading: false,
              };
            });
          }),
        );
      }),
    );
  });
}
