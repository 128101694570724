import { ActivatedRouteSnapshot, RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslocoService } from '@jsverse/transloco';
import { EtrackerPageTitle, PageTitleIdentifier } from '@kiq/shared/enums';
import { of, take, timeout } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EtrackerService } from './etracker.service';

@Injectable({ providedIn: 'root' })
export class CustomTitleStrategyService extends TitleStrategy {
  private readonly title = inject(Title);
  private readonly translocoService = inject(TranslocoService);
  private readonly etrackerService = inject(EtrackerService);

  private readonly pageTitleIdentifierToEtrackerPageTitleMap = new Map<PageTitleIdentifier, EtrackerPageTitle>(
    Object.keys(PageTitleIdentifier).map((key) => {
      const identifier = PageTitleIdentifier[key as keyof typeof PageTitleIdentifier];
      const pageTitle = EtrackerPageTitle[key as keyof typeof EtrackerPageTitle];
      return [identifier, pageTitle];
    }),
  );

  updateTitle(snapshot: RouterStateSnapshot) {
    const title: string[] = [];
    const deepestChild = this.getDeepestChild(snapshot.root);
    const routeTitleData = deepestChild?.data?.['title'] as PageTitleIdentifier | undefined;

    if (routeTitleData) {
      this.translocoService
        .selectTranslate('pageTitleIdentifiers.' + routeTitleData)
        .pipe(
          timeout(2000),
          catchError(() => of(null)),
          take(1),
        )
        .subscribe((translatedTitle) => {
          if (translatedTitle) {
            title.push(translatedTitle);
          }

          title.push('KIKKZ');
          this.title.setTitle(title.join(' | '));

          this.sendEtrackerPageView([routeTitleData]);
        });
    }
  }

  setTitle(titleIdentifiers: PageTitleIdentifier[]) {
    const title: string[] = [];

    const newTitle = titleIdentifiers
      .map((identifier) => this.translocoService.translate('pageTitleIdentifiers.' + identifier))
      .join(' - ');

    title.push(newTitle);
    title.push('KIKKZ');

    const finalTitle = title.join(' | ');
    this.title.setTitle(finalTitle);

    this.sendEtrackerPageView(titleIdentifiers);
  }

  private sendEtrackerPageView(titleIdentifiers: PageTitleIdentifier[]) {
    if (this.checkIfPreventTracking(titleIdentifiers)) {
      return;
    }

    const eTrackerTitle = titleIdentifiers
      .map((identifier) => this.pageTitleIdentifierToEtrackerPageTitleMap.get(identifier))
      .join(' | ');

    this.etrackerService.trackPageView(eTrackerTitle);
  }

  private checkIfPreventTracking(titleIdentifiers: PageTitleIdentifier[]) {
    return titleIdentifiers.includes(PageTitleIdentifier.GAMES);
  }

  private getDeepestChild(node: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (!node.children || node.children.length === 0) {
      return node;
    }

    return this.getDeepestChild(node.children[0]);
  }
}
