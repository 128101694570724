import { AsyncGameEndReason, PlayerNumber } from '@kiq/shared/enums';
import { AsyncGameRoundCategory } from './async-game-round-category';
import { AsyncGameRound } from './async-game-round';

export interface BaseAsyncMultiplayerGame {
  gameId: string;
  numberOfRounds: number;
  categories: Array<AsyncGameRoundCategory>;
  playerScores: Map<PlayerNumber, number>;
  currentRound?: AsyncGameRound;
  playedRounds: Array<AsyncGameRound>;
  gameTimeoutTimestamp?: Date;
  gameEnded: boolean;
  gameEndReason?: AsyncGameEndReason;
  gameEndTimestamp?: Date;
  gameWinner?: PlayerNumber;
  currentServerTime: Date;
}
