import { createFeature, createReducer, createSelector } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { AdvertisementActions } from './advertisement.actions';
import { AdvertisementPlace, AdvertisementType } from '@kiq/shared/enums';
import { generalAdvertisementAssets } from '../const/advertisement-maps';
import { SelfAdvertisementAsset } from '@kiq/shared/interfaces';

export interface AdvertisementState {
  error: string | null;
  rankingLoading: boolean;
  inGameLoading: boolean;
  gamesOverviewLoading: boolean;
  advertisementAsset: SelfAdvertisementAsset | null;
  alreadyLoadedAssetImgUrls: Set<string>;
  generalRankingAssets: SelfAdvertisementAsset[];
  generalInGameAssets: SelfAdvertisementAsset[];
  generalGamesOverviewAssets: SelfAdvertisementAsset[];
  currentInGameAdvertisementAssetIndex: number | null;
  currentGamesOverviewAdvertisementAssetIndex: number | null;
  currentRankingAdvertisementAssetIndex: number | null;
  rankingAdvertisementAssetsQueue: SelfAdvertisementAsset[];
  inGameAdvertisementAssetsQueue: SelfAdvertisementAsset[];
  gamesOverviewAdvertisementAssetsQueue: SelfAdvertisementAsset[];
  currentAdvertisementType: AdvertisementType;
}

export const initialAdvertisementState: AdvertisementState = {
  rankingLoading: false,
  inGameLoading: false,
  gamesOverviewLoading: false,
  error: null,
  advertisementAsset: null,

  alreadyLoadedAssetImgUrls: new Set<string>(),
  generalRankingAssets: generalAdvertisementAssets.get(AdvertisementPlace.RANKING) ?? [],
  generalInGameAssets: generalAdvertisementAssets.get(AdvertisementPlace.IN_GAME) ?? [],
  generalGamesOverviewAssets: generalAdvertisementAssets.get(AdvertisementPlace.GAMES_OVERVIEW) ?? [],
  currentRankingAdvertisementAssetIndex: Math.floor(
    Math.random() * ((generalAdvertisementAssets.get(AdvertisementPlace.RANKING)?.length ?? 1) || 1),
  ),
  currentInGameAdvertisementAssetIndex: Math.floor(
    Math.random() * ((generalAdvertisementAssets.get(AdvertisementPlace.IN_GAME)?.length ?? 1) || 1),
  ),
  currentGamesOverviewAdvertisementAssetIndex: Math.floor(
    Math.random() * ((generalAdvertisementAssets.get(AdvertisementPlace.GAMES_OVERVIEW)?.length ?? 1) || 1),
  ),
  rankingAdvertisementAssetsQueue: generalAdvertisementAssets.get(AdvertisementPlace.RANKING) ?? [],
  inGameAdvertisementAssetsQueue: generalAdvertisementAssets.get(AdvertisementPlace.IN_GAME) ?? [],
  gamesOverviewAdvertisementAssetsQueue: generalAdvertisementAssets.get(AdvertisementPlace.GAMES_OVERVIEW) ?? [],

  currentAdvertisementType: calculateNextAdvertisementType(),
};

export const advertisementFeature = createFeature({
  name: 'advertisement',
  reducer: createReducer(
    initialAdvertisementState,
    immerOn(AdvertisementActions.getNextAdvertisement, (state, { placement }) => {
      const currentAdvertisementType = state.currentAdvertisementType;

      state.currentAdvertisementType = calculateNextAdvertisementType();

      if (currentAdvertisementType === AdvertisementType.google) {
        return;
      }

      const resetQueue = (generalAssets: SelfAdvertisementAsset[]): SelfAdvertisementAsset[] =>
        shuffleArray([...generalAssets]);

      const getPlacementData = (placement: AdvertisementPlace) => {
        switch (placement) {
          case AdvertisementPlace.RANKING:
            return {
              currentIndex: state.currentRankingAdvertisementAssetIndex,
              queue: state.rankingAdvertisementAssetsQueue,
              generalAssets: state.generalRankingAssets,
            };
          case AdvertisementPlace.IN_GAME:
            return {
              currentIndex: state.currentInGameAdvertisementAssetIndex,
              queue: state.inGameAdvertisementAssetsQueue,
              generalAssets: state.generalInGameAssets,
            };
          case AdvertisementPlace.GAMES_OVERVIEW:
            return {
              currentIndex: state.currentGamesOverviewAdvertisementAssetIndex,
              queue: state.gamesOverviewAdvertisementAssetsQueue,
              generalAssets: state.generalGamesOverviewAssets,
            };
        }
      };

      const placementData = getPlacementData(placement);
      let { currentIndex, queue, generalAssets } = placementData;

      if (currentIndex !== null) {
        queue.splice(currentIndex, 1);
      }

      if (queue.length === 0) {
        queue = resetQueue(generalAssets);
      }

      let randomIndex = Math.floor(Math.random() * queue.length);
      // make sure to show different index next
      if (queue.length > 1 && randomIndex === currentIndex) {
        randomIndex = (randomIndex + 1) % queue.length;
      }

      switch (placement) {
        case AdvertisementPlace.RANKING:
          state.currentRankingAdvertisementAssetIndex = randomIndex;
          state.rankingAdvertisementAssetsQueue = queue;
          break;
        case AdvertisementPlace.IN_GAME:
          state.currentInGameAdvertisementAssetIndex = randomIndex;
          state.inGameAdvertisementAssetsQueue = queue;
          break;
        case AdvertisementPlace.GAMES_OVERVIEW:
          state.currentGamesOverviewAdvertisementAssetIndex = randomIndex;
          state.gamesOverviewAdvertisementAssetsQueue = queue;
          break;
      }
    }),
  ),
});

export const {
  name, // feature name
  selectError,
  selectAdvertisementAsset,
  selectAlreadyLoadedAssetImgUrls,
  selectGeneralGamesOverviewAssets,
  selectGeneralInGameAssets,
  selectGeneralRankingAssets,
  selectCurrentGamesOverviewAdvertisementAssetIndex,
  selectCurrentInGameAdvertisementAssetIndex,
  selectCurrentRankingAdvertisementAssetIndex,
  selectGamesOverviewAdvertisementAssetsQueue,
  selectInGameAdvertisementAssetsQueue,
  selectRankingAdvertisementAssetsQueue,
  selectInGameLoading,
  selectGamesOverviewLoading,
  selectRankingLoading,
  selectCurrentAdvertisementType,
} = advertisementFeature;

export const selectCurrentRankingAdvertisementAsset = createSelector(
  selectCurrentRankingAdvertisementAssetIndex,
  selectRankingAdvertisementAssetsQueue,
  (currentIndex, assets) => {
    if (currentIndex === null) return null;

    return assets[currentIndex];
  },
);

export const selectCurrentInGameAdvertisementAsset = createSelector(
  selectCurrentInGameAdvertisementAssetIndex,
  selectInGameAdvertisementAssetsQueue,
  (currentIndex, assets) => {
    if (currentIndex === null) return null;

    return assets[currentIndex];
  },
);

export const selectCurrentGamesOverviewAdvertisementAsset = createSelector(
  selectCurrentGamesOverviewAdvertisementAssetIndex,
  selectGamesOverviewAdvertisementAssetsQueue,
  (currentIndex, assets) => {
    if (currentIndex === null) return null;

    return assets[currentIndex];
  },
);

function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)); // Random index from 0 to i
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
}

function setLoadingState(state: AdvertisementState, loading: boolean, placement?: AdvertisementPlace): void {
  if (!placement) {
    state.rankingLoading = loading;
    state.inGameLoading = loading;
    state.gamesOverviewLoading = loading;
  }

  switch (placement) {
    case AdvertisementPlace.RANKING: {
      state.rankingLoading = loading;
      break;
    }
    case AdvertisementPlace.IN_GAME: {
      state.inGameLoading = loading;
      break;
    }
    case AdvertisementPlace.GAMES_OVERVIEW: {
      state.gamesOverviewLoading = loading;
      break;
    }
  }
}

function calculateNextAdvertisementType() {
  return Math.random() < 2 / 3 ? AdvertisementType.google : AdvertisementType.self;
}
