import { GameTopscorerState } from './game-topscorer-state';
import { GameTopscorerActionType } from '@kiq/shared/enums';
import { GameTopscorerQuestionOption } from './game-topscorer-question-option';

export interface GameTopscorerAction {
  currentState: GameTopscorerState;
  type: GameTopscorerActionType;
  answerOptionForCurrentQuestion?: GameTopscorerQuestionOption;
  gameTimeoutExceeded?: boolean;
}
