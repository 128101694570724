import { Observable, Observer, tap } from 'rxjs';
import { inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ENV_TOKEN } from '@kiq/client/util/config';

@Injectable({
  providedIn: 'root',
})
export class EtrackerService {
  private readonly ETRACKER_SCRIPT_SRC = '//code.etracker.com/code/e.js';
  private readonly ETRACKER_DATA_SECURE_CODE = 'z4skWx';
  private readonly ETRACKER_SCRIPT_ATTRIBUTE_LIST: Record<string, string>[] = [
    { async: 'true' },
    { src: this.ETRACKER_SCRIPT_SRC },
    { id: '_etLoader' },
    { type: 'text/javascript' },
    { 'data-page-changed-detection': 'off' },
    { 'data-block-cookies': 'true' },
    { 'data-secure-code': this.ETRACKER_DATA_SECURE_CODE },
  ];

  private readonly DOCUMENT = inject(DOCUMENT);
  private readonly env = inject(ENV_TOKEN);

  private renderer: Renderer2;
  private loading = false;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  private loadScript$(pageTitle: string): Observable<any> {
    this.loading = true;
    return new Observable((observer: Observer<void>) => {
      try {
        const scriptEle = this.renderer.createElement('script');

        this.addInitialPageTitleHelperScript(pageTitle);

        this.ETRACKER_SCRIPT_ATTRIBUTE_LIST.forEach((attr) => {
          const key = Object.keys(attr)[0];
          const value = attr[key];
          this.renderer.setAttribute(scriptEle, key, value);
        });

        if (scriptEle.readyState) {
          //IE
          scriptEle.onreadystatechange = () => {
            if (scriptEle.readyState === 'loaded' || scriptEle.readyState === 'complete') {
              scriptEle.onreadystatechange = null;
              observer.next();
              observer.complete();
            }
          };
        } else {
          //Others
          scriptEle.onload = () => {
            observer.next();
            observer.complete();
          };
        }
        scriptEle.onerror = () => {
          console.log('Could not load script');
          observer.error('Could not load script');
        };
        this.renderer.appendChild(this.DOCUMENT.head, scriptEle);
      } catch (err) {
        observer.error(err);
      }
    }).pipe(tap({ complete: () => (this.loading = false), error: () => (this.loading = false) }));
  }

  private sendPageView(pageTitle: string): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    et_eC_Wrapper({
      et_et: this.ETRACKER_DATA_SECURE_CODE,
      et_pagename: pageTitle,
    });
  }

  private addInitialPageTitleHelperScript(pageTitle: string): void {
    const scriptForPageName = this.renderer.createElement('script');
    scriptForPageName.type = 'text/javascript';

    scriptForPageName.innerHTML = `
        var et_pagename = "${pageTitle}";
        `;

    this.renderer.appendChild(this.DOCUMENT.head, scriptForPageName);
  }

  trackPageView(pageTitle: string): void {
    if (this.env !== 'prod' || this.loading) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const etracker = window?.['_etracker'] as any | undefined;

    if (etracker) {
      this.sendPageView(pageTitle);
      return;
    }

    this.loadScript$(pageTitle).subscribe();
  }
}
