import { inject, Injectable, Injector } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { RemoteConfigActions } from './remote-config.actions';
import { catchError, filter, from, fromEvent, map, of, switchMap } from 'rxjs';
import { fetchAndActivate, getAll, isSupported, RemoteConfig } from '@angular/fire/remote-config';
import { Store } from '@ngrx/store';
import { DOCUMENT } from '@angular/common';
import { selectInitialAppVersion } from './remote-config.reducer';
import { RemoteConfigKeys } from '../enum/remote-config-keys';
import { Router } from '@angular/router';
import { clientRoutes } from '@kiq/client/util/routing';

@Injectable()
export class RemoteConfigEffects {
  private readonly REMOTE_CONFIG_APP_VERSION_KEY = RemoteConfigKeys.appVersion;
  private readonly REMOTE_CONFIG_MAINTENANCE_KEY = RemoteConfigKeys.maintenanceMode;

  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly injector = inject(Injector);
  private readonly router = inject(Router);
  private readonly document = inject(DOCUMENT);

  private remoteConfig: RemoteConfig | null = null;

  fetchVersionEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RemoteConfigActions.fetchRemoteConfigStart),
      switchMap(() => {
        if (!this.remoteConfig) {
          return from(isSupported());
        } else {
          return of(true);
        }
      }),
      switchMap((isSupported) => {
        if (!isSupported) {
          return of(RemoteConfigActions.fetchRemoteConfigError());
        }
        if (!this.remoteConfig) {
          this.remoteConfig = this.injector.get(RemoteConfig);
        }

        return from(fetchAndActivate(this.remoteConfig)).pipe(
          concatLatestFrom(() => this.store.select(selectInitialAppVersion)),
          map(([, intialAppVersion]) => {
            const fullRemoteConfig = getAll(this.remoteConfig!);
            const currentVersion = fullRemoteConfig[this.REMOTE_CONFIG_APP_VERSION_KEY].asString();
            const maintenanceMode = fullRemoteConfig[this.REMOTE_CONFIG_MAINTENANCE_KEY].asBoolean();

            if (intialAppVersion && currentVersion !== intialAppVersion) {
              console.log('App version changed', currentVersion);
              console.log('Reload the app to apply the changes');
              this.document.defaultView?.location.reload();
            }

            if (maintenanceMode) {
              this.router.navigate([`/${clientRoutes.MAINTENANCE}`]);
            }

            return RemoteConfigActions.fetchRemoteConfigSuccess({ currentRemoteConfig: fullRemoteConfig });
          }),
          catchError((error) => {
            console.error('Error fetching app version', error);
            return of(RemoteConfigActions.fetchRemoteConfigError());
          }),
        );
      }),
    );
  });

  onVisibilityChangeEvent = createEffect(() => {
    return fromEvent(this.document, 'visibilitychange').pipe(
      filter(() => this.document.visibilityState === 'visible'),
      map(() => RemoteConfigActions.fetchRemoteConfigStart()),
    );
  });
}
