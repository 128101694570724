import { Store } from '@ngrx/store';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  AsyncMultiplayerUser,
  FootballPlayer,
  GameTopscorerQuestionOption,
  VipAsyncMultiplayerGame,
  VipAsyncMultiplayerViewModel,
  VipUserQuizduelGameUserView,
} from '@kiq/shared/interfaces';
import {
  selectCurrentAsyncState,
  selectCurrentGame,
  selectCurrentOpponent,
  selectCurrentVipUserGame,
  selectGameAndInstanceLoading,
  selectGameLoading,
  selectHasActiveGameInstance,
  selectHeadlinerVipGames,
  selectIntervalRunning,
  selectMyVisibleGameInstances,
  selectOverview,
  selectStepLoading,
  selectTimeLeftQuestion,
  selectTimeLeftQuestionInPercent,
  selectVipViewModel,
} from './vip-async-multiplayer.reducer';
import { VipAsyncMultiplayerActions } from './vip-async-multiplayer.actions';
import { AsyncMultiplayerFacade } from '../../interfaces/async-multiplayer-facade';
import { AsyncMultiplayerType, AsyncState } from '@kiq/shared/enums';

@Injectable({ providedIn: 'root' })
export class VipAsyncMultiplayerFacade implements AsyncMultiplayerFacade<VipAsyncMultiplayerGame> {
  private readonly store = inject(Store);

  readonly currentGame$: Observable<VipAsyncMultiplayerGame | null> = this.store.select(selectCurrentGame);
  readonly currentVipUserGame$: Observable<VipUserQuizduelGameUserView | null> =
    this.store.select(selectCurrentVipUserGame);
  readonly intervalRunning$: Observable<boolean> = this.store.select(selectIntervalRunning);
  readonly timeLeftQuestion$: Observable<number | null> = this.store.select(selectTimeLeftQuestion);
  readonly timeLeftQuestionInPercent$: Observable<number | null> = this.store.select(selectTimeLeftQuestionInPercent);
  readonly headlinerVipGames$: Observable<VipUserQuizduelGameUserView[] | null> =
    this.store.select(selectHeadlinerVipGames);
  readonly myVisibleVipGames$: Observable<VipAsyncMultiplayerGame[] | null> =
    this.store.select(selectMyVisibleGameInstances);
  readonly availableGames$: Observable<VipUserQuizduelGameUserView[] | null> = this.store
    .select(selectOverview)
    .pipe(map((overview) => overview?.availableGames ?? null));
  readonly vm$: Observable<VipAsyncMultiplayerViewModel> = this.store.select(selectVipViewModel);
  readonly currentOpponent$: Observable<AsyncMultiplayerUser | null> = this.store.select(selectCurrentOpponent);
  readonly gameLoading$: Observable<boolean> = this.store.select(selectGameLoading);
  readonly stepLoading$: Observable<boolean> = this.store.select(selectStepLoading);
  readonly hasActiveGameInstance$: Observable<boolean | null> = this.store.select(selectHasActiveGameInstance);
  readonly currentAsyncState$: Observable<AsyncState | null> = this.store.select(selectCurrentAsyncState);
  readonly asyncMultiplayerType = AsyncMultiplayerType.vip;
  readonly gameAndInstanceLoading$: Observable<boolean> = this.store.select(selectGameAndInstanceLoading);

  getVipGamesOverview() {
    this.store.dispatch(VipAsyncMultiplayerActions.getVipGamesOverview());
  }

  getVipUserGame(vipUserQuizduelGameId: string) {
    this.store.dispatch(VipAsyncMultiplayerActions.getVipUserGame({ vipUserQuizduelGameId }));
  }

  getInstanceForVipUserGame(vipUserQuizduelGameId: string) {
    this.store.dispatch(VipAsyncMultiplayerActions.getVipUserGameInstanceForVipUserGame({ vipUserQuizduelGameId }));
  }

  createNewInstanceForVipUserGame(vipUserQuizduelGameId: string) {
    this.store.dispatch(VipAsyncMultiplayerActions.createVipUserGameInstance({ vipUserQuizduelGameId }));
  }

  getNewQuestion() {
    this.store.dispatch(VipAsyncMultiplayerActions.getNextQuestion());
  }

  answerQuestion(footballPlayer?: FootballPlayer, topscorerAnswer?: GameTopscorerQuestionOption) {
    this.store.dispatch(VipAsyncMultiplayerActions.answerQuestion({ footballPlayer, topscorerAnswer }));
  }

  selectCategory(categoryId: string) {
    this.store.dispatch(VipAsyncMultiplayerActions.selectCategory({ categoryId }));
  }

  closeCurrentGame() {
    this.store.dispatch(VipAsyncMultiplayerActions.closeCurrentGame());
  }
}
