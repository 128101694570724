import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  AsyncGameRoundCategory,
  KikkzError,
  VipAsyncMultiplayerGame,
  VipUserQuizduelGameOverview,
  VipUserQuizduelGameUserView,
} from '@kiq/shared/interfaces';
import {
  createAnswerQuestionActions,
  createGetNextQuestionActions,
  createSelectNextCategoryActions,
  createSetCurrentCategoryActions,
  setShownOpponentScoreActions,
} from '../../helper/shared-actions.helpers';
import {
  createCloseCurrentGameAction,
  createIntervalActions,
  createRefreshGameStateOnFocusActions,
  createShowLoaderActions,
  createTimeTickActions,
} from '@kiq/shared/util/helper';
import { AsyncState } from '@kiq/shared/enums';

export const VipAsyncMultiplayerActions = createActionGroup({
  source: 'VipAsyncMultiplayer',
  events: {
    ...createGetNextQuestionActions<VipAsyncMultiplayerGame>(),
    ...createSelectNextCategoryActions<VipAsyncMultiplayerGame>(),
    ...createAnswerQuestionActions<VipAsyncMultiplayerGame>(),
    ...createIntervalActions<VipAsyncMultiplayerGame>(),
    ...createTimeTickActions(),
    ...createShowLoaderActions(),
    ...createSetCurrentCategoryActions<AsyncGameRoundCategory>(),
    ...createRefreshGameStateOnFocusActions(),
    ...createCloseCurrentGameAction(),
    ...setShownOpponentScoreActions(),

    'show instance loader': emptyProps(),

    'get vip games overview': emptyProps(),
    'get vip games overview success': props<{ response: VipUserQuizduelGameOverview }>(),
    'get vip games overview failure': props<{ error?: KikkzError }>(),

    'get vip user game': props<{ vipUserQuizduelGameId: string }>(),
    'get vip user game success': props<{ response: VipUserQuizduelGameUserView }>(),
    'get vip user game failure': props<{ error?: KikkzError }>(),

    'get vip user game instance for vip user game': props<{ vipUserQuizduelGameId: string }>(),
    'get vip user game instance for vip user game success': props<{ response: VipAsyncMultiplayerGame }>(),
    'get vip user game instance for vip user game failure': props<{ error?: KikkzError }>(),

    'create vip user game instance': props<{ vipUserQuizduelGameId: string }>(),
    'create vip user game instance success': props<{ response: VipAsyncMultiplayerGame }>(),
    'create vip user game instance failure': props<{ error?: KikkzError }>(),

    'get vip user game instance by id': props<{ vipUserQuizduelGameId: string }>(),

    'set current async state': props<{ currentAsyncState: AsyncState | null }>(),
  },
});
