import { CommonAsyncGameState } from '../interfaces/common-async-game-state';

export const createInitialCommonAsyncGameState = <TGame>(): CommonAsyncGameState<TGame> => ({
  gameLoading: false,
  stepLoading: false,
  isNextQuestionLoading: false,
  allGamesLoading: false,
  error: null,
  timeLeftQuestion: null,
  timeLeftQuestionInPercent: null,
  currentGame: null,
  shownOpponentScore: null,
  intervalRunning: false,
  userPlayerNumber: null,
  opponentPlayerNumber: null,
  currentCategory: null,
  userGamePoints: null,
  opponentGamePoints: null,
  currentOpponent: null,
});
