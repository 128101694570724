import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'shared-trophy-icon',
    templateUrl: './trophy-icon.component.html',
    styleUrl: './trophy-icon.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TrophyIconComponent implements OnChanges {
  @Input() name: string | undefined;

  iconPath?: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['name'] || changes?.['type']) {
      this.iconPath = `url('/assets/icons/${this.name}.svg')`;
    }
  }
}
