import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BACKEND_TOKEN } from '@kiq/client/util/config';
import { map, Observable } from 'rxjs';
import { BasicUserView, UserOwnerView } from '@kiq/shared/classes';
import { AsyncAction, GamesOverview, RegularAsyncMultiplayerGame } from '@kiq/shared/interfaces';
import { serializePayload } from '@kiq/shared/util/helper';

@Injectable()
export class RegularAsyncMultiplayerService {
  private readonly http = inject(HttpClient);
  private readonly BACKEND_BASE_URL = inject(BACKEND_TOKEN);
  private readonly BACKEND_URL = `${this.BACKEND_BASE_URL}/api/v1`;
  private readonly BACKEND_URL_GAME = '/game/quizduel';
  private readonly BACKEND_USER_FROM_INVITATION_LINK = '/user/gameInvitationLinkId';
  private readonly INVITATION_LINK = '/gameInvitationLinkId';
  private readonly INVITE_URL = '/invite';
  private readonly HIDE_WHEN_ENDED_URL = '/hideAllEndedGames';
  private readonly ACTION_URL = '/action';
  private readonly CHALLENGE_URL = `/challengeUser`;
  private readonly CREATE_CHALLENGE_FROM_GAME_URL = '/createChallengeFromGame';
  private readonly VIP_GAME_PATH = '/vipUser/game';

  getAllAsyncMultiplayerGames(): Observable<GamesOverview<RegularAsyncMultiplayerGame>> {
    const url = this.BACKEND_URL + this.BACKEND_URL_GAME;
    return this.http.get<GamesOverview<RegularAsyncMultiplayerGame>>(url).pipe(
      map((allAsyncGames) => {
        allAsyncGames.endedGames?.forEach((endedGame) => {
          return this.getGameWithMaps(endedGame);
        });

        allAsyncGames.runningGames?.forEach((openGame) => {
          return this.getGameWithMaps(openGame);
        });

        allAsyncGames.pendingGames?.forEach((pendingGame) => {
          return this.getGameWithMaps(pendingGame);
        });
        return allAsyncGames;
      }),
    );
  }

  getAsyncMultiplayerGame(gameId: string): Observable<RegularAsyncMultiplayerGame> {
    const url = this.BACKEND_URL + this.BACKEND_URL_GAME + '/' + gameId;
    return this.http.get<RegularAsyncMultiplayerGame>(url).pipe(
      map((asyncGame) => {
        return this.getGameWithMaps(asyncGame);
      }),
    );
  }

  createOrGetGameFromInviteLink(invitationLinkId: string): Observable<RegularAsyncMultiplayerGame> {
    const url = this.BACKEND_URL + this.BACKEND_URL_GAME + this.INVITE_URL + '/' + invitationLinkId;
    return this.http.post<RegularAsyncMultiplayerGame>(url, {}).pipe(
      map((asyncGame) => {
        return this.getGameWithMaps(asyncGame);
      }),
    );
  }

  getOpponentFromInvitationLink(invitationLinkId: string): Observable<UserOwnerView | BasicUserView> {
    const url = this.BACKEND_URL + this.BACKEND_USER_FROM_INVITATION_LINK + '/' + invitationLinkId;
    return this.http.get<UserOwnerView | BasicUserView>(url);
  }

  sendAction(gameId: string, asyncAction: AsyncAction): Observable<RegularAsyncMultiplayerGame> {
    const url = this.BACKEND_URL + this.BACKEND_URL_GAME + '/' + gameId + this.ACTION_URL;
    return this.http.post<RegularAsyncMultiplayerGame>(url, serializePayload(asyncAction)).pipe(
      map((asyncGame) => {
        return this.getGameWithMaps(asyncGame);
      }),
    );
  }

  hideWhenEnded() {
    const url = this.BACKEND_URL + this.BACKEND_URL_GAME + this.HIDE_WHEN_ENDED_URL;
    return this.http.post<void>(url, {});
  }

  challengeUser(userId: string): Observable<RegularAsyncMultiplayerGame> {
    const url = this.BACKEND_URL + this.BACKEND_URL_GAME + this.CHALLENGE_URL + '/' + userId;
    return this.http.post<RegularAsyncMultiplayerGame>(url, {}).pipe(
      map((asyncGame) => {
        return this.getGameWithMaps(asyncGame);
      }),
    );
  }

  challengeUserAfterPreviousGame(previousGameId: string): Observable<RegularAsyncMultiplayerGame> {
    const url = this.BACKEND_URL + this.BACKEND_URL_GAME + this.CREATE_CHALLENGE_FROM_GAME_URL + '/' + previousGameId;
    return this.http.post<RegularAsyncMultiplayerGame>(url, {}).pipe(
      map((asyncGame) => {
        return this.getGameWithMaps(asyncGame);
      }),
    );
  }

  private getGameWithMaps(asyncGame: RegularAsyncMultiplayerGame) {
    const playerIds = new Map();
    if (asyncGame.playerIds) {
      for (const [key, value] of Object.entries(asyncGame.playerIds)) {
        playerIds.set(key, value);
      }
      asyncGame.playerIds = playerIds;
    }
    const playerScores = new Map();
    if (asyncGame.playerScores) {
      for (const [key, value] of Object.entries(asyncGame.playerScores)) {
        playerScores.set(key, value);
      }
      asyncGame.playerScores = playerScores;
    }

    const gamePoints = new Map();
    if (asyncGame.gamePoints) {
      for (const [key, value] of Object.entries(asyncGame.gamePoints)) {
        gamePoints.set(key, value);
      }
      asyncGame.gamePoints = gamePoints;
    }

    if (asyncGame.currentRound) {
      const playerAnswers = new Map();
      if (asyncGame.currentRound?.playerAnswers) {
        for (const [key, value] of Object.entries(asyncGame.currentRound?.playerAnswers)) {
          playerAnswers.set(key, value);
        }
        asyncGame.currentRound.playerAnswers = playerAnswers;
      }
    }

    if (asyncGame.playedRounds) {
      asyncGame.playedRounds.forEach((playedRound) => {
        const playerAnswers = new Map();
        if (playedRound.playerAnswers) {
          for (const [key, value] of Object.entries(playedRound.playerAnswers)) {
            playerAnswers.set(key, value);
          }
        }
        playedRound.playerAnswers = playerAnswers;
      });
    }
    return asyncGame;
  }
}
