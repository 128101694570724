import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {regularAsyncMultiplayerFeature} from './+state/regular-async-multiplayer.reducer';
import {RegularAsyncMultiplayerEffects} from './+state/regular-async-multiplayer.effects';
import {RegularAsyncMultiplayerFacade} from './+state/regular-async-multiplayer.facade';
import {RegularAsyncMultiplayerService} from '../service/regular-async-multiplayer.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(regularAsyncMultiplayerFeature),
    EffectsModule.forFeature([RegularAsyncMultiplayerEffects]),
  ],
  providers: [
    RegularAsyncMultiplayerFacade,
    RegularAsyncMultiplayerService,
  ],
})
export class ClientDataAccessRegularAsyncMultiplayerDataModule {}
