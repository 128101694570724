import { createFeature, createReducer } from '@ngrx/store';
import { TrophyActions } from './trophy.actions';
import { immerOn } from 'ngrx-immer/store';
import { UserTrophyStatus } from '@kiq/shared/enums';
import { Trophy } from '@kiq/shared/interfaces';

export interface TrophyState {
  loading: boolean;
  trophies: Array<Trophy> | null;
  trophyCounter: number | null;
  trophyCounterNotification: number | null;
  isTrophyCounterNotification: boolean;
}

export const initialTrophyState: TrophyState = {
  loading: false,
  trophies: null,
  trophyCounter: null,
  trophyCounterNotification: null,
  isTrophyCounterNotification: false,
};
export const trophyFeature = createFeature({
  name: 'trophy',
  reducer: createReducer(
    initialTrophyState,

    immerOn(TrophyActions.getTrophiesStart, (state) => {
      state.loading = true;
    }),
    immerOn(TrophyActions.getTrophiesSuccess, (state, { trophies }) => {
      state.loading = false;
      state.trophies = trophies;
    }),
    immerOn(TrophyActions.getTrophiesFail, (state) => {
      state.loading = false;
    }),
    immerOn(TrophyActions.getTrophyCountForUser, (state) => {
      state.loading = true;
    }),
    immerOn(TrophyActions.getTrophyCountForUserSuccess, (state, { trophies }) => {
      state.loading = false;

      let trophyCounter = 0;
      trophies?.forEach((trophy) => {
        if (trophy.receivedUpdates?.at(-1)?.status && trophy.receivedUpdates?.at(-1)?.earnedAt) {
          if (trophy.isUpgradable && trophy.earnedAt) {
            trophyCounter++;
          }
          switch (trophy.receivedUpdates?.at(-1)?.status) {
            case UserTrophyStatus.BRONZE:
              trophyCounter++;
              break;
            case UserTrophyStatus.SILVER:
              trophyCounter += 2;
              break;
            case UserTrophyStatus.GOLD:
              trophyCounter += 3;
              break;
            case UserTrophyStatus.PLATINUM:
              trophyCounter += 4;
              break;
          }
        } else {
          if (!trophy.isUpgradable && trophy.earnedAt) {
            trophyCounter++;
          }
        }
      });

      state.trophyCounter = trophyCounter;
    }),
    immerOn(TrophyActions.getTrophyCountForUserFail, (state) => {
      state.loading = false;
    }),

    immerOn(TrophyActions.setTrophyCountNotification, (state) => {
      state.loading = true;
    }),

    immerOn(TrophyActions.setTrophyCountNotificationSuccess, (state, { trophyCounterNotification }) => {
      state.loading = false;
      state.trophyCounterNotification = trophyCounterNotification;
      state.isTrophyCounterNotification = false;
    }),

    immerOn(TrophyActions.retrieveLastTrophyCount, (state) => {
      state.loading = true;
    }),
    immerOn(TrophyActions.retrieveLastTrophyCountSuccess, (state, { trophyCounterNotification }) => {
      state.loading = false;
      state.trophyCounterNotification = trophyCounterNotification;
      if ((trophyCounterNotification ?? 0) < (state.trophyCounter ?? 0)) {
        state.isTrophyCounterNotification = true;
      }
    }),
    immerOn(TrophyActions.retrieveLastTrophyCountFail, (state) => {
      state.loading = false;
    }),
  ),
});
export const {
  name, // feature name
  reducer, // feature reducer
  selectLoading,
  selectTrophies,
  selectTrophyCounter,
  selectTrophyCounterNotification,
  selectIsTrophyCounterNotification,
} = trophyFeature;
