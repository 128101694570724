// Auto-generated file. Do not edit manually.

import { AdvertisementPlace, AdvertisementType, ViewSize } from '@kiq/shared/enums';
import { SelfAdvertisementAsset } from '@kiq/shared/interfaces';

export const generalAdvertisementAssets: Map<AdvertisementPlace, SelfAdvertisementAsset[]> = new Map([
  
      ["gamesOverview" as AdvertisementPlace, [
  {
    "id": "1-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 1,
    "viewports": {
      "mobile": {
        "title": "Der beste Zehner aller Zeiten 💶",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Der beste Zehner aller Zeiten 💶",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Der beste Zehner aller Zeiten 💶",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "2-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 2,
    "viewports": {
      "mobile": {
        "title": "Geld schießt keine Tore, aber hält unsere Server am laufen 💸",
        "titleFontSize": 15,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Geld schießt keine Tore, aber hält unsere Server am laufen 💸",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Geld schießt keine Tore, aber hält unsere Server am laufen 💸",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "3-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 3,
    "viewports": {
      "mobile": {
        "title": "Folge KIKKZ auf Instagram 🤝🏼",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ folgen 🚀",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Folge KIKKZ auf Instagram 🤝🏼",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ folgen 🚀",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Folge KIKKZ auf Instagram 🤝🏼",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ folgen 🚀",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "4-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 4,
    "viewports": {
      "mobile": {
        "title": "Folge KIKKZ auf TikTok 🤝🏼",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ folgen 🚀",
        "link": "https://www.tiktok.com/@kikkz.de",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Folge KIKKZ auf TikTok 🤝🏼",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ folgen 🚀",
        "link": "https://www.tiktok.com/@kikkz.de",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Folge KIKKZ auf TikTok 🤝🏼",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ folgen 🚀",
        "link": "https://www.tiktok.com/@kikkz.de",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "5-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 5,
    "viewports": {
      "mobile": {
        "title": "11Freunde müsst ihr sein. Und allen musst du von KIKKZ erzählen 🗣️",
        "titleFontSize": 15,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "tablet": {
        "title": "11Freunde müsst ihr sein. Und allen musst du von KIKKZ erzählen 🗣️",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "desktop": {
        "title": "11Freunde müsst ihr sein. Und allen musst du von KIKKZ erzählen 🗣️",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "6-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 6,
    "viewports": {
      "mobile": {
        "title": "Gib uns eine Stadionwurst aus 🌭",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Gib uns eine Stadionwurst aus 🌭",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Gib uns eine Stadionwurst aus 🌭",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "7-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 7,
    "viewports": {
      "mobile": {
        "title": "Endlich wieder Montagsspiele ⚽️",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Endlich wieder Montagsspiele ⚽️",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Endlich wieder Montagsspiele ⚽️",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "8-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 8,
    "viewports": {
      "mobile": {
        "title": "Bildung durch Fußball. Graugänse kennen wir erst seit Katar 22 🧑🏼‍🏫",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Bildung durch Fußball. Graugänse kennen wir erst seit Katar 22 🧑🏼‍🏫",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Bildung durch Fußball. Graugänse kennen wir erst seit Katar 22 🧑🏼‍🏫",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "9-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 9,
    "viewports": {
      "mobile": {
        "title": "Du hast bessere Ideen als wir? Her damit! 💡",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Du hast bessere Ideen als wir? Her damit! 💡",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Du hast bessere Ideen als wir? Her damit! 💡",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "10-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 10,
    "viewports": {
      "mobile": {
        "title": "Keine Kapriolen Rüdiger! ✈️",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Keine Kapriolen Rüdiger! ✈️",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Keine Kapriolen Rüdiger! ✈️",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "11-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 11,
    "viewports": {
      "mobile": {
        "title": "Spielmacher gesucht - bist du dabei? 🔟",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Spielmacher gesucht - bist du dabei? 🔟",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Spielmacher gesucht - bist du dabei? 🔟",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "12-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 12,
    "viewports": {
      "mobile": {
        "title": "Teile dein Fußballwissen 🧠",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Teile dein Fußballwissen 🧠",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Teile dein Fußballwissen 🧠",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "13-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 13,
    "viewports": {
      "mobile": {
        "title": "Nicht nur mitraten, auch mithelfen 🦸🏽‍♀️",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Nicht nur mitraten, auch mithelfen 🦸🏽‍♀️",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Nicht nur mitraten, auch mithelfen 🦸🏽‍♀️",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "14-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 14,
    "viewports": {
      "mobile": {
        "title": "Ohne dich spielen wir nur zu zehnt 😢",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Ohne dich spielen wir nur zu zehnt 😢",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Ohne dich spielen wir nur zu zehnt 😢",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "15-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 15,
    "viewports": {
      "mobile": {
        "title": "Das Runde muss ins Eckige…🥅",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Das Runde muss ins Eckige…🥅",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Das Runde muss ins Eckige…🥅",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "16-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 16,
    "viewports": {
      "mobile": {
        "title": "„Geht´s raus und spielt´s Fußball!“ 🐐",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "„Geht´s raus und spielt´s Fußball!“ 🐐",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "„Geht´s raus und spielt´s Fußball!“ 🐐",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "17-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 17,
    "viewports": {
      "mobile": {
        "title": "„Fußball ist wie Schach, nur ohne Würfel“ 🎲",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "„Fußball ist wie Schach, nur ohne Würfel“ 🎲",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "„Fußball ist wie Schach, nur ohne Würfel“ 🎲",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "18-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 18,
    "viewports": {
      "mobile": {
        "title": "„Wir hatten die Hosen voll, aber bei mir liefs ganz flüssig“ 💩",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "„Wir hatten die Hosen voll, aber bei mir liefs ganz flüssig“ 💩",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "„Wir hatten die Hosen voll, aber bei mir liefs ganz flüssig“ 💩",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "19-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 19,
    "viewports": {
      "mobile": {
        "title": "„Ein Tor würde dem Spiel gut tun“ ⚽️",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "„Ein Tor würde dem Spiel gut tun“ ⚽️",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "„Ein Tor würde dem Spiel gut tun“ ⚽️",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "20-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 20,
    "viewports": {
      "mobile": {
        "title": "„Man darf jetzt nicht alles so schlecht reden, wie es war“ 😵‍💫",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "„Man darf jetzt nicht alles so schlecht reden, wie es war“ 😵‍💫",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "„Man darf jetzt nicht alles so schlecht reden, wie es war“ 😵‍💫",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "21-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 21,
    "viewports": {
      "mobile": {
        "title": "„Geht´s raus und spielt´s Fußball!“ 🐐",
        "titleFontSize": 15,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "tablet": {
        "title": "„Geht´s raus und spielt´s Fußball!“ 🐐",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "desktop": {
        "title": "„Geht´s raus und spielt´s Fußball!“ 🐐",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "22-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 22,
    "viewports": {
      "mobile": {
        "title": "„Ein Spiel dauert 90 Minuten“ ⏱️",
        "titleFontSize": 15,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "tablet": {
        "title": "„Ein Spiel dauert 90 Minuten“ ⏱️",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "desktop": {
        "title": "„Ein Spiel dauert 90 Minuten“ ⏱️",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "23-gamesOverview",
    "placement": "gamesOverview",
    "advertisementId": 23,
    "viewports": {
      "mobile": {
        "title": "Erzähle deinen Freunden von KIKKZ 🤙🏼",
        "titleFontSize": 15,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://play.kikkz.de/game",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Erzähle deinen Freunden von KIKKZ 🤙🏼",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://play.kikkz.de/game",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Erzähle deinen Freunden von KIKKZ 🤙🏼",
        "titleFontSize": 18,
        "subTitle": "",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://play.kikkz.de/game",
        "canBeCopied": false
      }
    }
  }
] as SelfAdvertisementAsset[]]
    ,

      ["ranking" as AdvertisementPlace, [
  {
    "id": "1-ranking",
    "placement": "ranking",
    "advertisementId": 1,
    "viewports": {
      "mobile": {
        "title": "Der beste Zehner aller Zeiten 💶",
        "titleFontSize": 18,
        "subTitle": "Es muss kein Zehner sein. Wir freuen uns über jeden Support 🤝🏼",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Der beste Zehner aller Zeiten 💶",
        "titleFontSize": 18,
        "subTitle": "Es muss kein Zehner sein. Wir freuen uns über jeden Support 🤝🏼",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Der beste Zehner aller Zeiten 💶",
        "titleFontSize": 18,
        "subTitle": "Es muss kein Zehner sein. Wir freuen uns über jeden Support 🤝🏼",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "2-ranking",
    "placement": "ranking",
    "advertisementId": 2,
    "viewports": {
      "mobile": {
        "title": "Geld schießt keine Tore, aber hält unsere Server am laufen 💸",
        "titleFontSize": 18,
        "subTitle": "Wir freuen uns über jeden Support 🤝🏼",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Geld schießt keine Tore, aber hält unsere Server am laufen 💸",
        "titleFontSize": 18,
        "subTitle": "Wir freuen uns über jeden Support 🤝🏼",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Geld schießt keine Tore, aber hält unsere Server am laufen 💸",
        "titleFontSize": 18,
        "subTitle": "Wir freuen uns über jeden Support 🤝🏼",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "3-ranking",
    "placement": "ranking",
    "advertisementId": 3,
    "viewports": {
      "mobile": {
        "title": "Folge KIKKZ auf Instagram 🤝🏼",
        "titleFontSize": 18,
        "subTitle": "Für Updates, Ankündigungen und eine exklusiven Frage des Tages ⚽️❓",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ folgen 🚀",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Folge KIKKZ auf Instagram 🤝🏼",
        "titleFontSize": 18,
        "subTitle": "Für Updates, Ankündigungen und eine exklusiven Frage des Tages ⚽️❓",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ folgen 🚀",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Folge KIKKZ auf Instagram 🤝🏼",
        "titleFontSize": 18,
        "subTitle": "Für Updates, Ankündigungen und eine exklusiven Frage des Tages ⚽️❓",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ folgen 🚀",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "4-ranking",
    "placement": "ranking",
    "advertisementId": 4,
    "viewports": {
      "mobile": {
        "title": "Folge KIKKZ auf TikTok 🤝🏼",
        "titleFontSize": 18,
        "subTitle": "Für Updates, Ankündigungen und eine exklusiven Frage des Tages ⚽️❓",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ folgen 🚀",
        "link": "https://www.tiktok.com/@kikkz.de",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Folge KIKKZ auf TikTok 🤝🏼",
        "titleFontSize": 18,
        "subTitle": "Für Updates, Ankündigungen und eine exklusiven Frage des Tages ⚽️❓",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ folgen 🚀",
        "link": "https://www.tiktok.com/@kikkz.de",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Folge KIKKZ auf TikTok 🤝🏼",
        "titleFontSize": 18,
        "subTitle": "Für Updates, Ankündigungen und eine exklusiven Frage des Tages ⚽️❓",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ folgen 🚀",
        "link": "https://www.tiktok.com/@kikkz.de",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "5-ranking",
    "placement": "ranking",
    "advertisementId": 5,
    "viewports": {
      "mobile": {
        "title": "11Freunde müsst ihr sein. Und allen musst du von KIKKZ erzählen 🗣️",
        "titleFontSize": 18,
        "subTitle": "Teile das Reel in deiner Story! 📱",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "tablet": {
        "title": "11Freunde müsst ihr sein. Und allen musst du von KIKKZ erzählen 🗣️",
        "titleFontSize": 18,
        "subTitle": "Teile das Reel in deiner Story! 📱",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "desktop": {
        "title": "11Freunde müsst ihr sein. Und allen musst du von KIKKZ erzählen 🗣️",
        "titleFontSize": 18,
        "subTitle": "Teile das Reel in deiner Story! 📱",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "6-ranking",
    "placement": "ranking",
    "advertisementId": 6,
    "viewports": {
      "mobile": {
        "title": "Gib uns eine Stadionwurst aus 🌭",
        "titleFontSize": 18,
        "subTitle": "Wir freuen uns über jeden Support 🤝🏼",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Gib uns eine Stadionwurst aus 🌭",
        "titleFontSize": 18,
        "subTitle": "Wir freuen uns über jeden Support 🤝🏼",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Gib uns eine Stadionwurst aus 🌭",
        "titleFontSize": 18,
        "subTitle": "Wir freuen uns über jeden Support 🤝🏼",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "7-ranking",
    "placement": "ranking",
    "advertisementId": 7,
    "viewports": {
      "mobile": {
        "title": "Endlich wieder Montagsspiele ⚽️",
        "titleFontSize": 18,
        "subTitle": "Teile das Reel in deiner Story! 📱",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Endlich wieder Montagsspiele ⚽️",
        "titleFontSize": 18,
        "subTitle": "Teile das Reel in deiner Story! 📱",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Endlich wieder Montagsspiele ⚽️",
        "titleFontSize": 18,
        "subTitle": "Teile das Reel in deiner Story! 📱",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "8-ranking",
    "placement": "ranking",
    "advertisementId": 8,
    "viewports": {
      "mobile": {
        "title": "Bildung durch Fußball. Graugänse kennen wir erst seit Katar 22 🧑🏼‍🏫",
        "titleFontSize": 18,
        "subTitle": "Teile das Reel in deiner Story! 📱",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Bildung durch Fußball. Graugänse kennen wir erst seit Katar 22 🧑🏼‍🏫",
        "titleFontSize": 18,
        "subTitle": "Teile das Reel in deiner Story! 📱",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Bildung durch Fußball. Graugänse kennen wir erst seit Katar 22 🧑🏼‍🏫",
        "titleFontSize": 18,
        "subTitle": "Teile das Reel in deiner Story! 📱",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "9-ranking",
    "placement": "ranking",
    "advertisementId": 9,
    "viewports": {
      "mobile": {
        "title": "Du hast bessere Ideen als wir? Her damit! 💡",
        "titleFontSize": 18,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Du hast bessere Ideen als wir? Her damit! 💡",
        "titleFontSize": 18,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Du hast bessere Ideen als wir? Her damit! 💡",
        "titleFontSize": 18,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "10-ranking",
    "placement": "ranking",
    "advertisementId": 10,
    "viewports": {
      "mobile": {
        "title": "Keine Kapriolen Rüdiger! ✈️",
        "titleFontSize": 18,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Keine Kapriolen Rüdiger! ✈️",
        "titleFontSize": 18,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Keine Kapriolen Rüdiger! ✈️",
        "titleFontSize": 18,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "11-ranking",
    "placement": "ranking",
    "advertisementId": 11,
    "viewports": {
      "mobile": {
        "title": "Spielmacher gesucht - bist du dabei? 🔟",
        "titleFontSize": 18,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Spielmacher gesucht - bist du dabei? 🔟",
        "titleFontSize": 18,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Spielmacher gesucht - bist du dabei? 🔟",
        "titleFontSize": 18,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "12-ranking",
    "placement": "ranking",
    "advertisementId": 12,
    "viewports": {
      "mobile": {
        "title": "Teile dein Fußballwissen 🧠",
        "titleFontSize": 18,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Teile dein Fußballwissen 🧠",
        "titleFontSize": 18,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Teile dein Fußballwissen 🧠",
        "titleFontSize": 18,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "13-ranking",
    "placement": "ranking",
    "advertisementId": 13,
    "viewports": {
      "mobile": {
        "title": "Nicht nur mitraten, auch mithelfen 🦸🏽‍♀️",
        "titleFontSize": 18,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Nicht nur mitraten, auch mithelfen 🦸🏽‍♀️",
        "titleFontSize": 18,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Nicht nur mitraten, auch mithelfen 🦸🏽‍♀️",
        "titleFontSize": 18,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "14-ranking",
    "placement": "ranking",
    "advertisementId": 14,
    "viewports": {
      "mobile": {
        "title": "Ohne dich spielen wir nur zu zehnt 😢",
        "titleFontSize": 18,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Ohne dich spielen wir nur zu zehnt 😢",
        "titleFontSize": 18,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Ohne dich spielen wir nur zu zehnt 😢",
        "titleFontSize": 18,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "15-ranking",
    "placement": "ranking",
    "advertisementId": 15,
    "viewports": {
      "mobile": {
        "title": "Das Runde muss ins Eckige…🥅",
        "titleFontSize": 18,
        "subTitle": "…und KIKKZ in deine Story 📱",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Das Runde muss ins Eckige…🥅",
        "titleFontSize": 18,
        "subTitle": "…und KIKKZ in deine Story 📱",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Das Runde muss ins Eckige…🥅",
        "titleFontSize": 18,
        "subTitle": "…und KIKKZ in deine Story 📱",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "16-ranking",
    "placement": "ranking",
    "advertisementId": 16,
    "viewports": {
      "mobile": {
        "title": "„Geht´s raus und spielt´s Fußball!“ 🐐",
        "titleFontSize": 18,
        "subTitle": "Aber nehmt vorher an der Umfrage teil 🤝🏼",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "„Geht´s raus und spielt´s Fußball!“ 🐐",
        "titleFontSize": 18,
        "subTitle": "Aber nehmt vorher an der Umfrage teil 🤝🏼",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "„Geht´s raus und spielt´s Fußball!“ 🐐",
        "titleFontSize": 18,
        "subTitle": "Aber nehmt vorher an der Umfrage teil 🤝🏼",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "17-ranking",
    "placement": "ranking",
    "advertisementId": 17,
    "viewports": {
      "mobile": {
        "title": "„Fußball ist wie Schach, nur ohne Würfel“ 🎲",
        "titleFontSize": 18,
        "subTitle": "Hier gehts zum Feedback! 💌",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "„Fußball ist wie Schach, nur ohne Würfel“ 🎲",
        "titleFontSize": 18,
        "subTitle": "Hier gehts zum Feedback! 💌",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "„Fußball ist wie Schach, nur ohne Würfel“ 🎲",
        "titleFontSize": 18,
        "subTitle": "Hier gehts zum Feedback! 💌",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "18-ranking",
    "placement": "ranking",
    "advertisementId": 18,
    "viewports": {
      "mobile": {
        "title": "„Wir hatten die Hosen voll, aber bei mir liefs ganz flüssig“ 💩",
        "titleFontSize": 18,
        "subTitle": "Keine Panik - unsere Umfrage ist einfacher 🤓",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "„Wir hatten die Hosen voll, aber bei mir liefs ganz flüssig“ 💩",
        "titleFontSize": 18,
        "subTitle": "Keine Panik - unsere Umfrage ist einfacher 🤓",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "„Wir hatten die Hosen voll, aber bei mir liefs ganz flüssig“ 💩",
        "titleFontSize": 18,
        "subTitle": "Keine Panik - unsere Umfrage ist einfacher 🤓",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "19-ranking",
    "placement": "ranking",
    "advertisementId": 19,
    "viewports": {
      "mobile": {
        "title": "„Ein Tor würde dem Spiel gut tun“ ⚽️",
        "titleFontSize": 18,
        "subTitle": "Eine Antwort in der Umfrage tut´s für den Anfang auch 🤝🏼",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "„Ein Tor würde dem Spiel gut tun“ ⚽️",
        "titleFontSize": 18,
        "subTitle": "Eine Antwort in der Umfrage tut´s für den Anfang auch 🤝🏼",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "„Ein Tor würde dem Spiel gut tun“ ⚽️",
        "titleFontSize": 18,
        "subTitle": "Eine Antwort in der Umfrage tut´s für den Anfang auch 🤝🏼",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "20-ranking",
    "placement": "ranking",
    "advertisementId": 20,
    "viewports": {
      "mobile": {
        "title": "„Man darf jetzt nicht alles so schlecht reden, wie es war“ 😵‍💫",
        "titleFontSize": 18,
        "subTitle": "Aber man darf sagen, was besser geht 🫵🏼",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "„Man darf jetzt nicht alles so schlecht reden, wie es war“ 😵‍💫",
        "titleFontSize": 18,
        "subTitle": "Aber man darf sagen, was besser geht 🫵🏼",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "„Man darf jetzt nicht alles so schlecht reden, wie es war“ 😵‍💫",
        "titleFontSize": 18,
        "subTitle": "Aber man darf sagen, was besser geht 🫵🏼",
        "subTitleFontSize": 12,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "21-ranking",
    "placement": "ranking",
    "advertisementId": 21,
    "viewports": {
      "mobile": {
        "title": "„Geht´s raus und spielt´s Fußball!“ 🐐",
        "titleFontSize": 18,
        "subTitle": "Oder geht´s raus und teilt KIKKZ! 🤝🏼",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "tablet": {
        "title": "„Geht´s raus und spielt´s Fußball!“ 🐐",
        "titleFontSize": 18,
        "subTitle": "Oder geht´s raus und teilt KIKKZ! 🤝🏼",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "desktop": {
        "title": "„Geht´s raus und spielt´s Fußball!“ 🐐",
        "titleFontSize": 18,
        "subTitle": "Oder geht´s raus und teilt KIKKZ! 🤝🏼",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "22-ranking",
    "placement": "ranking",
    "advertisementId": 22,
    "viewports": {
      "mobile": {
        "title": "„Ein Spiel dauert 90 Minuten“ ⏱️",
        "titleFontSize": 18,
        "subTitle": "Eine Story 90 Sekunden 🚀",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "tablet": {
        "title": "„Ein Spiel dauert 90 Minuten“ ⏱️",
        "titleFontSize": 18,
        "subTitle": "Eine Story 90 Sekunden 🚀",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "desktop": {
        "title": "„Ein Spiel dauert 90 Minuten“ ⏱️",
        "titleFontSize": 18,
        "subTitle": "Eine Story 90 Sekunden 🚀",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "23-ranking",
    "placement": "ranking",
    "advertisementId": 23,
    "viewports": {
      "mobile": {
        "title": "Erzähle deinen Freunden von KIKKZ 🤙🏼",
        "titleFontSize": 18,
        "subTitle": "Teile den KIKKZ-Link in Gruppen ❤️‍🔥",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://play.kikkz.de/game",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Erzähle deinen Freunden von KIKKZ 🤙🏼",
        "titleFontSize": 18,
        "subTitle": "Teile den KIKKZ-Link in Gruppen ❤️‍🔥",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://play.kikkz.de/game",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Erzähle deinen Freunden von KIKKZ 🤙🏼",
        "titleFontSize": 18,
        "subTitle": "Teile den KIKKZ-Link in Gruppen ❤️‍🔥",
        "subTitleFontSize": 12,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://play.kikkz.de/game",
        "canBeCopied": false
      }
    }
  }
] as SelfAdvertisementAsset[]]
    ,

      ["inGame" as AdvertisementPlace, [
  {
    "id": "1-inGame",
    "placement": "inGame",
    "advertisementId": 1,
    "viewports": {
      "mobile": {
        "title": "Der beste Zehner aller Zeiten 💶",
        "titleFontSize": 32,
        "subTitle": "Es muss kein Zehner sein. Wir freuen uns über jeden Support 🤝🏼",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Der beste Zehner aller Zeiten 💶",
        "titleFontSize": 32,
        "subTitle": "Es muss kein Zehner sein. Wir freuen uns über jeden Support 🤝🏼",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Der beste Zehner aller Zeiten 💶",
        "titleFontSize": 32,
        "subTitle": "Es muss kein Zehner sein. Wir freuen uns über jeden Support 🤝🏼",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "2-inGame",
    "placement": "inGame",
    "advertisementId": 2,
    "viewports": {
      "mobile": {
        "title": "Geld schießt keine Tore, aber hält unsere Server am laufen 💸",
        "titleFontSize": 32,
        "subTitle": "Wir freuen uns über jeden Support 🤝🏼",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Geld schießt keine Tore, aber hält unsere Server am laufen 💸",
        "titleFontSize": 32,
        "subTitle": "Wir freuen uns über jeden Support 🤝🏼",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Geld schießt keine Tore, aber hält unsere Server am laufen 💸",
        "titleFontSize": 32,
        "subTitle": "Wir freuen uns über jeden Support 🤝🏼",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "3-inGame",
    "placement": "inGame",
    "advertisementId": 3,
    "viewports": {
      "mobile": {
        "title": "Folge KIKKZ auf Instagram 🤝🏼",
        "titleFontSize": 32,
        "subTitle": "Für Updates, Ankündigungen und eine exklusiven Frage des Tages ⚽️❓",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ folgen 🚀",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Folge KIKKZ auf Instagram 🤝🏼",
        "titleFontSize": 32,
        "subTitle": "Für Updates, Ankündigungen und eine exklusiven Frage des Tages ⚽️❓",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ folgen 🚀",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Folge KIKKZ auf Instagram 🤝🏼",
        "titleFontSize": 32,
        "subTitle": "Für Updates, Ankündigungen und eine exklusiven Frage des Tages ⚽️❓",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ folgen 🚀",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "4-inGame",
    "placement": "inGame",
    "advertisementId": 4,
    "viewports": {
      "mobile": {
        "title": "Folge KIKKZ auf TikTok 🤝🏼",
        "titleFontSize": 32,
        "subTitle": "Für Updates, Ankündigungen und eine exklusiven Frage des Tages ⚽️❓",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ folgen 🚀",
        "link": "https://www.tiktok.com/@kikkz.de",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Folge KIKKZ auf TikTok 🤝🏼",
        "titleFontSize": 32,
        "subTitle": "Für Updates, Ankündigungen und eine exklusiven Frage des Tages ⚽️❓",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ folgen 🚀",
        "link": "https://www.tiktok.com/@kikkz.de",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Folge KIKKZ auf TikTok 🤝🏼",
        "titleFontSize": 32,
        "subTitle": "Für Updates, Ankündigungen und eine exklusiven Frage des Tages ⚽️❓",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ folgen 🚀",
        "link": "https://www.tiktok.com/@kikkz.de",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "5-inGame",
    "placement": "inGame",
    "advertisementId": 5,
    "viewports": {
      "mobile": {
        "title": "11Freunde müsst ihr sein. Und allen musst du von KIKKZ erzählen 🗣️",
        "titleFontSize": 32,
        "subTitle": "Teile das Reel in deiner Story! 📱",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "tablet": {
        "title": "11Freunde müsst ihr sein. Und allen musst du von KIKKZ erzählen 🗣️",
        "titleFontSize": 32,
        "subTitle": "Teile das Reel in deiner Story! 📱",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "desktop": {
        "title": "11Freunde müsst ihr sein. Und allen musst du von KIKKZ erzählen 🗣️",
        "titleFontSize": 32,
        "subTitle": "Teile das Reel in deiner Story! 📱",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "6-inGame",
    "placement": "inGame",
    "advertisementId": 6,
    "viewports": {
      "mobile": {
        "title": "Gib uns eine Stadionwurst aus 🌭",
        "titleFontSize": 32,
        "subTitle": "Wir freuen uns über jeden Support 🤝🏼",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Gib uns eine Stadionwurst aus 🌭",
        "titleFontSize": 32,
        "subTitle": "Wir freuen uns über jeden Support 🤝🏼",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Gib uns eine Stadionwurst aus 🌭",
        "titleFontSize": 32,
        "subTitle": "Wir freuen uns über jeden Support 🤝🏼",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ unterstützen 💚",
        "link": "https://www.google.de",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "7-inGame",
    "placement": "inGame",
    "advertisementId": 7,
    "viewports": {
      "mobile": {
        "title": "Endlich wieder Montagsspiele ⚽️",
        "titleFontSize": 32,
        "subTitle": "Teile das Reel in deiner Story! 📱",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Endlich wieder Montagsspiele ⚽️",
        "titleFontSize": 32,
        "subTitle": "Teile das Reel in deiner Story! 📱",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Endlich wieder Montagsspiele ⚽️",
        "titleFontSize": 32,
        "subTitle": "Teile das Reel in deiner Story! 📱",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "8-inGame",
    "placement": "inGame",
    "advertisementId": 8,
    "viewports": {
      "mobile": {
        "title": "Bildung durch Fußball. Graugänse kennen wir erst seit Katar 22 🧑🏼‍🏫",
        "titleFontSize": 32,
        "subTitle": "Teile das Reel in deiner Story! 📱",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Bildung durch Fußball. Graugänse kennen wir erst seit Katar 22 🧑🏼‍🏫",
        "titleFontSize": 32,
        "subTitle": "Teile das Reel in deiner Story! 📱",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Bildung durch Fußball. Graugänse kennen wir erst seit Katar 22 🧑🏼‍🏫",
        "titleFontSize": 32,
        "subTitle": "Teile das Reel in deiner Story! 📱",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "9-inGame",
    "placement": "inGame",
    "advertisementId": 9,
    "viewports": {
      "mobile": {
        "title": "Du hast bessere Ideen als wir? Her damit! 💡",
        "titleFontSize": 32,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Du hast bessere Ideen als wir? Her damit! 💡",
        "titleFontSize": 32,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Du hast bessere Ideen als wir? Her damit! 💡",
        "titleFontSize": 32,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "10-inGame",
    "placement": "inGame",
    "advertisementId": 10,
    "viewports": {
      "mobile": {
        "title": "Keine Kapriolen Rüdiger! ✈️",
        "titleFontSize": 32,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Keine Kapriolen Rüdiger! ✈️",
        "titleFontSize": 32,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Keine Kapriolen Rüdiger! ✈️",
        "titleFontSize": 32,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "11-inGame",
    "placement": "inGame",
    "advertisementId": 11,
    "viewports": {
      "mobile": {
        "title": "Spielmacher gesucht - bist du dabei? 🔟",
        "titleFontSize": 32,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Spielmacher gesucht - bist du dabei? 🔟",
        "titleFontSize": 32,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Spielmacher gesucht - bist du dabei? 🔟",
        "titleFontSize": 32,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "12-inGame",
    "placement": "inGame",
    "advertisementId": 12,
    "viewports": {
      "mobile": {
        "title": "Teile dein Fußballwissen 🧠",
        "titleFontSize": 32,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Teile dein Fußballwissen 🧠",
        "titleFontSize": 32,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Teile dein Fußballwissen 🧠",
        "titleFontSize": 32,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "13-inGame",
    "placement": "inGame",
    "advertisementId": 13,
    "viewports": {
      "mobile": {
        "title": "Nicht nur mitraten, auch mithelfen 🦸🏽‍♀️",
        "titleFontSize": 32,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Nicht nur mitraten, auch mithelfen 🦸🏽‍♀️",
        "titleFontSize": 32,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Nicht nur mitraten, auch mithelfen 🦸🏽‍♀️",
        "titleFontSize": 32,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "14-inGame",
    "placement": "inGame",
    "advertisementId": 14,
    "viewports": {
      "mobile": {
        "title": "Ohne dich spielen wir nur zu zehnt 😢",
        "titleFontSize": 32,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Ohne dich spielen wir nur zu zehnt 😢",
        "titleFontSize": 32,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Ohne dich spielen wir nur zu zehnt 😢",
        "titleFontSize": 32,
        "subTitle": "Nimm an unserer Umfrage teil 🙏🏻",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "15-inGame",
    "placement": "inGame",
    "advertisementId": 15,
    "viewports": {
      "mobile": {
        "title": "Das Runde muss ins Eckige…🥅",
        "titleFontSize": 32,
        "subTitle": "…und KIKKZ in deine Story 📱",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Das Runde muss ins Eckige…🥅",
        "titleFontSize": 32,
        "subTitle": "…und KIKKZ in deine Story 📱",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Das Runde muss ins Eckige…🥅",
        "titleFontSize": 32,
        "subTitle": "…und KIKKZ in deine Story 📱",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "16-inGame",
    "placement": "inGame",
    "advertisementId": 16,
    "viewports": {
      "mobile": {
        "title": "„Geht´s raus und spielt´s Fußball!“ 🐐",
        "titleFontSize": 32,
        "subTitle": "Aber nehmt vorher an der Umfrage teil 🤝🏼",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "„Geht´s raus und spielt´s Fußball!“ 🐐",
        "titleFontSize": 32,
        "subTitle": "Aber nehmt vorher an der Umfrage teil 🤝🏼",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "„Geht´s raus und spielt´s Fußball!“ 🐐",
        "titleFontSize": 32,
        "subTitle": "Aber nehmt vorher an der Umfrage teil 🤝🏼",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "17-inGame",
    "placement": "inGame",
    "advertisementId": 17,
    "viewports": {
      "mobile": {
        "title": "„Fußball ist wie Schach, nur ohne Würfel“ 🎲",
        "titleFontSize": 32,
        "subTitle": "Hier gehts zum Feedback! 💌",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "„Fußball ist wie Schach, nur ohne Würfel“ 🎲",
        "titleFontSize": 32,
        "subTitle": "Hier gehts zum Feedback! 💌",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "„Fußball ist wie Schach, nur ohne Würfel“ 🎲",
        "titleFontSize": 32,
        "subTitle": "Hier gehts zum Feedback! 💌",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "18-inGame",
    "placement": "inGame",
    "advertisementId": 18,
    "viewports": {
      "mobile": {
        "title": "„Wir hatten die Hosen voll, aber bei mir liefs ganz flüssig“ 💩",
        "titleFontSize": 32,
        "subTitle": "Keine Panik - unsere Umfrage ist einfacher 🤓",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "„Wir hatten die Hosen voll, aber bei mir liefs ganz flüssig“ 💩",
        "titleFontSize": 32,
        "subTitle": "Keine Panik - unsere Umfrage ist einfacher 🤓",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "„Wir hatten die Hosen voll, aber bei mir liefs ganz flüssig“ 💩",
        "titleFontSize": 32,
        "subTitle": "Keine Panik - unsere Umfrage ist einfacher 🤓",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "19-inGame",
    "placement": "inGame",
    "advertisementId": 19,
    "viewports": {
      "mobile": {
        "title": "„Ein Tor würde dem Spiel gut tun“ ⚽️",
        "titleFontSize": 32,
        "subTitle": "Eine Antwort in der Umfrage tut´s für den Anfang auch 🤝🏼",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "„Ein Tor würde dem Spiel gut tun“ ⚽️",
        "titleFontSize": 32,
        "subTitle": "Eine Antwort in der Umfrage tut´s für den Anfang auch 🤝🏼",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "„Ein Tor würde dem Spiel gut tun“ ⚽️",
        "titleFontSize": 32,
        "subTitle": "Eine Antwort in der Umfrage tut´s für den Anfang auch 🤝🏼",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "20-inGame",
    "placement": "inGame",
    "advertisementId": 20,
    "viewports": {
      "mobile": {
        "title": "„Man darf jetzt nicht alles so schlecht reden, wie es war“ 😵‍💫",
        "titleFontSize": 32,
        "subTitle": "Aber man darf sagen, was besser geht 🫵🏼",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "tablet": {
        "title": "„Man darf jetzt nicht alles so schlecht reden, wie es war“ 😵‍💫",
        "titleFontSize": 32,
        "subTitle": "Aber man darf sagen, was besser geht 🫵🏼",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      },
      "desktop": {
        "title": "„Man darf jetzt nicht alles so schlecht reden, wie es war“ 😵‍💫",
        "titleFontSize": 32,
        "subTitle": "Aber man darf sagen, was besser geht 🫵🏼",
        "subTitleFontSize": 20,
        "buttonText": "Zur Umfrage! 💭",
        "link": "https://docs.google.com/forms/d/1lk7xWTm40rSCSc8sHpue69EEqoGHjc0GKLJ6arBtS1o/edit?pli=1",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "21-inGame",
    "placement": "inGame",
    "advertisementId": 21,
    "viewports": {
      "mobile": {
        "title": "„Geht´s raus und spielt´s Fußball!“ 🐐",
        "titleFontSize": 32,
        "subTitle": "Oder geht´s raus und teilt KIKKZ! 🤝🏼",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "tablet": {
        "title": "„Geht´s raus und spielt´s Fußball!“ 🐐",
        "titleFontSize": 32,
        "subTitle": "Oder geht´s raus und teilt KIKKZ! 🤝🏼",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "desktop": {
        "title": "„Geht´s raus und spielt´s Fußball!“ 🐐",
        "titleFontSize": 32,
        "subTitle": "Oder geht´s raus und teilt KIKKZ! 🤝🏼",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "22-inGame",
    "placement": "inGame",
    "advertisementId": 22,
    "viewports": {
      "mobile": {
        "title": "„Ein Spiel dauert 90 Minuten“ ⏱️",
        "titleFontSize": 32,
        "subTitle": "Eine Story 90 Sekunden 🚀",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "tablet": {
        "title": "„Ein Spiel dauert 90 Minuten“ ⏱️",
        "titleFontSize": 32,
        "subTitle": "Eine Story 90 Sekunden 🚀",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      },
      "desktop": {
        "title": "„Ein Spiel dauert 90 Minuten“ ⏱️",
        "titleFontSize": 32,
        "subTitle": "Eine Story 90 Sekunden 🚀",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://www.instagram.com/kikkz.fussballquiz/",
        "canBeCopied": false
      }
    }
  },
  {
    "id": "23-inGame",
    "placement": "inGame",
    "advertisementId": 23,
    "viewports": {
      "mobile": {
        "title": "Erzähle deinen Freunden von KIKKZ 🤙🏼",
        "titleFontSize": 32,
        "subTitle": "Teile den KIKKZ-Link in Gruppen ❤️‍🔥",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://play.kikkz.de/game",
        "canBeCopied": false
      },
      "tablet": {
        "title": "Erzähle deinen Freunden von KIKKZ 🤙🏼",
        "titleFontSize": 32,
        "subTitle": "Teile den KIKKZ-Link in Gruppen ❤️‍🔥",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://play.kikkz.de/game",
        "canBeCopied": false
      },
      "desktop": {
        "title": "Erzähle deinen Freunden von KIKKZ 🤙🏼",
        "titleFontSize": 32,
        "subTitle": "Teile den KIKKZ-Link in Gruppen ❤️‍🔥",
        "subTitleFontSize": 20,
        "buttonText": "KIKKZ-Story teilen 🔗",
        "link": "https://play.kikkz.de/game",
        "canBeCopied": false
      }
    }
  }
] as SelfAdvertisementAsset[]]
    
]);

