import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { UserFacade } from 'libs/client/data-access/user/src';
import { OnlineStatisticsActions } from './online-statistics.actions';
import { catchError, concatMap, map, merge, of, shareReplay, switchMap, takeUntil, timer } from 'rxjs';
import { OnlineStatisticsService } from '../service/online-statistics.service';
import { HttpErrorResponse } from '@angular/common/http';
import { OtherUserService } from 'other-user';
import { createSimpleRequestEffect, createSimpleRequestWithLoaderEffect } from '@kiq/shared/util/helper';

@Injectable()
export class OnlineStatisticsEffects {
  private readonly actions = inject(Actions);
  private readonly userFacade = inject(UserFacade);
  private readonly onlineStatisticsService = inject(OnlineStatisticsService);
  private readonly otherUserService = inject(OtherUserService);
  SHOW_LOADER_AFTER_DELAY = 500;

  getOwnOverallOnlineStatistics$ = createEffect(() => {
    return this.actions.pipe(
      ofType(OnlineStatisticsActions.getOwnUserGameStatisticOverviewStart),
      concatLatestFrom(() => this.userFacade.user$),
      switchMap(([, user]) => {
        const getOwnOverview$ = this.onlineStatisticsService.getOwnUserStatisticOverview(user?.id ?? '').pipe(
          map((ownOverallOnlineStatistic) =>
            OnlineStatisticsActions.getOwnUserGameStatisticOverviewSuccess({
              userGameStatisticOverview: ownOverallOnlineStatistic,
            }),
          ),
          catchError((errorRes: HttpErrorResponse) => {
            const errorCode = errorRes.error.errorCode;
            return of(OnlineStatisticsActions.getOwnUserGameStatisticOverviewFail());
          }),
          shareReplay(1),
        );

        const overviewLoading$ = timer(this.SHOW_LOADER_AFTER_DELAY).pipe(
          map(() => OnlineStatisticsActions.showOverviewLoader()),
          takeUntil(getOwnOverview$),
        );

        return merge(overviewLoading$, getOwnOverview$);
      }),
    );
  });

  getOtherUsersOverallOnlineStatistics$ = createEffect(() => {
    return this.actions.pipe(
      ofType(OnlineStatisticsActions.getOtherUserGameStatisticOverviewStart),
      concatMap((otherUser) => {
        const getOtherUsersOverview$ = this.onlineStatisticsService
          .getUserGameStatisticOpponentOverview(otherUser.otherUser.id)
          .pipe(
            map((otherUsersOverallOnlineStatistic) =>
              OnlineStatisticsActions.getOtherUserGameStatisticOverviewSuccess({
                userGameStatisticOpponentOverview: otherUsersOverallOnlineStatistic,
              }),
            ),
            catchError((errorRes: HttpErrorResponse) => {
              const errorCode = errorRes.error.errorCode;
              return of(OnlineStatisticsActions.getOtherUserGameStatisticOverviewFail());
            }),
            shareReplay(1),
          );

        const overviewLoading$ = timer(this.SHOW_LOADER_AFTER_DELAY).pipe(
          map(() => OnlineStatisticsActions.showOverviewLoader()),
          takeUntil(getOtherUsersOverview$),
        );

        return merge(overviewLoading$, getOtherUsersOverview$);
      }),
    );
  });

  getAllOnlineStatistics$ = createEffect(() => {
    return this.actions.pipe(
      ofType(OnlineStatisticsActions.getAllUserGameStatisticOpponentOverviewsStart),
      concatMap(() => {
        const getAllOnlineStatistics$ = this.onlineStatisticsService.getAllUserGameStatisticOpponentOverviews().pipe(
          map((allUserGameStatisticOpponentOverviews) =>
            OnlineStatisticsActions.getAllUserGameStatisticOpponentOverviewsSuccess({
              allUserGameStatisticOpponentOverviews,
            }),
          ),
          catchError((errorRes: HttpErrorResponse) => {
            const errorCode = errorRes.error.errorCode;
            return of(OnlineStatisticsActions.getAllUserGameStatisticOpponentOverviewsFail());
          }),
          shareReplay(1),
        );

        const allStatisticsLoading$ = timer(this.SHOW_LOADER_AFTER_DELAY).pipe(
          map(() => OnlineStatisticsActions.showAllStatisticsLoader()),
          takeUntil(getAllOnlineStatistics$),
        );

        return merge(allStatisticsLoading$, getAllOnlineStatistics$);
      }),
    );
  });

  getOnlineStatisticsVersusUser$ = createEffect(() => {
    return this.actions.pipe(
      ofType(
        OnlineStatisticsActions.getOtherUserSuccess,
        OnlineStatisticsActions.getUserGameStatisticOpponentDetailsStart,
      ),
      concatMap((otherUser) => {
        return this.onlineStatisticsService.getAllUserGameStatisticOpponentDetails(otherUser.otherUser.id).pipe(
          map((userGameStatisticOpponentDetails) =>
            OnlineStatisticsActions.getUserGameStatisticOpponentDetailsSuccess({ userGameStatisticOpponentDetails }),
          ),
          catchError((errorRes: HttpErrorResponse) => {
            const errorCode = errorRes.error.errorCode;
            return of(OnlineStatisticsActions.getUserGameStatisticOpponentDetailsFail());
          }),
        );
      }),
    );
  });

  getOtherUser$ = createEffect(() => {
    return this.actions.pipe(
      ofType(OnlineStatisticsActions.getOtherUserStart),
      concatMap((userName) => {
        const getOtherUser$ = this.otherUserService.getOtherUserFromUsername$(userName.userName).pipe(
          map((otherUser) => OnlineStatisticsActions.getOtherUserSuccess({ otherUser })),
          catchError((errorRes: HttpErrorResponse) => {
            const errorCode = errorRes.error.errorCode;
            return of(OnlineStatisticsActions.getOtherUserFail());
          }),
          shareReplay(1),
        );

        const otherUserLoading$ = timer(this.SHOW_LOADER_AFTER_DELAY).pipe(
          map(() => OnlineStatisticsActions.showOtherUserLoader()),
          takeUntil(getOtherUser$),
        );

        return merge(otherUserLoading$, getOtherUser$);
      }),
    );
  });

  getVipGameStatisticsOverviewForUser$ = createSimpleRequestEffect(this.actions, {
    triggerAction: OnlineStatisticsActions.getVipGameStatisticsOverviewForUser,
    successAction: OnlineStatisticsActions.getVipGameStatisticsOverviewForUserSuccess,
    failAction: OnlineStatisticsActions.getVipGameStatisticsOverviewForUserFail,
    serviceMethod: (userId: string) => this.onlineStatisticsService.getVIPGameStatisticsOverviewForUser$(userId),
    mapTriggerPayload: (payload) => [payload.userId] as [string],
    mapSuccessPayload: (response) => ({ response }),
  });
}
