import { emptyProps, props } from '@ngrx/store';

export function createIntervalActions<TResponse>() {
  return {
    'start interval': props<{ game: TResponse }>(),
    'start interval success': props<{ game: TResponse }>(),
    'stop interval': emptyProps(),
  };
}

export function createTimeTickActions() {
  return {
    'time tick': emptyProps(),
  };
}

export function createShowLoaderActions() {
  return {
    'show step loader': emptyProps(),
    'show game loader': emptyProps(),
  };
}

export function createRefreshGameStateOnFocusActions() {
  return {
    'refresh game state on focus': emptyProps(),
  };
}

export function createCloseCurrentGameAction() {
  return {
    'close current game': emptyProps(),
  };
}
