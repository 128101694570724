import { ChangeDetectionStrategy, Component, inject, Input, OnChanges, signal, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { AssetsHelperService } from '@kiq/shared/util/asset-helper';
import { AnimationConfigWithPath } from 'ngx-lottie/lib/symbols';

@Component({
    selector: 'kiq-lottie-animation',
    imports: [CommonModule, LottieComponent],
    templateUrl: './lottie-animation.component.html',
    styleUrl: './lottie-animation.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LottieAnimationComponent implements OnChanges {
  protected DEFAULT_HEIGHT = '80px';
  protected DEFAULT_WIDTH = '80px';
  private animationItem: AnimationItem | null = null;

  private assetsHelperService = inject(AssetsHelperService);

  @Input() height: string | undefined;
  @Input() width: string | undefined;
  @Input() restart: any;

  @Input({ required: true }) set options(options: AnimationOptions) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const pathWithHash = `/${this.assetsHelperService.getAssetWithHash(options?.path ?? '')}`;

    this._options.update((oldOptions) => ({ ...oldOptions, ...options, path: pathWithHash }));
  }

  protected _options = signal<AnimationOptions | null>(null);

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['restart']) {
      this.restartAnimation();
    }
  }

  animationCreated(animationItem: AnimationItem) {
    this.animationItem = animationItem;
  }

  private restartAnimation() {
    if (this.animationItem) {
      this.animationItem.stop();
      this.animationItem.play();
      this.restart = false;
    }
  }
}
